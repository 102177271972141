import { useTranslation } from "react-i18next";
import draftToHtml from "draftjs-to-html";
import { RawHTML } from "../../common/RawHTML";
import { IVendorProductMetadata } from "./Product.types";
import { ProductMetadata } from "./ProductMetadata";

interface ProductDescriptionProps {
  description?: string;
  descriptionJson?: string;
  metadata?: IVendorProductMetadata;
  className?: string;
}

export const ProductDescription = ({ description, descriptionJson, className, metadata }: ProductDescriptionProps) => {
  const { t, ready } = useTranslation();
  const noDescription = <p className={className}>{t("store.product.noDescription")}</p>;

  if (!ready) {
    return null;
  }
  if (metadata?.rawDescription) {
    return (
      <>
        <ProductMetadata metadata={metadata} className={className}>
          <RawHTML className={className} html={metadata?.rawDescription} />
        </ProductMetadata>
      </>
    );
  }

  if (description) {
    return (
      <>
        <ProductMetadata metadata={metadata} />
        <p className={className}>{description}</p>
      </>
    );
  }

  if (!descriptionJson) {
    return noDescription;
  }

  try {
    const decodedJSON = JSON.parse(descriptionJson);
    const raw = draftToHtml(decodedJSON);

    return <RawHTML html={raw} className={className} />;
  } catch (e) {
    return noDescription;
  }
};
