import { getCurrencySymbolFromCountry } from "../../utils/country";

export class SnapchatPixelAPI {
  private _initialized = false;
  private pixelId: string | undefined;
  private static instance: SnapchatPixelAPI | undefined;

  constructor(pixelId: string) {
    this.pixelId = pixelId;
  }

  private get snaptr(): (action: string, event: string, payload?: any) => void {
    // @ts-ignore
    return window.snaptr;
  }

  init(allowTracking: boolean, userInfo?: any) {
    if (!this.pixelId) {
      console.error("SnapchatPixelAPI pixelId is empty during init");
      return;
    }

    if (allowTracking && (!this._initialized || !!userInfo)) {
      // if not initializer or has userInfo
      this.snaptr("init", this.pixelId!, {
        user_email: userInfo?.email ?? "__INSERT_USER_EMAIL__",
      });
    }
    this._initialized = allowTracking;
  }

  track(eventName: string, payload?: any, userInfo?: any) {
    if (!this.pixelId || !this._initialized) {
      console.error("SnapchatPixelAPI init must be called first");
      return;
    }

    if (userInfo) {
      this.init(userInfo);
    }

    this.snaptr("track", eventName, payload);
  }

  trackPageView(userInfo?: any) {
    this.track("PAGE_VIEW", undefined, userInfo);
  }

  trackPurchase(price?: string, transactionId?: string, userInfo?: any) {
    this.track(
      "PURCHASE",
      {
        price,
        currency: getCurrencySymbolFromCountry(),
        transaction_id: transactionId,
      },
      userInfo
    );
  }

  trackSubscribe(userInfo?: any) {
    this.track("SUBSCRIBE", undefined, userInfo);
  }

  public static getInstance(pixelId: string) {
    if (this.instance) {
      return this.instance;
    }
    return new SnapchatPixelAPI(pixelId);
  }
}
