function getCode(gaTrackingId: string) {
  return {
    __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gaTrackingId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
  };
}

export const GoogleTagManagerScript = () => {
  const gaTrackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || "";
  if (gaTrackingId) {
    return <noscript dangerouslySetInnerHTML={getCode(gaTrackingId)} />;
  } else {
    return <></>;
  }
};
