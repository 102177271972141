import { Address, UserInfo } from "../signup/SignUpApi";
import { AddressInput, StoreAddress, Variant } from "./Checkout.types";
import { MetadataItem } from "../store/product-details/Product.types";
import * as JSON5 from "json5";

export const checkIfVelocityError = (err: string) => {
  return err === "You have reached a limit for creating order";
};

export const checkIfCreditLimitError = (err: string) => {
  return err.includes("reached credit limit");
};

export const checkIfAddressError = (err: string) => {
  return err === "This value is not valid for the address.";
};

export const createApolloAuthProps = (storeToken?: string, token?: string) => ({
  context: { headers: { Authorization: `JWT ${token ?? storeToken}` } },
});

export const makeCheckoutRedirectUrls = (isPayInThree: boolean) => {
  const baseHrefTokens = window.location.href.split("/");
  if (!window.location.href.endsWith("/checkout")) {
    baseHrefTokens.pop();
  }
  const successUrl = [...baseHrefTokens, "complete"].join("/");
  const cancelUrl = [...baseHrefTokens].join("/");
  const tranchesCount = isPayInThree ? 2 : undefined;
  return { successUrl, cancelUrl, tranchesCount };
};

export const transformAddressToInput = (address: StoreAddress): AddressInput => ({
  firstName: address?.firstName,
  lastName: address?.lastName,
  companyName: address?.companyName,
  streetAddress1: address?.streetAddress1,
  streetAddress2: address?.streetAddress2,
  city: address?.city,
  cityArea: "",
  postalCode: address?.postalCode,
  country: address?.country?.code,
  countryArea: address?.countryArea,
  phone: address?.phone,
});

export const transformAddressInputToAddress = (addressInput: StoreAddress | undefined): Address | undefined => {
  if (!addressInput) {
    return addressInput;
  }
  const { streetAddress1, streetAddress2, city, postalCode, country, countryArea } = addressInput;
  return {
    address1: streetAddress1,
    address2: streetAddress2,
    city: city,
    postCode: postalCode,
    country: country?.country,
    state: countryArea,
  };
};

export const extractPrimaryAddress = (userInfo: UserInfo): Address => {
  const { address1, address2, city, state, postCode, country } = userInfo;
  return {
    address1,
    address2,
    city,
    state,
    postCode,
    country,
  };
};
export const isDifferenceAddress = (primaryAddress: Address, shippingAddress: StoreAddress) => {
  return (
    primaryAddress.address1 !== shippingAddress.streetAddress1 ||
    primaryAddress.address2 !== shippingAddress.streetAddress2 ||
    primaryAddress.city?.toLowerCase() !== shippingAddress.city?.toLowerCase() ||
    primaryAddress.postCode !== shippingAddress.postalCode ||
    primaryAddress.state !== shippingAddress.countryArea
  );
};

export const getLineVariantImage = (variant: Variant) => {
  if (variant.images?.length) {
    return variant.images[0].url;
  }

  const imagesMetadata = variant.product?.metadata?.find((row: MetadataItem) => row.key === "images")?.value;

  if (imagesMetadata) {
    try {
      const images = JSON5.parse(imagesMetadata);
      if (images.length) {
        return images[0].url;
      }
    } catch (e) {
      console.log("Failed to parse images metadata");
    }
  }

  return variant.product?.thumbnail?.url ?? "";
};
