import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PaymentDate } from "./PaymentDateChange";
import advancedDayjs from "../../../utils/date";

const PaymentDateCalendarDay = ({
  children,
  day,
  onDaySelected,
}: {
  children?: JSX.Element | string;
  day: PaymentDate;
  onDaySelected: PaymentDateOnSelected;
}) => {
  return (
    <Button
      variant="outline-dark"
      className="rectangle payment-date-btn px-0 py-0 py-md-1 m-1"
      onClick={() => onDaySelected(day)}
    >
      {children ?? day}
    </Button>
  );
};

const PaymentDateCalendarWeek = ({
  startDay,
  startWeekday,
  onDaySelected,
}: {
  startDay: number;
  startWeekday: number;
  onDaySelected: PaymentDateOnSelected;
}) => {
  const days: number[] = Array(7)
    .fill(null)
    .map((_, day) => {
      if (day < startWeekday) {
        return 0;
      }

      const dayToDisplay = startDay + day - startWeekday;
      if (dayToDisplay > 28) {
        return 0;
      }

      return dayToDisplay;
    });

  return (
    <Row>
      {days.map((day, idx) => {
        return (
          <Col key={idx} className="px-1 px-md-2">
            {day ? <PaymentDateCalendarDay onDaySelected={onDaySelected} day={day} /> : <></>}
          </Col>
        );
      })}
    </Row>
  );
};

type PaymentDateOnSelected = (day: PaymentDate) => void;

interface PaymentDateCalendarProps {
  className: string;
  onDaySelected: PaymentDateOnSelected;
  visible: boolean;
}

export const PaymentDateCalendar = ({ className, onDaySelected, visible }: PaymentDateCalendarProps) => {
  const { t } = useTranslation();
  const firstDayOfMonth = advancedDayjs().startOf("month").day();
  const numOfWeeks = firstDayOfMonth === 0 ? 4 : 5;

  return (
    <div className={`${className} ${visible ? "visible" : "invisible"}`}>
      {Array(numOfWeeks)
        .fill(null)
        .map((_, week) => {
          let startWeekday = firstDayOfMonth;
          let startDay = 1;
          if (week > 0) {
            startWeekday = 0;
            startDay = advancedDayjs()
              .startOf("month")
              .add(7 * week, "days")
              .startOf("week")
              .date();
          }

          return (
            <PaymentDateCalendarWeek
              onDaySelected={onDaySelected}
              startDay={startDay}
              startWeekday={startWeekday}
              key={week}
            />
          );
        })}
      <Row>
        <Col>
          <PaymentDateCalendarDay onDaySelected={onDaySelected} day="last">
            {t("payment.forms.paymentDate.lastDayOfMonth") as string}
          </PaymentDateCalendarDay>
        </Col>
      </Row>
    </div>
  );
};
