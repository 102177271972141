import BitsIcon from "../cassetteUI/common/BitsIcon";
import { useTranslation } from "react-i18next";
import { TripleSpinner } from "../ui/TripleSpinner";
import { useBitsUserContext } from "../../context/SignedInContext";

import "./ScoreKyc.scss";
import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import logger, { LogTagKey } from "../../utils/logger";
import { useNavigate } from "react-router";
// @ts-ignore
import SumsubWebSdk from "@sumsub/websdk-react";
import { Col, Row } from "react-bootstrap";
import { UserInitialKycStatus, UserKycStatus } from "./KycApi";

export const ScoreKyc = () => {
  const { userInfo, setErrorMessage } = useBitsUserContext();
  const [accessToken, setAccessToken] = useState<string>();
  const [hideKyc, setHideKyc] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const kycApi = useApi().kyc;
  const navigate = useNavigate();

  const { t, ready } = useTranslation();
  if (!ready) {
    return <TripleSpinner />;
  }

  const poll = () => {
    kycApi
      .getStatus()
      .then((res) => {
        if (res.initialKycStatus === UserInitialKycStatus.approved && res.kycStatus === UserKycStatus.kycApproved) {
          navigate("../complete");
        }
        if (res.kycStatus === UserKycStatus.kycError) {
          if (res.error?.includes("name mismatch")) {
            setErrorMessage(t("score.kyc.mismatchMessage"));
            setHideKyc(true);
          } else {
            setErrorMessage(t("common.error.unknown"));
          }
        }
      })
      .catch(() => {
        // do nothing
      });
  };

  useEffect(() => {
    setLoading(true);
    kycApi
      .startSession(true)
      .then((res) => {
        if (res.isKycRequired) {
          setAccessToken(res.accessToken);
        }
      })
      .catch((err) => {
        setErrorMessage(t("common.error.unknown"));
        logger.error(err, LogTagKey.TkKyc, "Failed to start KYC session", {
          membershipNumber: userInfo?.membershipNumber,
        });
      })
      .finally(() => {
        setLoading(false);
      });
    const handle = setInterval(poll, 3000);
    return () => {
      clearInterval(handle);
      setErrorMessage("");
    };
  }, []);

  const messageHandler = () => {};

  const errorHandler = () => {};

  const options = {};

  const config = {};

  return (
    <>
      {!hideKyc && (
        <div className="unAuth-card" id="kyc">
          <BitsIcon />
          <div className="signup-header">
            <h5 className="title">{t("score.kyc.header")}</h5>
          </div>
          <div className="body form-wrapper">
            {loading && <TripleSpinner />}
            {!loading && accessToken && (
              <SumsubWebSdk
                accessToken={accessToken}
                expirationHandler={async () => accessToken}
                config={config}
                options={options}
                onMessage={messageHandler}
                onError={errorHandler}
              />
            )}
            <Row>
              <Col></Col>
              <Col sm={8}>
                <button
                  type="button"
                  className="form-input enabled w-100 button"
                  id="sign-up-important-information-submit"
                >
                  {t("score.kyc.button")}
                </button>
              </Col>
              <Col></Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};
