import { getJson, postJson } from "../../utils/api";
import { PaymentDate } from "../settings/paymentDate/PaymentDateChange";
import { AnnualRenewalPeriod, MonthlyRenewalPeriod, PlanInterval } from "./SubscriptionApi";

export interface WalletBalance {
  currentBalance: number;
  unpaidAmount: number;
  availableFunds: number;
}

export interface SubscriptionPlan {
  id: string;
  amount: number;
  creditAmount: number;
  currency: string;
  name: string;
  title: string;
  subtitle: string;
  renewalPeriod: MonthlyRenewalPeriod | AnnualRenewalPeriod;
  interval: PlanInterval;
}

export enum WalletStatus {
  unknown = "unknown",
  pending = "pending",
  active = "active",
  past_due = "past_due",
}

export enum GatewayType {
  stripe = "stripe",
}

export interface SubscriptionGateway {
  id: string;
  type: GatewayType;
  requireAction: boolean;
  actionClientSecret: string | null;
  checkoutUrl?: string | null;
  subscription?: string | null;
}

export interface SuperchargeSubscriptionItem {
  subscriptionItemId: string;
  plan: SubscriptionPlan;
}
interface BitsTurboInfo {
  balance: {
    currentBalance: number;
    availableFunds: number;
    unpaidAmount: number;
  };
  plan: {
    creditAmount: number;
    instalmentAmount: number;
    title: string;
  };
}
export interface WalletModel {
  userId: string;
  plan: SubscriptionPlan | null;
  balance: WalletBalance;
  status: WalletStatus;
  /**
   * @desc datetime in utc. iso format
   */
  startedDate: string | null;
  /**
   * @desc datetime in utc. iso format
   */
  lastPaymentDate: string | null;
  /**
   * @desc datetime in utc. iso format
   */
  nextPaymentDate: string | null;
  gateway: SubscriptionGateway | null;
  supercharge?: SuperchargeSubscriptionItem | null;
  bitsTurbo?: BitsTurboInfo;
}

export interface CreateSubscriptionRequest {
  successUrl: string;
  cancelUrl: string;
  planId: string;
  couponId?: string;
}

export interface CreateTrialSubscriptionRequest {
  planId: string;
  trialDays: number;
}

export interface ChangePaymentMethodRequest {
  successUrl: string;
  cancelUrl: string;
}

export interface CreateSuperchargeSubscriptionRequest {
  stripePlanId: string;
}

export interface ChangePaymentMethodResponse {
  checkoutUrl: string;
}

export enum PaymentStatus {
  draft = "draft",
  processing = "processing",
  pending = "pending",
  funded = "funded",
  done = "done",
  failed = "failed",
  cancelled = "cancelled",
}

export enum PaymentType {
  subscriptionFee = "subscriptionFee",
  order = "order",
  credit = "credit",
}

export enum StripeEntity {
  invoice = "invoice",
  paymentIntent = "paymentIntent",
  session = "session",
}

export interface PaymentGateway {
  type: string;
  id: string;
  entity: StripeEntity;
  requireAction: boolean;
  actionClientSecret: string | null;
  checkoutUrl: string | null;
  entityNumber: string | null;
}

export interface SubscriptionFeePaymentDetails {
  /**
   * @desc datetime in utc. iso format
   */
  billingPeriodStart: string;
  /**
   * @desc datetime in utc. iso format
   */
  billingPeriodEnd: string;

  isPayInThreeOrder?: boolean;
}

export interface OrderPrice {
  amount: number;
  currency: string;
}

export interface OrderPrices {
  total: OrderPrice;
  subtotal: OrderPrice;
  shipping: OrderPrice;
  upfront: OrderPrice;
}

export interface OrderLine {
  id: string;
  name: string;
  productName: string;
  quantity: number;
  merchant: string;
}

export interface OrderPaymentDetails {
  orderId: string;
  prices: OrderPrices;
  lines: OrderLine[];
  isPayInThreeOrder?: boolean;
}

export interface CreditPaymentDetails {
  description: string;
  isPayInThreeOrder?: boolean;
}

export interface PaymentResponse {
  id: string;
  /**
   * @desc datetime in utc. iso format
   */
  createdAt: string;
  /**
   * @desc datetime in utc. iso format
   */
  finishedAt: string | null;
  /**
   * @desc datetime in utc. iso format
   */
  scheduledDate: string | null;
  /**
   * @desc datetime in utc. iso format
   */
  dueDate: string | null;
  status: PaymentStatus;
  type: PaymentType;
  amount: number;
  currency: string;
  gateway: PaymentGateway | null;
  details: SubscriptionFeePaymentDetails | OrderPaymentDetails | CreditPaymentDetails | null;
}

export interface PaymentListResponse {
  payments: PaymentResponse[];
  nextToken: string | null;
  count: number;
}

export interface ChangePaymentDateResponse {
  hasChangedPaymentDay?: boolean;
}

export interface AddCreditRequest {
  amount: number;
  description: string;
}

export class PaymentApi {
  async getWallet(): Promise<WalletModel> {
    return await getJson("wallet", process.env.REACT_APP_BASE_PAYMENT_API_URL)
      .then((response) => response.json())
      .then((response) => response["data"]);
  }

  async createSubscription(
    planId: string,
    successUrl: string,
    cancelUrl: string,
    couponId?: string
  ): Promise<WalletModel> {
    const request: CreateSubscriptionRequest = { planId, successUrl, cancelUrl, couponId };
    return await postJson<CreateSubscriptionRequest>(
      "wallet/subscriptions",
      request,
      process.env.REACT_APP_BASE_PAYMENT_API_URL
    )
      .then((response) => response.json())
      .then((response) => response["data"]);
  }

  async createTrialSubscription(planId: string, trialDays: number): Promise<WalletModel> {
    const request: CreateTrialSubscriptionRequest = { planId, trialDays };
    return await postJson<CreateTrialSubscriptionRequest>(
      "wallet/subscriptions/trial",
      request,
      process.env.REACT_APP_BASE_PAYMENT_API_URL
    )
      .then((response) => response.json())
      .then((response) => response["data"]);
  }

  async changePaymentMethod(successUrl: string, cancelUrl: string): Promise<ChangePaymentMethodResponse> {
    const request: ChangePaymentMethodRequest = { successUrl, cancelUrl };
    return await postJson<ChangePaymentMethodRequest>(
      "wallet/paymentMethod",
      request,
      process.env.REACT_APP_BASE_PAYMENT_API_URL
    )
      .then((response) => response.json())
      .then((response) => response["data"]);
  }

  async getPayments(
    status?: PaymentStatus[],
    type?: PaymentType[],
    nextToken?: string | null
  ): Promise<PaymentListResponse> {
    return await getJson(
      `payments?type=${(type ?? []).join(",")}&status=${(status ?? []).join(",")}${
        nextToken != null ? `&nextToken=${nextToken}` : ""
      }`,
      process.env.REACT_APP_BASE_PAYMENT_API_URL
    )
      .then((response) => response.json())
      .then((response) => response["data"]);
  }

  async chargeFunded(chargeTurboPayments = false): Promise<PaymentListResponse> {
    return await postJson("wallet/charge", { chargeTurboPayments }, process.env.REACT_APP_BASE_PAYMENT_API_URL)
      .then((response) => response.json())
      .then((response) => response["data"]);
  }

  async addCredit(params: AddCreditRequest): Promise<PaymentListResponse> {
    return await postJson("wallet/credit", params, process.env.REACT_APP_BASE_PAYMENT_API_URL)
      .then((response) => response.json())
      .then((response) => response["data"]);
  }

  async changePaymentDate(paymentDay: PaymentDate): Promise<ChangePaymentDateResponse> {
    return await postJson(
      `wallet/subscriptions/paymentDay`,
      { paymentDay: paymentDay.toString() },
      process.env.REACT_APP_BASE_PAYMENT_API_URL
    ).then((response) => response.json());
  }

  async createSuperchargeSubscription(stripePlanId: string): Promise<WalletModel> {
    const request: CreateSuperchargeSubscriptionRequest = { stripePlanId };
    return await postJson<CreateSuperchargeSubscriptionRequest>(
      "wallet/subscriptions/supercharge",
      request,
      process.env.REACT_APP_BASE_PAYMENT_API_URL
    )
      .then((response) => response.json())
      .then((response) => response["data"]);
  }
}

export const isSubscriptionActive = (wallet: WalletModel | null | undefined) =>
  wallet?.plan?.name != null && ![WalletStatus.pending, WalletStatus.unknown].includes(wallet?.status);

export const isSubscriptionNotStarted = (wallet: WalletModel | null | undefined) =>
  wallet != null && [WalletStatus.pending, WalletStatus.unknown].includes(wallet?.status);
