import React, { createContext, useContext, useEffect, useState } from "react";
import { ContentfulClient, ContentfulConfiguration, defaultContentfulConfiguration } from "../utils/contentful";
import logger, { LogTagKey } from "../utils/logger";

interface IContentfulContext {
  content?: ContentfulConfiguration;
  isLoading?: boolean;
  contentReady?: boolean;
}

export const defaultContentfulContext: IContentfulContext = {
  content: defaultContentfulConfiguration,
  isLoading: true,
  contentReady: false,
};

export const ContentfulContext = createContext<IContentfulContext>(defaultContentfulContext);

export const ContentfulContextProvider: React.FC = ({ children }) => {
  const [content, setContent] = useState<ContentfulConfiguration>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [contentReady, setReady] = useState<boolean>(false);

  useEffect(() => {
    ContentfulClient.getInstance()
      .then((client) => {
        const content = client?.getContent();
        if (typeof content !== "undefined") {
          setContent(content);
        }
      })
      .catch((err) => {
        logger.error(err, LogTagKey.TkGeneric, "Contentful hook error");
        setContent(undefined);
      })
      .finally(() => {
        setLoading(false);
        setReady(true);
      });
  }, []);

  return (
    <ContentfulContext.Provider value={{ content, contentReady, isLoading }}>{children}</ContentfulContext.Provider>
  );
};

export const useContentfulContext = () => useContext(ContentfulContext);
