import TiktokPixel, { AdvancedMatching, Options } from "tiktok-pixel";
import { SubscriptionPlan, UserInfo } from "../../components/signup/SignUpApi";
import { CheckoutLine, StoreCheckout } from "../../components/checkout/Checkout.types";
import { getCookieConsentValue } from "react-cookie-consent";

const pixelIdFromConfig = process.env.REACT_APP_TIKTOK_PIXEL_ID || undefined;

export const getStoreCheckoutContents = (checkout: StoreCheckout): any => {
  const contents: any = [];
  const content_ids: string[] = [];
  checkout?.lines.forEach((line: CheckoutLine) => {
    const { sku, product, pricing } = line?.variant ?? {};
    const { id, name } = product ?? {};
    const { price } = pricing ?? {};
    if (id) {
      content_ids.push(id);
      contents.push({ content_id: id, content_name: name, description: sku, value: price.gross.amount });
    }
  });
  return { contents, content_ids };
};

export const buildPaymentDataFromStoreCheckout = (checkout: StoreCheckout): any => {
  const { contents } = getStoreCheckoutContents(checkout);
  const { amount, currency } = checkout?.totalPrice?.gross ?? {};
  return {
    value: amount?.toFixed(2),
    currency: currency,
    content_type: "product",
    contents,
  };
};

class TiktokPixelAPI {
  private _isAllowed = false;

  init(userInfo?: UserInfo) {
    const advancedMatching: AdvancedMatching | undefined =
      userInfo && userInfo.email
        ? {
            email: userInfo.email,
            phone_number: userInfo.phoneNumber ?? "",
          }
        : undefined;
    const options: Options = {
      debug: false,
    };
    TiktokPixel.init(pixelIdFromConfig || "", advancedMatching, options);
    this._isAllowed = getCookieConsentValue() === "true";
  }

  allowTracking() {
    this._isAllowed = true;
  }

  trackPurchase(checkout: StoreCheckout, userInfo?: UserInfo) {
    if (!this._isAllowed) return;
    if (userInfo && pixelIdFromConfig) {
      this.init(userInfo);
    }

    const data = buildPaymentDataFromStoreCheckout(checkout);
    TiktokPixel.track("PlaceAnOrder", data);
  }

  trackSubscribe(plan: SubscriptionPlan, userInfo?: UserInfo) {
    if (!this._isAllowed) return;
    if (userInfo && pixelIdFromConfig) {
      this.init(userInfo);
    }

    const { currency, amount, subscriptionPlanId: id, title } = plan ?? {};
    const amountWithDecimals = amount?.toFixed(2);
    const data = {
      currency,
      value: amountWithDecimals,
      content_type: "product",
      content_id: id,
      content_name: title,
      description: `${title}: ${amountWithDecimals}/month`,
    };
    TiktokPixel.track("Subscribe", data);
  }
}

export default new TiktokPixelAPI();
