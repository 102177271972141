import { Breadcrumb, BreadcrumbItemProps } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./StoreBreadcrumbs.scss";

export interface StoreBreadcrumbItem {
  label: string;
  link?: string;
  chevronLeft?: boolean;
}

interface StoreBreadcrumbsProps {
  items?: StoreBreadcrumbItem[];
  className?: string;
}

export const StoreBreadcrumbs = ({ items, className }: StoreBreadcrumbsProps) => {
  if (!items?.length) {
    return null;
  }

  return (
    <Breadcrumb className={`store-breadcrumbs ${className ?? ""}`}>
      {items.map((item, idx) => {
        const itemProps: BreadcrumbItemProps = {};
        if (item.link != null) {
          itemProps.linkAs = Link;
          itemProps.linkProps = { to: item.link };
        } else {
          itemProps.active = true;
        }

        return (
          <Breadcrumb.Item key={idx} {...itemProps}>
            {item.chevronLeft && <i className="bi bi-chevron-left" />}
            {item.label}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};
