import { useEffect } from "react";
import { useNavigate } from "react-router";
import PaymentDateChange from "../settings/paymentDate/PaymentDateChange";
import { CHANGE_PAYMENT_DATE_STEP_NO, SIGNUP_LEGALNAME_URL } from "./SignUp";

export const SignUpChangePaymentDate = ({
  setSignUpStep,
  signUpStep,
}: {
  setSignUpStep: (step: number) => void;
  signUpStep: number;
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (signUpStep < CHANGE_PAYMENT_DATE_STEP_NO) {
      return navigate(SIGNUP_LEGALNAME_URL);
    }
    setSignUpStep(CHANGE_PAYMENT_DATE_STEP_NO);
  }, [signUpStep]);
  return <PaymentDateChange isSignUp={true} />;
};
