import { StoreCheckout } from "../../components/checkout/Checkout.types";
import { SubscriptionPlan, UserInfo } from "../../components/signup/SignUpApi";
import facebookPixel from "./facebookPixel";
import { mixPanelInstance } from "./mixpanel";
import tiktokPixel from "./tiktokPixel";

export function trackSubscribe(plan: SubscriptionPlan, userInfo?: UserInfo) {
  facebookPixel.trackSubscribe(plan, userInfo);
  tiktokPixel.trackSubscribe(plan, userInfo);
}

export function trackPurchase(checkout: StoreCheckout, userInfo: UserInfo | undefined) {
  if (checkout == null || userInfo == null) {
    return;
  }
  const { referral } = userInfo;
  const utmSource = referral?.split("|")[0] ?? "";
  facebookPixel.trackPurchase(checkout, userInfo);
  tiktokPixel.trackPurchase(checkout, userInfo);
  if (utmSource !== "") {
    mixPanelInstance.trackUTMStorePurchase(utmSource);
  }
}

export function trackLogin(userInfo?: UserInfo) {
  if (userInfo) {
    facebookPixel.trackLogin(userInfo);
  }
}
