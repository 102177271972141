import BitsIcon from "../cassetteUI/common/BitsIcon";
import { useTranslation } from "react-i18next";
import { TripleSpinner } from "../ui/TripleSpinner";

import "./ScoreApplyComplete.scss";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { ScoreUserModel, UserScoreStatus } from "./ScoreApi";

export const ScoreApplyComplete = () => {
  const { t, ready } = useTranslation();
  const navigate = useNavigate();
  const [user, setUser] = useState<ScoreUserModel>();
  if (!ready) {
    return <TripleSpinner />;
  }

  const scoreApi = useApi().score;

  useEffect(() => {
    const poll = () => {
      scoreApi
        .getEligibility()
        .then((res) => {
          setUser(res.user);
          if (res.user == null) {
            navigate("..");
          } else if (res.user?.status === UserScoreStatus.failed) {
            clearInterval(handle);
            navigate("../error");
          } else if (res.user?.status === UserScoreStatus.enabled) {
            clearInterval(handle);
          }
        })
        .catch(() => {
          // do nothing
        });
    };

    const handle = setInterval(poll, 3000);
    return () => {
      clearInterval(handle);
    };
  }, []);

  return (
    <>
      <div className="unAuth-card" id="apply-complete">
        <BitsIcon />
        {user?.status === UserScoreStatus.enabled ? (
          <>
            <div className="signup-header">
              <h5 className="title"> {t("score.done.header")}</h5>
            </div>
            <div className="body form-wrapper">
              {t("score.done.top")}
              <Link to="/simulator">
                <button type="button" className="form-input enabled w-100" id="sign-up-important-information-submit">
                  {t("score.done.button")}
                </button>
              </Link>
            </div>
          </>
        ) : (
          <div className="body form-wrapper">
            <h5 className="title">
              <TripleSpinner />
            </h5>
          </div>
        )}
      </div>
    </>
  );
};
