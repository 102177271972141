import { useEffect, useState } from "react";
import { PlanInterval, Pocket, SubscriptionPlan } from "../../../payment/SubscriptionApi";
import { Col, Row } from "react-bootstrap";
import logger, { LogTagKey } from "../../../../utils/logger";
import { UserInfo } from "../../../signup/SignUpApi";
import useApi from "../../../../hooks/useApi";
import "./SubcriptionPlansCasette.scss";
import { TripleSpinner } from "../../../ui/TripleSpinner";
import { useTranslation } from "react-i18next";
import { SubscriptionPlanInfo } from "../../../settings/subscription/SubcriptionPlanInfo";
import { DetailSubscriptionPlanItem } from "./DetailSubscriptionPlanItem";
import { Link } from "react-router-dom";
import { getPlanWithDefaultCreditLimit } from "../../../../utils/subscription";
import { ErrorModal } from "../../../error/ErrorModal";
import { isCountryUk } from "../../../../utils/country";
import { useContentfulContext } from "../../../../context/ContentfulContext";
import UpcomingPaymentsModal from "../../../store/upcoming-payments/UpcomingPaymentsModal";
import { useSubscriptionInfo } from "../../../../hooks/useSubscriptionInfo";
import { useBitsUserContext } from "../../../../context/SignedInContext";

export interface ISelected {
  selected?: boolean;
}

const SubscriptionPlansCasette = (props: {
  onSubscriptionPlanSelected: (plan: SubscriptionPlan | undefined) => void;
  setErrorMessage: (s: string | undefined) => void;
  pocket: Pocket | undefined;
  userInfo: UserInfo | undefined;
  currentPlanId?: string | undefined;
  isSignup: boolean;
  isSubmiting?: boolean;
  onSubmitBtnClick: (data: any) => void;
  showStoreLink?: boolean;
}) => {
  const { t, ready } = useTranslation();
  const {
    onSubscriptionPlanSelected,
    pocket,
    setErrorMessage,
    userInfo,
    currentPlanId,
    isSignup,
    onSubmitBtnClick,
    showStoreLink,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState<(SubscriptionPlan & ISelected)[]>();
  const [preselectedPlan, setPreselectedPlan] = useState<SubscriptionPlan & ISelected>();
  const [defaultPlan, setDefaultPlan] = useState<SubscriptionPlan>();
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan & ISelected>();
  const [currentActivePlan, setCurrentActivePlan] = useState<SubscriptionPlan>();
  const [error, setError] = useState<string>();
  const [showError, setShowError] = useState<boolean>(false);
  const { content, isLoading: isContentfulLoading } = useContentfulContext();
  const { hasOverduePayment } = useBitsUserContext();
  const { isUpcomingPaymentsShown, handleUpcomingPaymentsClose, showUpcomingPayments } = useSubscriptionInfo();

  const subscriptionApi = useApi().subscription;

  useEffect(() => {
    const fetchSubscriptions = async () => {
      if (pocket?.id) {
        setErrorMessage(undefined);
        setIsLoading(true);

        subscriptionApi
          .getSubscriptionPlans()
          .then((response) => {
            const showingIds =
              content?.subscriptionPlans === undefined ? [] : content.subscriptionPlans.allowShowingPlanIds;
            let preselectedPlan;
            const subscriptionPlans = response
              .filter((p) => {
                if (showingIds?.length > 0) {
                  return showingIds.includes(p.subscriptionPlanId);
                }

                return true;
              })
              .sort((p1: SubscriptionPlan, p2: SubscriptionPlan) => p1.order - p2.order)
              .map((p: SubscriptionPlan & ISelected) => {
                currentPlanId && p.stripePlanId === currentPlanId && setCurrentActivePlan(p);
                if (p.stripePlanId === currentPlanId || (!currentPlanId && p.isDefault)) {
                  p.selected = true;
                  preselectedPlan = p;
                }
                return p;
              });

            if (subscriptionPlans.length === 0) {
              throw new Error("No active subscription plans");
            }

            setSubscriptionPlans(subscriptionPlans);

            if (preselectedPlan) {
              setPreselectedPlan(preselectedPlan);
            }

            const planWithLowestCreditLimit = getPlanWithDefaultCreditLimit(response);
            if (planWithLowestCreditLimit) {
              setDefaultPlan(planWithLowestCreditLimit);
            }
          })
          .catch((e: any) => {
            showErrorMessage(t("common.error.unknown"));
            setIsLoading(false);
            logger.error(e, LogTagKey.TkSubscription, "Payment System error fetching subscription plans", {
              membershipNumber: userInfo?.membershipNumber,
              pocketId: pocket?.id,
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    };
    fetchSubscriptions().then(() => {});
  }, [pocket]);

  useEffect(() => {
    preselectedPlan && onPlanSelected(preselectedPlan);
  }, [preselectedPlan]);

  const onPlanSelected = (selectedPlan: SubscriptionPlan | undefined) => {
    setSubscriptionPlans(
      (subscriptionPlans ?? []).map((plan) => ({
        ...plan,
        selected: plan.subscriptionPlanId === selectedPlan?.subscriptionPlanId,
      }))
    );
    onSubscriptionPlanSelected(selectedPlan);
    setSelectedPlan(selectedPlan);
    validateSelectedPlan(selectedPlan);
  };
  const validateSelectedPlan = (selectedPlan: SubscriptionPlan | undefined) => {
    if (!selectedPlan || !currentActivePlan) return;
    switch (currentActivePlan.interval) {
      case PlanInterval.year:
        if (selectedPlan.interval === PlanInterval.month) {
          showErrorMessage(t("subscription.errors.downgradeAnnualToMonthly"));
          return;
        }
        if (
          selectedPlan.interval === PlanInterval.year &&
          selectedPlan.renewalPeriod < currentActivePlan.renewalPeriod
        ) {
          showErrorMessage(t("subscription.errors.downgradePlan"));
          return;
        }
        setError(undefined);
        return;
      case PlanInterval.month:
        if (
          selectedPlan.interval === PlanInterval.month &&
          selectedPlan.renewalPeriod < currentActivePlan.renewalPeriod
        ) {
          showErrorMessage(t("subscription.errors.downgradePlan"));
          return;
        }
        setError(undefined);
        return;
      default:
        showErrorMessage(t("subscription.errors.unexpectedError"));
    }
  };
  const showErrorMessage = (message: string) => {
    setError(message);
    setShowError(true);
  };

  const onPlanSubmit = (data: any) => {
    if (hasOverduePayment()) {
      showUpcomingPayments();
    } else {
      onSubmitBtnClick(data);
    }
  };

  if (!ready) {
    return null;
  }

  if (isLoading || isContentfulLoading) {
    return <TripleSpinner />;
  }

  const customPlanDetails = content?.subscriptionPlans?.customPlanDetails ?? [];
  console.log("subscriptionPlans", subscriptionPlans);

  return (
    <>
      <Col className="my-4 me-2 text-center">
        <h2 id="subscription-plans-title">{t("settings.forms.plans.title")}</h2>
        {showStoreLink && (
          <Link to="/store" target="_blank">
            {t("settings.forms.plans.seeStore")}
          </Link>
        )}
      </Col>
      <Row className="mt-4 justify-content-center">
        {subscriptionPlans?.map((subscriptionPlan: SubscriptionPlan & ISelected) => {
          const id = `plan${subscriptionPlan.subscriptionPlanId}`;
          const cssClass =
            subscriptionPlan.title != null ? `#${id}::before { content: '${subscriptionPlan.title}'}` : "";
          const planDetails = customPlanDetails?.find((plan: any) => plan?.name === subscriptionPlan?.name);
          return (
            <Col
              className={`col-6 col-lg-4 col-xl-4 my-4 subscription-card-holder ${
                subscriptionPlan.selected || subscriptionPlan.stripePlanId === currentPlanId ? "active" : ""
              }`}
              id={id}
              key={`col-${subscriptionPlan.subscriptionPlanId}`}
              xs={12}
              md={4}
            >
              <style>{cssClass}</style>
              <DetailSubscriptionPlanItem
                key={subscriptionPlan.subscriptionPlanId}
                isSignup={isSignup}
                subscriptionPlan={subscriptionPlan}
                currentPlanId={currentPlanId}
                onSelect={() => onPlanSelected(subscriptionPlan)}
                onSubmitBtnClick={onPlanSubmit}
                planDetails={planDetails}
              />
            </Col>
          );
        })}
      </Row>
      <Row className="my-5 mx-2 justify-content-center d-none">
        <SubscriptionPlanInfo
          description={content?.subscriptionPlans?.description || []}
          highlightedPlan={isCountryUk() ? selectedPlan : defaultPlan}
        />
      </Row>
      <ErrorModal
        id="modal-error-select-plan"
        show={showError}
        errorMessage={error}
        onClose={() => setShowError(false)}
      />
      <UpcomingPaymentsModal isShown={isUpcomingPaymentsShown} onClose={handleUpcomingPaymentsClose} />
    </>
  );
};

export default SubscriptionPlansCasette;
