import React from "react";
import "./ContentWrapper.scss";

interface InsetContainerProps {
  children: React.ReactNode;
  blue?: boolean;
  shadow?: boolean;
  bitsBlue?: boolean;
}

export const ContentWrapper = ({
  children,
  className,
  blue,
  shadow,
  bitsBlue,
}: InsetContainerProps & React.HTMLAttributes<HTMLDivElement>) => {
  const cls = `bits-content-wrapper ${className || ""} ${blue ? "blue" : bitsBlue ? "bits-blue" : "white"} ${
    shadow ? "top-shadow" : ""
  }`;
  return (
    <div className={cls}>
      <div className="container">{children}</div>
    </div>
  );
};
