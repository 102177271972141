import { useEffect, useState } from "react";
import "./AccountSetupModal.scss";
import { useBitsUserContext } from "../../../context/SignedInContext";
import { Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import { TripleSpinner } from "../../ui/TripleSpinner";
import logger, { LogTagKey } from "../../../utils/logger";
import StepProgressBar from "../../ui/StepProgressBar";
import AccountSetupStep1 from "./AccountSetupStep1";
import AccountSetupStep2 from "./AccountSetupStep2";
import AccountSetupStep3 from "./AccountSetupStep3";
import Loading from "../../common/Loading";
import { isUserCreditLinePendingActivation, userHasTag, UserTags } from "../../auth/TagsApi";
import { getCurrencyFromCountry, isCountryUs } from "../../../utils/country";

export enum Step {
  Step1,
  Step2,
  Step3,
}

export const AccountSetupModalId = "account-setup-modal";

const TotalSteps = 3;
const currency = getCurrencyFromCountry();

const AccountSetupModal = ({ isShown, onClose }: { isShown: boolean; onClose: () => void }) => {
  const { t, ready } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { userInfo, waitAndReloadWallet, userTags, setUserTags } = useBitsUserContext();
  const [step, setStep] = useState<Step>(Step.Step1);
  const paymentApi = useApi().payment;
  const tagsApi = useApi().tags;
  const navigate = useNavigate();
  if (!isCountryUs()) {
    return null;
  }
  useEffect(() => {
    if (userTags != null) {
      if (isUserCreditLinePendingActivation(userTags)) {
        setStep(Step.Step3);
      } else if (userHasTag(userTags, UserTags.StartedApplying)) {
        setStep(Step.Step2);
      }
    }
  }, [userTags]);

  const handleClose = () => {
    onClose();
  };

  if (!ready) {
    return <TripleSpinner />;
  }

  const onStep1Next = () => {
    setLoading(true);
    tagsApi
      .replaceUserTag(UserTags.NotApplied, UserTags.StartedApplying)
      .then(() => {
        setStep(Step.Step2);
        setUserTags([{ name: UserTags.StartedApplying, enabled: true }]);
      })
      .catch((err) => {
        handleClose();
        navigate("/paymentError");
        logger.error(err, LogTagKey.TkTags, "Error adding tag to user", {
          membershipNumber: userInfo?.membershipNumber,
          tag: UserTags.StartedApplying,
        });
      })
      .finally(() => {
        setTimeout(() => setLoading(false), 3000);
      });
  };

  const onStep2Next = () => {
    setLoading(true);
    tagsApi
      .replaceUserTag(UserTags.StartedApplying, UserTags.Activated)
      .then(() => {
        setUserTags([{ name: UserTags.Activated, enabled: true }]);
        waitAndReloadWallet();
        handleClose();
      })
      .catch((err) => {
        handleClose();
        navigate("/paymentError");
        logger.error(err, LogTagKey.TkTags, "Error adding tag to user", {
          membershipNumber: userInfo?.membershipNumber,
          tag: UserTags.ApprovedPendingActivation,
        });
      })
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 2500)
      );
  };

  const onStep3Next = () => {
    setLoading(true);
    paymentApi.addCredit({ amount: 100, description: "Bits Newsletter" }).then(
      async () => {
        await tagsApi.replaceUserTag(UserTags.ApprovedPendingActivation, UserTags.Activated);
        await tagsApi.setUserTag(`${currency}${UserTags.NewsletterSubscriber}` as any, true);
        setUserTags([{ name: UserTags.Activated, enabled: true }]);
        setLoading(false);
        waitAndReloadWallet();
        handleClose();
      },
      (err) => {
        setLoading(false);
        handleClose();
        navigate("/paymentError");
        logger.error(err, LogTagKey.TkPaymentSystem, "Payment System adding credit", {
          membershipNumber: userInfo?.membershipNumber,
        });
      }
    );

    handleClose();
  };

  return (
    <Modal show={isShown} onHide={handleClose} className={`account-setup`} size="lg" id={AccountSetupModalId}>
      <Modal.Header closeButton={true} />
      <Modal.Body>
        {loading && (
          <>
            <Row className="loading-container">
              <Col>
                <Loading loading={loading} message={null} />
              </Col>
            </Row>
            <Row className="loading-message">
              <Col>{t("accountSetup.preparingYourAccount")}</Col>
            </Row>
          </>
        )}
        {!loading && (
          <div className="dialog">
            <Row>
              <Col className="progress-holder">
                <StepProgressBar steps={[...new Array(TotalSteps)]} signUpStep={step} showDone={true} />
              </Col>
            </Row>
            <Row>
              {step === Step.Step1 && <AccountSetupStep1 onNext={onStep1Next} />}
              {step === Step.Step2 && <AccountSetupStep2 onNext={onStep2Next} />}
              {step === Step.Step3 && <AccountSetupStep3 onNext={onStep3Next} />}
            </Row>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AccountSetupModal;
