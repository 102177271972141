import { postJson } from "../../utils/api";
const CREATE_PASSWORD_SERVICE = "users/create_password";
const RESET_PASSWORD_SERVICE = "users/reset_password";
const LOGIN_SERVICE = "me/login";
const REQUEST_TO_VERIFY_PHONE_SERVICE = "users/phone/verify_request";
const VERIFY_PHONE_SERVICE = "users/phone/verify";
const REGISTER_SESSION_SERVICE = "users/registration/session";
const COMPLETE_REGISTRATION_SERVICE = "users/registration/session/?/complete";
import AuthService from "./AuthService";
import logger, { LogTagKey } from "../../utils/logger";
import { UserInfo } from "../signup/SignUpApi";

export interface ILogInRequestPayload {
  email: string;
  password: string;
}

interface ILogInResponse {
  accessToken: string;
}

export interface IAuthResponse {
  code?: number;
  detail?: never[];
  message?: string;
  accessToken?: string;
}

export class AuthApi {
  static async resetPassword(email: string, utmQuery = ""): Promise<IAuthResponse> {
    const url = `${RESET_PASSWORD_SERVICE}${utmQuery}`;
    return (await postJson(url, { email })).json();
  }

  static async createPassword(password: string, token: string): Promise<IAuthResponse> {
    return (await postJson(CREATE_PASSWORD_SERVICE, { password, token })).json();
  }

  async getAccessToken(payload: ILogInRequestPayload): Promise<string> {
    return await postJson(LOGIN_SERVICE, payload)
      .then((response) => response.json())
      .then((response: ILogInResponse) => response["accessToken"]);
  }

  async logIn(email: string, password: string): Promise<void> {
    try {
      const accessToken = await this.getAccessToken({ email, password });
      AuthService.saveAuthToken(accessToken);
    } catch (e: any) {
      if (![400, 401].includes(e?.statusCode)) {
        logger.error(e, LogTagKey.TkLogin, "Login failed", undefined, { email });
      }
      throw e;
    }
  }

  async sendRequestToVerifyPhone(phoneNumber: string, identifier: string): Promise<any> {
    return (await postJson(REQUEST_TO_VERIFY_PHONE_SERVICE, { phoneNumber, identifier })).json();
  }

  async verifyPhone(token: string, identifier: string): Promise<any> {
    return (await postJson(VERIFY_PHONE_SERVICE, { token, identifier })).json();
  }

  async registerSession(userInfo: UserInfo): Promise<any> {
    return (await postJson(REGISTER_SESSION_SERVICE, userInfo)).json();
  }

  async completeRegistration(sessionId: string, userInfo: UserInfo): Promise<any> {
    return (await postJson(COMPLETE_REGISTRATION_SERVICE.replace("?", sessionId), userInfo)).json();
  }
}
