import { Card, Col, Row } from "react-bootstrap";
import { CreditAgencyList, getCurrencySymbol, isCountryUk, isCountryUs } from "../../../utils/country";
import { SubscriptionPlan } from "../../signup/SignUpApi";
import CreditClock from "../../../assets/settings/credit-clock.svg";
import { ISelected } from "./SubcriptionPlans";
import "./SubcriptionPlanInfo.scss";
import { useTranslation } from "react-i18next";
import equifaxSVG from "../../../assets/equifax.svg";
import experianSVG from "../../../assets/experian.svg";
import transunionSVG from "../../../assets/transunion.svg";
import { OnlyIf } from "../../common/OnlyIf";

export const SubscriptionPlanInfo = (props: {
  description: string[];
  highlightedPlan?: SubscriptionPlan & ISelected;
}) => {
  const { description, highlightedPlan } = props;
  const { t, ready } = useTranslation();
  if (!ready) {
    return null;
  }
  return (
    <Card className="mt-2 plan-info">
      <Card.Body>
        <Row className="mb-3 pt-3">
          <OnlyIf condition={isCountryUk()}>
            <Col className="text-center border-end" md={{ span: 3, offset: 1 }}>
              <img src={experianSVG} height={35} className="mx-2 experian" alt="Experian" />
            </Col>
            <Col className="text-center border-end" md={4}>
              <img src={equifaxSVG} height={35} className="mx-4 equifax" alt="Equifax" />
            </Col>
            <Col className="text-center" md={3}>
              <img src={transunionSVG} height={28} className="mx-4 transunion" alt="TransUnion" />
            </Col>
          </OnlyIf>
        </Row>

        <Row className="mb-3 pt-3 text-center fs-1">
          <Col>
            {t("subscription.subscriptionInfo.buildCredit")}{" "}
            <span className="fw-bolder">{t("subscription.subscriptionInfo.now")}</span>
          </Col>
        </Row>
        <Row className="my-4 text-center fw-bold">
          <Col>
            {t(`subscription.subscriptionInfo.subHeaderBuildCredit${isCountryUk() ? "Short" : ""}`, {
              creditAgencies: CreditAgencyList,
            })}
          </Col>
        </Row>
        <Row className="my-5 justify-content-center">
          <OnlyIf condition={!isCountryUs()}>
            <Col xs={12} md={6} className="clock-img me-md-5 pb-3">
              <img className="w-10" src={CreditClock} alt={t("subscription.subscriptionInfo.now")} />
            </Col>
          </OnlyIf>
          <Col className="pb-3 ms-md-3">
            {description.map((item, index) =>
              item.includes("%CREDIT_LIMIT%") ? (
                <Row key={index} className="m-1 fs-4">
                  <small>
                    <i className="bi bi-check2 check-icon fs-3" />
                    <span className="credit-limit" data-cy="plan-info-credit-limit">
                      {item.replace(
                        "%CREDIT_LIMIT%",
                        `${getCurrencySymbol(highlightedPlan?.currency)}${highlightedPlan?.creditAmount ?? "0"}`
                      )}
                    </span>
                  </small>
                </Row>
              ) : (
                <Row key={index} className="m-1 fs-4">
                  <small>
                    <i className="bi bi-check2 check-icon fs-3" />
                    {item}
                  </small>
                </Row>
              )
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
