import BitsIcon from "../cassetteUI/common/BitsIcon";
import { useTranslation } from "react-i18next";
import { useBitsUserContext } from "../../context/SignedInContext";

import "./ScoreAddressConfirm.scss";
import { Col, Row } from "react-bootstrap";
import { useEffect } from "react";
import useApi from "../../hooks/useApi";
import { useNavigate } from "react-router";
import { UserInitialKycStatus } from "./KycApi";

export const ScoreAddressConfirm = () => {
  const { userInfo, wallet } = useBitsUserContext();
  const kycApi = useApi().kyc;
  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    kycApi
      .getStatus()
      .then((res) => {
        if (res.initialKycStatus === UserInitialKycStatus.pending) {
          navigate("../kyc");
        }
      })
      .catch(() => {
        // do nothing
      });
  }, []);

  const handleSubmit = () => {
    navigate("confirm");
  };

  return (
    <>
      <div className="unAuth-card" id="address-confirm">
        <BitsIcon />
        <div className="signup-header">
          <h5 className="title"> {t("score.confirm.header")}</h5>
        </div>
        <div className="body form-wrapper">
          <div className="address-container">
            <div className="title">{t("score.confirm.name")}</div>
            <div>
              {userInfo?.title} {userInfo?.firstName} {userInfo?.lastName}
            </div>
            <br />
            <div className="title">{t("score.confirm.postalAddress")}</div>
            <div>{userInfo?.address1}</div>
            <div>{userInfo?.address2}</div>
            <div>{userInfo?.address3}</div>
            <div>{userInfo?.city}</div>
            <div>{userInfo?.postCode}</div>
          </div>
          <button
            type="button"
            className="form-input enabled w-100"
            id="sign-up-important-information-submit"
            onClick={handleSubmit}
            disabled={wallet == null}
          >
            {t("auth.forms.continue")}
          </button>
          <Row className="bottom-message">
            <Col sm={1}>
              <i className="bi bi-info-circle"></i>
            </Col>
            <Col className="text">
              <p>{t("score.confirm.bottom1")}</p>
              <p>{t("score.confirm.bottom2")}</p>
              <p>{t("score.confirm.bottom3")}</p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
