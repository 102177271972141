import { useRawHTML } from "../../hooks/useRawHTML";

interface RawHTMLProps {
  html?: string;
  className?: string;
}
export const RawHTML = ({ html, className }: RawHTMLProps) => {
  if (!html) {
    return null;
  }
  const sanitizedHTML = useRawHTML(html);
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} className={className} />;
};
