import * as Sentry from "@sentry/react";
import { Severity, User } from "@sentry/react";
import { Extras } from "@sentry/types";
import { DeploymentEnv } from "../components/common/CustomHead";
import { version } from "../../package.json";

export enum LogTagKey {
  TkSignup = "Signup",
  TkSubscription = "Subscription",
  TkLogin = "Login",
  TkResetPassword = "Reset Password",
  TkCreatePassword = "Create Password",
  TkPaymentSystem = "Payment System",
  TkAuth = "Auth",
  TkStoreCategories = "Store Categories",
  TkStoreProducts = "Store Products",
  TkStoreOrders = "Store Orders",
  TkStoreProduct = "Store Product Details",
  TkStoreSearch = "Store Search",
  TKAddressChange = "Settings Address Change",
  TkCheckout = "Checkout",
  TKCheckoutDeliveryAddress = "Checkout delivery address",
  TKVideo = "Video",
  TkTags = "Tags",
  TKPeriodicStatements = "Periodic Statements",
  TkGeneric = "Generic error",
  TkWalletPass = "Wallet pass",
  TkScore = "Score",
  TkKyc = "KYC",
}
interface ILog {
  setUser: (email: string, membershipNumber: string) => void;
  debug: (message: string, tagKey: string, tagValue: string, user?: User) => void;
  info: (message: string, tagKey: string, tagValue: string, user?: User) => void;
  warn: (error: any, tagKey: string, tagValue: string, user?: User) => void;
  error: (error: any, tagKey: string, tagValue: string, user?: User) => void;
}

export class Logger implements ILog {
  private sentryDns: string;
  private isDisable: boolean;
  constructor() {
    this.sentryDns = process.env.REACT_APP_SENTRY_DNS || "";
    this.isDisable = this.sentryDns === undefined || this.sentryDns === "";
    this.init();
  }
  private init = (): void => {
    !this.isDisable &&
      Sentry.init({
        dsn: this.sentryDns,
        environment: process.env.REACT_APP_DEPLOYMENT_ENV || DeploymentEnv.Dev,
        release: version,
        /* integrations: [new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    })],*/
        tracesSampleRate: 1.0,
        debug: false,
      });
  };
  // Set user for global scope for logged in users
  setUser = (userEmail?: string, membershipNumber?: string) => {
    Sentry.configureScope((scope) => scope.clear());
    Sentry.setUser({ email: userEmail, id: membershipNumber });
  };
  debug = (message: string, tagKey: string, tagValue: string, extras?: Extras, user?: User) => {
    this.captureMessage(Severity.Debug, message, tagKey, tagValue, extras, user);
  };
  info = (message: string, tagKey: string, tagValue: string, extras?: Extras, user?: User) => {
    this.captureMessage(Severity.Info, message, tagKey, tagValue, extras, user);
  };
  warn = (error: any, tagKey: string, tagValue: string, extras?: Extras, user?: User) => {
    this.captureException(Severity.Warning, error, tagKey, tagValue, extras, user);
  };
  error = (error: any, tagKey: string, tagValue: string, extras?: Extras, user?: User) => {
    this.captureException(Severity.Error, error, tagKey, tagValue, extras, user);
  };
  private captureException = (
    serverity: Severity,
    error: any,
    tagKey: string,
    tagValue: string,
    extras?: Extras,
    user?: User
  ) => {
    !this.isDisable &&
      Sentry.withScope((scope) => {
        scope.setLevel(serverity);
        tagKey && tagValue && scope.setTag(tagKey, tagValue);
        extras && scope.setExtras(extras);
        user && scope.setUser(user);
        if (error instanceof Error) {
          Sentry.captureException(error);
        } else {
          Sentry.captureException(
            new Error(`${tagValue} - Error Detail: ${JSON.stringify(error, Object.getOwnPropertyNames(error))}`)
          );
        }
      });
  };
  private captureMessage = (
    serverity: Severity,
    message: string,
    tagKey: string,
    tagValue: string,
    extras?: Extras,
    user?: User
  ) => {
    !this.isDisable &&
      Sentry.withScope((scope) => {
        scope.setLevel(serverity);
        tagKey && tagValue && scope.setTag(tagKey, tagValue);
        extras && scope.setExtras(extras);
        user && scope.setUser(user);
        Sentry.captureMessage(message, Sentry.Severity.Info);
      });
  };
}
const logger = new Logger();
export default logger;
