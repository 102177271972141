import { Link } from "react-router-dom";
import { useBitsUserContext } from "../../../context/SignedInContext";
import logo from "../../../assets/logo/logo.webp";
import "./logo.scss";

interface BitsLogoProps {
  to?: string;
  className?: string | undefined;
}

export const BitsLogo = ({ className, to = "/dashboard" }: BitsLogoProps) => {
  const { setMobileSidebar } = useBitsUserContext();
  const classes = `sidebar-bits-logo-wrapper ${className || ""}`;
  return (
    <div className={classes}>
      <Link to={to} id="bits-logo" onClick={() => setMobileSidebar(false)}>
        <img src={logo} alt="Bits Logo" />
      </Link>
    </div>
  );
};
