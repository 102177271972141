import { ReactNode, useEffect, useState } from "react";

interface OnlyIfProps {
  condition: boolean;
  children: ReactNode;
}

export const OnlyIf = ({ condition, children }: OnlyIfProps) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(condition);
  }, [condition]);

  if (!show) {
    return null;
  }

  return <>{children}</>;
};
