import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { Extras } from "@sentry/types";
import logger from "./logger";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BASE_SALEOR_API_URL ?? location.origin,
});

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  // Log error for graphQL error
  if (graphQLErrors) {
    const extrasData: Extras = {};
    extrasData.operationName = operation.operationName;

    graphQLErrors.forEach(({ message, extensions }, index) => {
      extrasData[`message${index}`] = message;
      extrasData[`stacktrace${index}`] = JSON.stringify(extensions, Object.getOwnPropertyNames(extensions));
    });
    logger.error(networkError, "SaleorGraphQl", `GraphQLError`, extrasData);
  }
  // Log error for network error
  if (networkError) {
    logger.error(networkError, "SaleorGraphQl", `NetworkError`, {
      name: networkError.name,
      message: networkError.message,
    });
  }
});

export const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache(),
});
