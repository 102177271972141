import jwtDecode, { JwtPayload } from "jwt-decode";

export default class AuthService {
  private static AUTH_TOKEN_KEY = "bits-store-access-token";

  static isAuthenticated() {
    const token = this.getAuthToken();
    if (token === null) {
      return false;
    }

    try {
      const decoded = jwtDecode<JwtPayload>(token);
      if (Date.now() >= (decoded?.exp || 0) * 1000) {
        return false;
      }
    } catch (e) {
      return false;
    }

    return true;
  }

  static getAuthToken(): string | null {
    return localStorage.getItem(this.AUTH_TOKEN_KEY);
  }

  static clearAuthToken(): void {
    localStorage.removeItem(this.AUTH_TOKEN_KEY);
  }

  static saveAuthToken(token: string): void {
    localStorage.setItem(this.AUTH_TOKEN_KEY, token);
  }
}
