import { TrustPilotBox } from "../common/TrustPilotBox";
import "../cassetteUI/signupFlow/SignUpFlow.scss";
import { Col, Container, Row } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import { ScoreAddressConfirm } from "./ScoreAddressConfirm";
import "../cassetteUI/signupFlow/SignUpFlow.scss";
import "./ScoreApply.scss";
import { ESIGNMessage } from "../common/ESIGNMessage";
import { ScoreKyc } from "./ScoreKyc";
import { ScoreDataConfirm } from "./ScoreDataConfirm";
import { ScoreApplyComplete } from "./ScoreApplyComplete";
import { ErrorMessage } from "../error/ErrorMessage";
import { useBitsUserContext } from "../../context/SignedInContext";
import { useTranslation } from "react-i18next";
import { TripleSpinner } from "../ui/TripleSpinner";
import { ScoreError } from "./ScoreError";

export const ScoreApply = () => {
  const { errorMessage } = useBitsUserContext();

  const { ready, t } = useTranslation();
  if (!ready) {
    return <TripleSpinner />;
  }
  const showBottomInfo = errorMessage !== t("score.kyc.mismatchMessage");

  return (
    <>
      <div className="welcome-layout" id="score-apply">
        <Container className="pb-3">
          <Row className="justify-content-center">
            <Col>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <Routes>
                <Route index element={<ScoreAddressConfirm />} />
                <Route path="kyc" element={<ScoreKyc />} />
                <Route path="confirm" element={<ScoreDataConfirm />} />
                <Route path="complete" element={<ScoreApplyComplete />} />
                <Route path="error" element={<ScoreError />} />
              </Routes>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>{showBottomInfo ? <ESIGNMessage /> : <div className="bottom-placeholder">&nbsp;</div>}</Col>
          </Row>
          {showBottomInfo && <TrustPilotBox />}
        </Container>
      </div>
    </>
  );
};
