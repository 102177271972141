export const JSONParse = (data: any, defaultValue = {}) => {
  try {
    return JSON.parse(data) ?? defaultValue;
  } catch (e: unknown) {
    return defaultValue;
  }
};

export class PlatformResolver {
  static isApple = (): boolean => {
    const platform = navigator.platform.toLowerCase();
    return /mac/.test(platform) || ["iphone", "ipad", "ipod"].includes(platform);
  };
}
