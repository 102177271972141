import { useEffect } from "react";
import { Modal } from "react-bootstrap";

interface IAutoClosableModal {
  show: boolean;
  setHide: () => void;
  secondsToDisplay: number;
  dialogClassName: string;
  children: JSX.Element[];
}

const useAutoClosableModal = (show: boolean, setHide: () => void, secondsToDisplay: number) => {
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setHide();
      }, secondsToDisplay * 1000);
    }
  }, [show]);
  return {};
};

export const AutoClosableModal = ({
  show,
  setHide,
  secondsToDisplay,
  dialogClassName,
  children,
}: IAutoClosableModal) => {
  useAutoClosableModal(show, setHide, secondsToDisplay);
  return (
    <Modal show={show} dialogClassName={dialogClassName}>
      {children}
    </Modal>
  );
};
