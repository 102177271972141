import { SnapchatPixelAPI } from "../utils/analytics/snapchatPixel";
import { useEffect } from "react";
import { getCookieConsentValue } from "react-cookie-consent";

export const useTrackSnapchat = () => {
  const initializeSnapchatPixel = () => {
    try {
      const pixelId = process.env.REACT_APP_SNAPCHAT_PIXEL_ID ?? "";
      const allowTracking = getCookieConsentValue() === "true";
      if (!pixelId || !allowTracking) {
        return null;
      }
      const snapchatTrackerInstance = SnapchatPixelAPI.getInstance(pixelId);
      snapchatTrackerInstance.init(allowTracking);
      return snapchatTrackerInstance;
    } catch (e: any) {
      console.error(e);
      return null;
    }
  };

  const trackSnapchatPageView = (userInfo?: any) => {
    const trackerInstance = initializeSnapchatPixel();
    if (trackerInstance) {
      trackerInstance.trackPageView(userInfo);
    }
  };

  const trackSnapchatPurchase = (price?: string, userInfo?: any) => {
    const trackerInstance = initializeSnapchatPixel();
    if (trackerInstance) {
      trackerInstance.trackPurchase(price, undefined, userInfo);
    }
  };

  useEffect(() => {
    initializeSnapchatPixel();
  }, []);

  return {
    trackSnapchatPageView,
    trackSnapchatPurchase,
  };
};
