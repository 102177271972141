import { getJson, postJson } from "../../utils/api";
import { isCountryUs } from "../../utils/country";

const TAGS_ENDPOINT = "me/tags";

export interface UserTag {
  name: string;
  enabled: boolean;
}

export enum UserTags {
  NotApplied = "Not applied",
  StartedApplying = "Started Applying",
  ApprovedPendingActivation = "Approved, pending Activation",
  Activated = "Activated",
  NewsletterSubscriber = "1 Newsletter Subscriber",
}

export class TagsApi {
  async getUserTags(): Promise<UserTag[]> {
    return await getJson(TAGS_ENDPOINT).then((response) => response.json());
  }

  async setUserTag(name: UserTags, enabled: boolean): Promise<boolean> {
    return await postJson(TAGS_ENDPOINT, { name, enabled }).then((response) => response.json());
  }

  async replaceUserTag(oldTag: UserTags, newTag: UserTags): Promise<boolean> {
    return this.setUserTag(oldTag, false).then(() => this.setUserTag(newTag, true));
  }
}

export const userHasTag = (userTags: UserTag[], tag: UserTags): boolean =>
  userTags.some((ut) => ut.name === tag && ut.enabled);

export const isUserCreditLineActiveOrPending = (userTags: UserTag[]) =>
  !isCountryUs()
    ? true
    : userHasTag(userTags, UserTags.ApprovedPendingActivation) || userHasTag(userTags, UserTags.Activated);

export const isUserCreditLinePendingActivation = (userTags: UserTag[]) =>
  !isCountryUs() ? true : userHasTag(userTags, UserTags.ApprovedPendingActivation);

export const isUserCreditLineActivated = (userTags: UserTag[]) =>
  !isCountryUs() ? true : userHasTag(userTags, UserTags.Activated);
