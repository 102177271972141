import { putJson } from "../../../utils/api";
import { Address, UserInfo } from "../../signup/SignUpApi";

export class PostalAddressApi {
  async updateAddress(address: Address): Promise<UserInfo> {
    const { address1, address2, address3, city, postCode, state, addressId } = address;
    return await putJson(
      "me",
      { address1, address2, address3, city, postCode, state, addressId },
      process.env.REACT_APP_BASE_API_URL
    ).then((response) => response.json());
  }
}
