import { IPlanDetails } from "../../../utils/contentful";
import { Row, Col } from "react-bootstrap";
import { SubscriptionPlan } from "../../payment/SubscriptionApi";
import { getCurrencySymbol } from "../../../utils/country";

export interface IPlanDetailsList {
  planDetails: IPlanDetails | undefined;
  subscriptionPlan: SubscriptionPlan;
}

export const PlanDetailsList = ({ planDetails, subscriptionPlan }: IPlanDetailsList) => {
  return (
    <Row className="plan-details mt-4">
      <Col className="fs-6" data-cy="plan-details">
        <ul>
          {planDetails?.creditLimit && (
            <li>
              {planDetails?.creditLimit?.replace(
                "%CREDIT_LIMIT%",
                `${getCurrencySymbol(subscriptionPlan?.currency)}${subscriptionPlan?.creditAmount ?? "0"}`
              )}
            </li>
          )}
          {planDetails?.descriptions?.map((description: string) => (
            <li key={description}>{description}</li>
          ))}
        </ul>
      </Col>
    </Row>
  );
};
