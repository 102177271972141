import { Col } from "react-bootstrap";

export const OrderSummaryHeading = ({ title }: { title: string }): JSX.Element => {
  return (
    <div id="order-summary-heading">
      <Col className="col-12 mb-5">
        <div id="your-cart-title">
          <h1 id="your-cart_heading">{title}</h1>
        </div>
      </Col>
    </div>
  );
};
