import React from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TripleSpinner } from "../ui/TripleSpinner";

const CheckoutForm = (props: { errorMessage: string | undefined; isLoading: boolean }) => {
  const { errorMessage, isLoading } = props;
  const { t, ready } = useTranslation();

  if (!ready) {
    return <TripleSpinner />;
  }

  return (
    <>
      <Row className="text-center">
        <Col>{t("payment.forms.payment.redirectMessage")}</Col>
      </Row>
      {isLoading && <TripleSpinner />}
      {errorMessage && (
        <Row>
          <Col className="text-center">
            <Alert key="alertResponse" variant="danger">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </>
  );
};
export default CheckoutForm;
