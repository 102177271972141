import { HelmetProvider } from "react-helmet-async";
import { CustomHead } from "./components/common/CustomHead";
import { useLayoutEffect } from "react";
import { ContentfulContextProvider } from "./context/ContentfulContext";
import { AppWrapper } from "./AppWrapper";
import { CassetteAppWrapper } from "./cassetteAppWrapper";
import { isCountryUk } from "./utils/country";

function App() {
  const isUK = isCountryUk();
  console.log("Country is UK", isCountryUk());
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <HelmetProvider>
      <ContentfulContextProvider>
        <CustomHead />
        {isUK ? <CassetteAppWrapper /> : <AppWrapper />}
      </ContentfulContextProvider>
    </HelmetProvider>
  );
}

export default App;
