const USA_PHONE_PATTERN = "^1[0-9]{10}$|^\\+[1]{1}[0-9]{10}$|^[0]{2}[1]{1}[0-9]{10}$|^[0-9]{10}$";
const UK_PHONE_PATTERN =
  "^00447[0-9]{9}$|^\\+[4]{2}7[0-9]{9}$|^07[0-9]{9}$|^7[0-9]{9}$|^447[0-9]{9}$|^[0]{2}447[0-9]{9}$";

export class CountryResolver {
  static getCountry = () => {
    return process.env.REACT_APP_COUNTRY_CODE ?? Country.Us;
  };
}

export enum Country {
  Us = "us",
  Uk = "uk",
}

export const getCountry = () => {
  return CountryResolver.getCountry();
};

export const isCountryUk = () => {
  return getCountry() === Country.Uk;
};

export const isCountryUs = () => {
  return getCountry() === Country.Us;
};

export const getValidAge = () => {
  switch (getCountry()) {
    case Country.Us: {
      return 18; //2021-11-29 [USA] Change minimum age for using the service from 21 to 18
    }
    default:
      return 18;
  }
};

const countryDateFormatMap = new Map([
  [Country.Uk, "DD-MM-YYYY"],
  [Country.Us, "MM-DD-YYYY"],
]);

const countryLongDateFormatMap = new Map([
  [Country.Uk, "DD MMM YYYY"],
  [Country.Us, "MMM, DD YYYY"],
]);

const countrySaleorCodeMap = new Map([
  [Country.Uk, "GB"],
  [Country.Us, "US"],
]);

const currencySymbolMap = new Map([
  ["GBP", "£"],
  ["USD", "$"],
]);

const currencyCountryMap = new Map([
  [Country.Uk, "£"],
  [Country.Us, "$"],
]);

const countryLanguageFormat = new Map([
  [Country.Uk, "en-GB"],
  [Country.Us, "en-US"],
]);

export const getCountryDateFormat = (countryCode?: any) => {
  return countryDateFormatMap.get(countryCode ?? getCountry()) ?? countryDateFormatMap.get(Country.Us) ?? "MM-DD-YYYY";
};

export const getCountryLongDateFormat = (countryCode?: any) => {
  return (
    countryLongDateFormatMap.get(countryCode ?? getCountry()) ??
    countryLongDateFormatMap.get(Country.Us) ??
    "MMM DD YYYY"
  );
};

export const getCountrySaleorCode = (countryCode?: any) => {
  return countrySaleorCodeMap.get(countryCode ?? getCountry()) ?? countrySaleorCodeMap.get(Country.Us);
};

const currencySymbolCountryMap = new Map([
  [Country.Uk, "GBP"],
  [Country.Us, "USD"],
]);

export const getCurrencyFromCountry = (countryCode?: any) => {
  return currencyCountryMap.get(countryCode ?? getCountry()) ?? currencyCountryMap.get(Country.Us) ?? "$";
};

export const getCurrencySymbolFromCountry = (countryCode?: any) => {
  return currencySymbolCountryMap.get(countryCode ?? getCountry()) ?? currencySymbolCountryMap.get(Country.Us) ?? "USD";
};

export const getCurrencySymbol = (currency?: string) => {
  const defaultCurrency = "£";
  if (currency === undefined) {
    return defaultCurrency;
  }
  return currencySymbolMap.get(currency.toUpperCase()) ?? defaultCurrency;
};

export const getCountryLanguageFormat = (countryCode?: any) => {
  return countryLanguageFormat.get(countryCode ?? getCountry()) ?? countryLanguageFormat.get(Country.Uk) ?? "en-GB";
};

export const formatPhoneUSANumber = (phoneNumber = ""): string => {
  const phoneNumberToFormat: string = phoneNumber?.replace(/\D|ext/gi, "");
  if (phoneNumberToFormat?.length < 10) {
    return phoneNumberToFormat;
  }
  return "+1 " + phoneNumberToFormat.replace(/\d*(\d{3})\D*(\d{3})\D*(\d{4})$/, "$1 $2 $3");
};

export const formatPhoneUKNumber = (phoneNumber = ""): string => {
  const phoneNumberToFormat: string = phoneNumber?.replace(/\D|ext/gi, "");
  if (phoneNumberToFormat?.length < 10) {
    return phoneNumberToFormat;
  }
  return (
    "+44 " +
    phoneNumberToFormat
      .replace(/ /g, "")
      .replace("+44", "")
      .replace(/\d*(\d{5})\D*(\d{5})$/, "$1 $2")
  );
};

export const validateUSAPhoneNumber = (phoneNumber: string) =>
  new RegExp(USA_PHONE_PATTERN, "g").test(phoneNumber?.replace(/[\s()\-\\.]/gi, ""));

export const validateUKPhoneNumber = (phoneNumber: string) =>
  new RegExp(UK_PHONE_PATTERN, "g").test(phoneNumber.replace(/ /g, "").replace(/\+/, "00"));

export const USA_STATE_NAMES: any = {
  NONE: "",
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawai",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export interface ExternalLinks {
  termsConditionsUrl: string;
  termsUrl?: string;
  policyPrivacyUrl: string;
  esignUrl?: string;
  faqsUrl: string;
  rentReporting?: string;
  marketplace?: string;
  bitsSchoolUrl: string;
  retailChargeAgreementUrl?: string;
  email: string;
  complaints?: string;
  home?: string;
  vulnerableCustomers?: string;
  beforeYouStart?: string;
  contactUs?: string;
  bitsStory?: string;
  workingAtBits?: string;
  facebookUrl?: string;
  youtubeUrl?: string;
  twitterUrl?: string;
  instagramUrl?: string;
  trustpilotId?: string;
  trustpilotDomain?: string;
  support?: string;
  termsConditionsUrlSimplified?: string;
  termsUrlSimplified?: string;
  policyPrivacyUrlSimplified?: string;
  complaintsSimplified?: string;
  marketplaceUrl?: string;
  risksBenefitsUrl?: string;
  freeCreditReportUrl?: string;
  regulatoryStatusUrl?: string;
  trustpilotUrl?: string;
  usaSiteUrl?: string;
  scoreInAppUrl?: string;
  creditCardHomeUrl?: string;
  creditCardFaqsUrl?: string;
  creditCardTermsAndConditionsUrl?: string;
  creditCardFXCalculatorUrl?: string;
  creditCard3dsFaqsUrl?: string;
  creditCardPrivacyPolicyUrl?: string;
  creditCardHowToApplyUrl?: string;
}

export const getExternalLinks = (): ExternalLinks => {
  const LANDING_URL = isCountryUk() ? "https://www.getbits.app" : "https://getbits.app/us";
  switch (getCountry()) {
    case Country.Us: {
      return {
        termsUrl: `${LANDING_URL}/store-storecard-termsandconditions`,
        termsConditionsUrl: `${LANDING_URL}/terms-conditions`,
        policyPrivacyUrl: `${LANDING_URL}/privacy-policy`,
        esignUrl: `${LANDING_URL}/e-sign`,
        faqsUrl: `${LANDING_URL}/faqs`,
        bitsSchoolUrl: "https://www.youtube.com/c/Bitsapp/featured",
        retailChargeAgreementUrl: `${LANDING_URL}/retail-charge-agreement`,
        email: "usa@getbits.app",
        twitterUrl: "https://twitter.com/getabit",
        instagramUrl: "https://www.instagram.com/getbitsapp/",
        facebookUrl: "https://www.facebook.com/getabit",
      };
    }
    case Country.Uk:
    default:
      return {
        termsConditionsUrl: `${LANDING_URL}/terms-conditions`,
        termsUrl: `${LANDING_URL}/store-storecard-termsandconditions`,
        policyPrivacyUrl: `${LANDING_URL}/privacy-policy`,
        faqsUrl: `${LANDING_URL}/faqs`,
        rentReporting: `${LANDING_URL}/rentreporting`,
        marketplace: `${LANDING_URL}/marketplace`,
        bitsSchoolUrl: "https://www.youtube.com/c/Bitsapp/featured",
        email: "hello@getbits.app",
        complaints: `${LANDING_URL}/complaints`,
        support: `${LANDING_URL}/support`,
        home: "https://www.getbits.app/",
        vulnerableCustomers: `${LANDING_URL}/vulnerable-customers`,
        beforeYouStart: `${LANDING_URL}/before-starting`,
        facebookUrl: "https://www.facebook.com/getabit",
        youtubeUrl: "https://www.youtube.com/c/Bitsapp/featured",
        instagramUrl: "https://www.instagram.com/getbitsapp/",
        twitterUrl: "https://twitter.com/getabit",
        trustpilotId: "5e676d2e7935f90001f6efc8",
        trustpilotDomain: "https://www.trustpilot.com/review/getbits.app",
        termsConditionsUrlSimplified: `${LANDING_URL}/terms-conditions-simplified`,
        termsUrlSimplified: `${LANDING_URL}/storecard-terms-conditions-simplified`,
        policyPrivacyUrlSimplified: `${LANDING_URL}/privacy-policy-simplified`,
        complaintsSimplified: `${LANDING_URL}/complaints-simplified`,
        marketplaceUrl: `${LANDING_URL}/marketplace`,
        risksBenefitsUrl: `${LANDING_URL}/risks-benefits`,
        freeCreditReportUrl: `${LANDING_URL}/free-credit-reports`,
        regulatoryStatusUrl: `${LANDING_URL}/regulatory-status`,
        trustpilotUrl: "https://uk.trustpilot.com/review/getbits.app",
        usaSiteUrl: "https://www.getbits.app/usa",
        scoreInAppUrl: `${LANDING_URL}/score-in-app`,
        creditCardHomeUrl: `${LANDING_URL}/Card-home`,
        creditCardFaqsUrl: `${LANDING_URL}/card-faqs`,
        creditCardTermsAndConditionsUrl: `${LANDING_URL}/termsandconditions_for_card`,
        creditCardFXCalculatorUrl: `${LANDING_URL}/FX-calculator`,
        creditCard3dsFaqsUrl: `${LANDING_URL}/3DS-faqs`,
        creditCardPrivacyPolicyUrl: `${LANDING_URL}/card-privacy-policy-terms`,
        creditCardHowToApplyUrl: `${LANDING_URL}/how-to-apply`,
      };
  }
};

const getCreditAgencyList = () => {
  switch (getCountry()) {
    case Country.Uk:
      return "Experian, Equifax (partnered with ClearScore) and TransUnion (partnered with Credit Karma)";
    default:
      return "Experian";
  }
};

export const getCreditAgencyListShort = () => {
  switch (getCountry()) {
    case Country.Uk:
      return "Experian, Equifax (ClearScore) and TransUnion";
    default:
      return "Experian";
  }
};

export const CreditAgencyList = getCreditAgencyList();

export const getStoreUrl = (country: string = Country.Us) => {
  switch (country) {
    case Country.Uk:
      return process.env.REACT_APP_WEB_STORE_UK_URL || "https://uk.getbits.app";
    default:
      return process.env.REACT_APP_WEB_STORE_US_URL || "https://us.getbits.app";
  }
};

export const getCompanyName = () => {
  switch (getCountry()) {
    case Country.Uk:
      return "© Fea Card Limited,";
    case Country.Us:
    default:
      return "© Bits Card, Inc.";
  }
};
