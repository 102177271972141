import React from "react";

export function useStickyState<T>(defaultValue: T | null | undefined, key: string) {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.sessionStorage.getItem(key);
    return stickyValue != null ? (stickyValue === "undefined" ? undefined : JSON.parse(stickyValue)) : defaultValue;
  });
  React.useEffect(() => {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}
