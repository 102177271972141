import { useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import "./country-switch.scss";
import { Country, getStoreUrl, isCountryUs } from "../../utils/country";
import Check from "../../assets/icons/footer/check.svg";
import ChevronDown from "../../assets/icons/footer/chevron-down.svg";
import { useTranslation } from "react-i18next";

const US_WEB_STORE_URL = getStoreUrl(Country.Us);
const UK_WEB_STORE_URL = getStoreUrl(Country.Uk);
export const CountrySwitch = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(Country.Uk);
  const [redirectTo, setRedirectTo] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isCountryUs()) {
      setSelected(Country.Us);
    } else {
      setSelected(Country.Uk);
    }
  }, []);

  useEffect(() => {
    if (!redirectTo) {
      return;
    }
    window.location.href = redirectTo;
  }, [redirectTo]);

  return (
    <>
      {isCountryUs() && <p className="country-switch-title">{t("common.footer.selectWebsite")}</p>}
      <div className="dropDown" onClick={() => setIsOpen(!isOpen)}>
        <Image src={Check} alt="check icon" width={16} height={16} />
        <span className="location">{t(`common.footer.${selected}.country`)}</span>
        <div className="chevronDown">
          <Image src={ChevronDown} alt="down arrow icon" width={16} height={16} />
        </div>
        <div className="select" style={!isOpen ? { opacity: "0" } : { opacity: "1" }}>
          {selected === Country.Us ? (
            <span
              onClick={() => {
                setSelected(Country.Uk);
                setRedirectTo(UK_WEB_STORE_URL);
              }}
            >
              {t("common.footer.uk.country")}
            </span>
          ) : (
            <span
              onClick={() => {
                setSelected(Country.Us);
                setRedirectTo(US_WEB_STORE_URL);
              }}
            >
              {t("common.footer.us.country")}
            </span>
          )}
        </div>
      </div>
    </>
  );
};
