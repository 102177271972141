import { fetchApi, getJson, postJson, putJson } from "../../utils/api";

export const SESSION_STORAGE_TOKEN = "bitsToken";

export interface SubscriptionPlan {
  subscriptionPlanId: number;
  stripePlanId: string;
  name: string;
  title: string;
  subtitle: string;
  availability?: string;
  amount: number;
  currency: string;
  interval: string;
  creditAmount: number;
  order: number;
  isDefault: boolean;
  renewalPeriod?: number;
}

export interface UserInfo {
  address1?: string;
  address2?: string;
  address3?: string;
  addressId?: string;
  houseNumber?: string;
  city?: string;
  dateBirth?: string;
  email?: string;
  emergencyContact?: string;
  firstName?: string;
  gender?: string;
  lastName?: string;
  password?: string;
  phoneNumber?: string;
  phoneNumberToken?: string;
  postCode?: string;
  referredBy?: string;
  sessionId?: string;
  identifier?: string;
  membershipNumber?: string;
  country?: string;
  state?: string; // for us
  placeId?: string;
  userId?: string;
  termsAccepted?: boolean;
  title?: string;
  addressSuggestions?: AddressSuggestion[];
  referral?: string;
}

export interface RegistrationSessionUpdate {
  address1?: string;
  address2?: string;
  address3?: string;
  addressId?: string;
  city?: string;
  dateBirth?: string;
  emergencyContact?: string;
  firstName?: string;
  gender?: string;
  lastName?: string;
  password?: string;
  phoneNumber?: string;
  phoneNumberToken?: string;
  postCode?: string;
  referredBy?: string;
  title?: string;
}

export interface RegistrationSessionCreate extends RegistrationSessionUpdate {
  email: string;
}

export interface RegistrationSessionComplete extends RegistrationSessionUpdate {
  password: string;
  country?: string;
  state?: string; // for us
  placeId?: string;
}

export interface Address {
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  postCode?: string;
  country?: string;
  state?: string; // for us
  placeId?: string;
  addressId?: string;
}

export interface AddressSuggestion {
  suggestion?: string;
  address_id: string;
}

export interface AggregatorTrackingModel {
  aggregatorUid: string;
  aggregatorName: string;

  userId?: string;
  signUpDate?: string;

  subscribeDate?: string;
  unsubscribeDate?: string;
  planAmount?: number;

  creditCardApplicationId?: string; // eligibilityId ??
  creditCardApplicationStartedDate?: string;
  creditCardApplicationCompletedDate?: string;
  creditCardApplicationSuccess?: boolean;

  dateCreated?: string;
  dateUpdated?: string;
}

export class SignUpApi {
  async registerSession(payload: RegistrationSessionCreate): Promise<UserInfo> {
    return (await postJson("users/registration/session", payload)).json();
  }

  async updateSession(sessionId: string, payload: RegistrationSessionUpdate): Promise<UserInfo> {
    return (await putJson(`users/registration/session/${sessionId}`, payload)).json();
  }

  async completeSession(sessionId: string, payload: RegistrationSessionComplete): Promise<string> {
    return await postJson(`users/registration/session/${sessionId}/complete`, payload)
      .then((response) => response.json())
      .then((response) => response["accessToken"]);
  }

  async createAndCompleteSession(userInfo: UserInfo): Promise<string> {
    const payload: RegistrationSessionCreate = {
      email: userInfo.email || "",
    };
    const newUserInfoCreate = await this.registerSession(payload);

    const payloadComplete: RegistrationSessionComplete = {
      address1: userInfo.address1,
      address2: userInfo.address2,
      address3: userInfo.address3,
      addressId: userInfo.addressId,
      city: userInfo.city,
      postCode: userInfo.postCode,
      dateBirth: userInfo.dateBirth,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      emergencyContact: userInfo.emergencyContact,
      referredBy: userInfo.referredBy,
      phoneNumber: userInfo.phoneNumber,
      password: userInfo.password || "",
      country: userInfo.country,
      state: userInfo.state, // for us
      placeId: userInfo.placeId,
    };

    return await this.completeSession(newUserInfoCreate.sessionId || "", payloadComplete);
  }

  async phoneVerifyRequest(phoneNumber: string, identifier: string): Promise<Response> {
    return (await postJson("users/phone/verify_request", { identifier: identifier, phoneNumber: phoneNumber })).json();
  }

  async phoneVerify(token: string, identifier: string): Promise<Response> {
    return (await postJson("users/phone/verify", { identifier: identifier, token: token })).json();
  }

  // Search address - UK only
  async searchAddress(query: string): Promise<AddressSuggestion[]> {
    return await postJson("address/search", { query: query })
      .then((response) => response.json())
      .then((response) => response["items"]);
  }

  async getAddress(addressId: string): Promise<Address> {
    return (
      await fetchApi(`address/${addressId}`, {
        method: "GET",
      })
    ).json();
  }

  async fetchProfile(): Promise<UserInfo> {
    return (await fetchApi(`me`, { method: "GET" })).json();
  }

  async validatePlaceId(placeId: string): Promise<any> {
    return (await getJson(`/address/${placeId}/google`)).json();
  }

  async searchAddressGoogle(query: string): Promise<AddressSuggestion[]> {
    return await postJson("address/search/google", { query })
      .then((response) => response.json())
      .then((response) => response["items"]);
  }

  async saveEmailInMailchimpList(email: string) {
    return await postJson("email", { email }, process.env.REACT_APP_BASE_SIGNUP_EMAIL_STORAGE)
      .then((response) => response.json())
      .then((response) => response["data"]);
  }

  async tagListMember(email: string, activeSubscription = false, activeSupercharge = false) {
    return await putJson(
      `/email/${email}`,
      { activeSubscription, activeSupercharge },
      process.env.REACT_APP_BASE_SIGNUP_EMAIL_STORAGE
    )
      .then((response) => response.json())
      .then((response) => response["data"]);
  }

  async tagListMemberSupercharge(email: string) {
    return await this.tagListMember(email, false, true);
  }

  async tokenExchange(token: string): Promise<string> {
    return await postJson(`me/exchangeToken`, { token })
      .then((response) => response.json())
      .then((response) => response["accessToken"]);
  }

  async aggregatorTrackingInit(aggregatorName: string, aggregatorUid?: string): Promise<AggregatorTrackingModel> {
    return await postJson(`aggregator/init`, { aggregatorName, aggregatorUid })
      .then((response) => response.json())
      .then((response) => response["data"]);
  }
}
