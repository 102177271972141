import { Trans, useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { TripleSpinner } from "../../ui/TripleSpinner";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Country, CreditAgencyList, getCountry, getCurrencySymbol, getExternalLinks } from "../../../utils/country";
import { useBitsUserContext } from "../../../context/SignedInContext";
import { getPlanWithDefaultCreditLimit } from "../../../utils/subscription";
import { SubscriptionPlan } from "../../payment/SubscriptionApi";
import useApi from "../../../hooks/useApi";

interface IAccountSetupStep2 {
  onNext: () => void;
}

const AccountSetupStep2 = ({ onNext }: IAccountSetupStep2) => {
  const { t, ready } = useTranslation();
  const [isAgreed, setIsAgreed] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [creditLimit, setCreditLimit] = useState<string>();
  const { pocket } = useBitsUserContext();
  const subscriptionApi = useApi().subscription;

  useEffect(() => {
    if (!pocket?.id) {
      return;
    }
    setLoading(true);
    subscriptionApi
      .getSubscriptionPlans()
      .then((response: SubscriptionPlan[]) => {
        const planWithLowestCreditLimit = getPlanWithDefaultCreditLimit(response);
        if (planWithLowestCreditLimit) {
          setCreditLimit(
            `${getCurrencySymbol(planWithLowestCreditLimit?.currency)}${planWithLowestCreditLimit?.creditAmount ?? "0"}`
          );
        }
      })
      .finally(() => setLoading(false));
  }, [pocket]);

  if (!ready || isLoading) {
    return <TripleSpinner />;
  }

  const listItems = [
    t("accountSetup.step2.list1"),
    t("accountSetup.step2.list2", { creditLimit }),
    t("accountSetup.step2.list3"),
    t("accountSetup.step2.list4", { creditAgencies: CreditAgencyList }),
    t("accountSetup.step2.list5"),
  ];

  const getAgreementClass = () => (isAgreed ? "enabled" : "disabled");

  const externalLinks = getExternalLinks();
  const handleAgreementChange = () => setIsAgreed(!isAgreed);

  return (
    <>
      <Row className="step2">
        <Col className="header">{t("accountSetup.step2.header")}</Col>
      </Row>
      <Row>
        <Col lg={1} />
        <Col className="subheader small">{t("accountSetup.step2.subheader")}</Col>
        <Col lg={1} />
      </Row>
      <Row>
        <Col lg={1} />
        <Col>
          <ul className="notes">
            {listItems.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Col>
        <Col lg={1} />
      </Row>
      <Row>
        <Col lg={1} />
        <Col className="agreement">
          <Form.Check
            className={`checkbox ${getAgreementClass()}`}
            type="checkbox"
            id="step2-agreement"
            checked={isAgreed}
            onChange={handleAgreementChange}
          />
          <Trans t={t}>
            <span className={getAgreementClass()}>{t("accountSetup.step2.agreeTo")} </span>
            <a className={getAgreementClass()} href={externalLinks.retailChargeAgreementUrl} target="_blank">
              {t("accountSetup.step2.retailChargeAgreement")},
            </a>{" "}
            <a className={getAgreementClass()} href={externalLinks.policyPrivacyUrl} target="_blank">
              {t("accountSetup.step2.privacyPolicy")}
            </a>
            {getCountry() === Country.Us && (
              <>
                <span className={getAgreementClass()}>{t("accountSetup.step2.and")}</span>{" "}
                <a className={getAgreementClass()} href={externalLinks.esignUrl} target="_blank">
                  {t("accountSetup.step2.esign")}
                </a>
              </>
            )}
          </Trans>
        </Col>
        <Col lg={1} />
      </Row>
      <Row>
        <Col lg={2} />
        <Col className="buttons">
          <Button id="step2-submit" disabled={!isAgreed} onClick={onNext}>
            {t("accountSetup.agreeButton")}
          </Button>
        </Col>
        <Col lg={2} />
      </Row>
    </>
  );
};

export default AccountSetupStep2;
