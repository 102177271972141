import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertFloatToDinero } from "../utils/money";
import { getCurrencySymbol } from "../utils/country";
import { isUserCreditLineActivated, isUserCreditLinePendingActivation } from "../components/auth/TagsApi";
import { isSubscriptionActive, WalletModel } from "../components/payment/PaymentApi";
import { useBitsUserContext } from "../context/SignedInContext";

export const useSubscriptionInfo = () => {
  const { t } = useTranslation();
  const { wallet, userTags } = useBitsUserContext();
  const [isUpcomingPaymentsShown, setIsUpcomingPaymentsShown] = useState<boolean>(false);
  const [isAccountSetupModalShown, setIsAccountSetupModalShown] = useState<boolean>(false);
  const [isCreditLineApplied, setIsCreditLineApplied] = useState<boolean>();
  const [isCreditLineActivated, setIsCreditLineActivated] = useState<boolean>();
  const [isWalletActive, setIsWalletActive] = useState<boolean>(false);
  const [isSuperchargeActive, setIsSuperchargeActive] = useState<boolean>(false);

  useEffect(() => {
    if (userTags != null && isSubscriptionActive(wallet)) {
      const creditLineActive = isUserCreditLineActivated(userTags);
      setIsAccountSetupModalShown(!creditLineActive);
      setIsCreditLineApplied(isUserCreditLinePendingActivation(userTags));
      setIsCreditLineActivated(creditLineActive);
      setIsSuperchargeActive(isSuperchargeActived(wallet));
    }
  }, [userTags, wallet]);

  useEffect(() => {
    setIsWalletActive(isSubscriptionActive(wallet));
  }, [wallet]);

  const bitsTurboBalance = convertFloatToDinero({
    amount: wallet?.bitsTurbo?.balance?.currentBalance ?? 0,
    currency: wallet?.plan?.currency ?? "",
  });

  const balance = convertFloatToDinero({
    amount: wallet?.balance?.currentBalance ?? 0,
    currency: wallet?.plan?.currency ?? "",
  });

  const cardBalanceTooltip =
    t("store.subscription.tooltips.cardBalance") +
    (!balance.isZero() ? t("store.subscription.tooltips.cardBalancePayBack") : "");

  const bitsTurboCreditAmount = convertFloatToDinero({
    amount: wallet?.bitsTurbo?.plan?.creditAmount ?? 0,
    currency: wallet?.plan?.currency ?? "",
  });
  const creditAmount = convertFloatToDinero({
    amount: wallet?.plan?.creditAmount ?? 0,
    currency: wallet?.plan?.currency ?? "",
  });

  const superchargeCreditAmount = convertFloatToDinero({
    amount: wallet?.supercharge?.plan?.creditAmount ?? 0,
    currency: wallet?.supercharge?.plan?.currency ?? "",
  });

  const utilization = !creditAmount.isZero()
    ? Math.round(((balance.getAmount() / creditAmount.getAmount()) * 100 + Number.EPSILON) * 100) / 100
    : 0;

  const currency = getCurrencySymbol(wallet?.plan?.currency);
  const isSuperchargeActived = (wallet: WalletModel | null | undefined) =>
    wallet?.supercharge !== undefined && wallet?.supercharge !== null;

  const getBitsTurboAccountBalance = () => {
    return bitsTurboBalance;
  };

  const getPrimaryAccountBalance = () => {
    if (!isSuperchargeActive) {
      return balance;
    }
    const currentBalance = wallet?.balance.currentBalance ?? 0;
    const primaryCreditAmount = wallet?.plan?.creditAmount ?? 0;
    if (currentBalance > primaryCreditAmount) {
      return convertFloatToDinero({
        amount: primaryCreditAmount,
        currency: wallet?.plan?.currency ?? "",
      });
    }
    return balance;
  };

  const getSuperchargeAccountBalance = () => {
    if (!isSuperchargeActive) {
      return convertFloatToDinero({ amount: 0, currency: wallet?.plan?.currency ?? "" });
    }
    const currentBalance = wallet?.balance.currentBalance ?? 0;
    const primaryCreditAmount = wallet?.plan?.creditAmount ?? 0;
    if (currentBalance > primaryCreditAmount) {
      return convertFloatToDinero({
        amount: currentBalance - primaryCreditAmount,
        currency: wallet?.plan?.currency ?? "",
      });
    }
    return convertFloatToDinero({ amount: 0, currency: wallet?.plan?.currency ?? "" });
  };

  const handleUpcomingPaymentsClose = () => setIsUpcomingPaymentsShown(false);
  const handleAccountSetupModalClose = () => setIsAccountSetupModalShown(false);
  const showUpcomingPayments = () => {
    setIsUpcomingPaymentsShown(true);
  };
  const showAccountSetup = () => {
    setIsAccountSetupModalShown(true);
  };

  return {
    isUpcomingPaymentsShown,
    isAccountSetupModalShown,
    isCreditLineApplied,
    isCreditLineActivated,
    isWalletActive,
    isSuperchargeActive,
    cardBalanceTooltip,
    utilization,
    currency,
    balance,
    creditAmount,
    superchargeCreditAmount,
    getPrimaryAccountBalance,
    getSuperchargeAccountBalance,
    handleUpcomingPaymentsClose,
    handleAccountSetupModalClose,
    showUpcomingPayments,
    showAccountSetup,
    bitsTurboCreditAmount,
    getBitsTurboAccountBalance,
    bitsTurboBalance,
  };
};
