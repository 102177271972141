import { useEffect, useState } from "react";
import useApi from "../useApi";
import logger, { LogTagKey } from "../../utils/logger";
import { useBitsUserContext } from "../../context/SignedInContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Extras } from "@sentry/types";
import { JSONParse } from "../../utils";
import useSessionStorage from "../useSessionStorage";
import advancedDayjs from "../../utils/date";
import { isCountryUk } from "../../utils/country";
import { isSubscriptionActive } from "../../components/payment/PaymentApi";
const PAYMENT_DATE_SUCCESS = "success";
const PAYMENT_DATE_FAILURE = "failure";

export type PaymentDate = number | "last";

interface IUsePaymentDateChange {
  isLoading: boolean;
  selectedPaymentDate?: PaymentDate;
  setSelectedPaymentDate: (day: PaymentDate) => void;
  showCalendar: boolean;
  handleInputClick: () => void;
  handleSubmit: () => Promise<void>;
  handleSkip: () => void;
  onDaySelected: (day: PaymentDate) => void;
  errorMessage: string | null;
  isSubscriptionModalShown: boolean;
  setIsSubscriptionModalShown: (flag: boolean) => void;
}

export const usePaymentDateChange = (isSignUp?: boolean): IUsePaymentDateChange => {
  const isUK = isCountryUk();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { pocket, wallet } = useBitsUserContext();
  const { getItem } = useSessionStorage();
  const { url } = JSONParse(getItem("previousPage"));
  const [selectedPaymentDate, setSelectedPaymentDate] = useState<PaymentDate>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [isSubscriptionModalShown, setIsSubscriptionModalShown] = useState<boolean>(false);
  const paymentApi = useApi().payment;
  const navigate = useNavigate();

  useEffect(() => {
    if (isSignUp) {
      const today = advancedDayjs().get("date");
      const selectedPaymentDate = today < 29 ? today : "last";
      setSelectedPaymentDate(selectedPaymentDate);
    }
  }, [isSignUp]);

  const validateSubscriptionModal = (): boolean => {
    if (!isSignUp && !isSubscriptionActive(wallet)) {
      setIsSubscriptionModalShown(true);

      return false;
    }
    return true;
  };

  const handleInputClick = () => {
    setShowCalendar(!showCalendar);
  };

  const handleError = (err: Error, errorExtras: Extras) => {
    logger.error(err, LogTagKey.TkPaymentSystem, "Change payment date failed", errorExtras);
    navigate(PAYMENT_DATE_FAILURE);
  };

  const handleSubmit = async () => {
    if (!validateSubscriptionModal()) {
      return;
    }

    if (!pocket?.id || !selectedPaymentDate) {
      handleError(new Error("Pocket is missing"), { pocketId: pocket?.id, selectedDate: selectedPaymentDate });
      return;
    }
    setIsLoading(true);
    try {
      await paymentApi.changePaymentDate(selectedPaymentDate);
      isSignUp ? navigate(url ?? isUK ? "/dashboard" : "/store") : navigate(PAYMENT_DATE_SUCCESS);
    } catch (e: any) {
      if (isSignUp && setErrorMessage) {
        setErrorMessage(t("payment.paymentDateFailure.error"));
      } else {
        handleError(e, { pocketId: pocket?.id, selectedDate: selectedPaymentDate });
      }
    }
  };

  const handleSkip = () => navigate(url ?? isUK ? "/dashboard" : "/store");

  const onDaySelected = (day: PaymentDate) => {
    setSelectedPaymentDate(day);
    handleInputClick();
  };

  return {
    isLoading,
    selectedPaymentDate,
    setSelectedPaymentDate,
    showCalendar,
    handleInputClick,
    handleSubmit,
    handleSkip,
    onDaySelected,
    errorMessage,
    isSubscriptionModalShown,
    setIsSubscriptionModalShown,
  };
};
