import { useEffect } from "react";
import { TwitterConvTrkr } from "utils/analytics/twitterPixel";
import { getCookieConsentValue } from "react-cookie-consent";

export const useTrackTwitter = () => {
  const initializeTwitterPixel = async () => {
    try {
      const twitterTrackingId = process.env.REACT_APP_TWITTER_PIXEL_TRACKING_ID ?? "";
      const allowTracking = getCookieConsentValue() === "true";
      if (!twitterTrackingId || !allowTracking) {
        return null;
      }

      const twitterConversionTrackerInstance = TwitterConvTrkr.getInstance(twitterTrackingId);
      await twitterConversionTrackerInstance.init(allowTracking);
      return twitterConversionTrackerInstance;
    } catch (error: any) {
      console.error(error);
      return null;
    }
  };

  const trackTwitterPageView = async () => {
    const twitterConversionTrackerInstance = await initializeTwitterPixel();
    const twitterTrackingPageViewEventId = process.env.REACT_APP_TWITTER_PIXEL_TRACKING_PAGEVIEW_EVENT_ID ?? "";

    if (twitterConversionTrackerInstance) {
      twitterConversionTrackerInstance.trackPageView(twitterTrackingPageViewEventId ?? undefined);
    }
  };

  const trackTwitterPurchase = async (value: string) => {
    const twitterConversionTrackerInstance = await initializeTwitterPixel();
    const twitterTrackingPurchaseEventId = process.env.REACT_APP_TWITTER_PIXEL_TRACKING_PURCHASE_EVENT_ID ?? "";

    if (twitterConversionTrackerInstance) {
      twitterConversionTrackerInstance.trackPurchase(value, twitterTrackingPurchaseEventId ?? undefined);
    }
  };

  const trackTwitterLead = async (email_address?: string) => {
    const twitterConversionTrackerInstance = await initializeTwitterPixel();
    const twitterTrackingPurchaseEventId = process.env.REACT_APP_TWITTER_PIXEL_TRACKING_LEAD_EVENT_ID ?? "";

    if (twitterConversionTrackerInstance) {
      twitterConversionTrackerInstance.trackLead(email_address, twitterTrackingPurchaseEventId);
    }
  };

  const trackTwitterAboutYourData = async () => {
    const twitterConversionTrackerInstance = await initializeTwitterPixel();
    const twitterTrackingPurchaseEventId = process.env.REACT_APP_TWITTER_PIXEL_TRACKING_ABOUTYOURDATA_EVENT_ID ?? "";

    if (twitterConversionTrackerInstance && twitterTrackingPurchaseEventId) {
      twitterConversionTrackerInstance.trackGenericEvent(twitterTrackingPurchaseEventId);
    }
  };

  useEffect(() => {
    initializeTwitterPixel();
  }, []);
  return {
    trackTwitterPageView,
    trackTwitterPurchase,
    trackTwitterLead,
    trackTwitterAboutYourData,
  };
};
