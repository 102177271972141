import { Link, Outlet } from "react-router-dom";
import "./UnauthorizedRoutesLayout/UnauthorizedRoutesLayout.scss";
import { useTranslation } from "react-i18next";
import { TripleSpinner } from "../../ui/TripleSpinner";
import { Footer } from "../common/Footer";
import Header from "../header/Header";

interface ISigninLikeRoutesLayout {
  headerClass?: string | null;
  wrapperClass?: string | null;
  displayBackButton?: boolean;
  backButtonRoute: string | undefined;
}

export const SignupLikeRoutesLayout = ({
  headerClass = "",
  wrapperClass = "",
  displayBackButton = false,
  backButtonRoute = "#",
}: ISigninLikeRoutesLayout): JSX.Element | null => {
  const { t, ready } = useTranslation();

  if (!ready) {
    return <TripleSpinner />;
  }

  const renderBackButton = (): JSX.Element | null => {
    if (!displayBackButton) {
      return null;
    }
    return (
      <Link className="bits-back-button" to={backButtonRoute}>
        <span>&#8592;</span>
        {t("common.back")}
      </Link>
    );
  };

  return (
    <div className={`unauthorized-layout ${wrapperClass}`}>
      <div className="layout-wrapper">
        <Header />
        <div className={`welcome-header ${headerClass || ""}`}>{renderBackButton()}</div>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};
