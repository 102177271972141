import { gql } from "@apollo/client";

export interface MetadataItem {
  key: string;
  value: string;
}

export interface ProductData {
  product: {
    id: string;
    name: string;
    description: string;
    descriptionJson?: string;
    isAvailable: boolean;
    slug: string;
    category?: {
      id: string;
      name: string;
      slug: string;
    };
    productType?: {
      id?: string;
      isShippingRequired?: boolean;
      isDigital?: boolean;
      name?: string;
      hasVariants?: boolean;
      variantAttributes?: ProductVariantAttribute[];
    };
    images: ProductImage[];
    variants?: ProductVariant[];
    pricing?: {
      priceRange?: {
        start?: ProductPrice;
      };
    };
    metadata?: MetadataItem[];
  };
}

export interface ProductVariantAttribute {
  name: string;
  slug: string;
  values: {
    name: string;
  }[];
}

export interface ProductPrice {
  currency?: string;
  gross?: {
    amount?: number;
  };
  net?: {
    amount?: number;
  };
}

export interface ProductAttribute {
  attribute: {
    name: string;
  };
  values: { name: string }[];
}

export interface ProductVariant {
  id: string;
  name: string;
  sku: string;
  quantityAvailable: number;
  pricing?: {
    price?: ProductPrice;
  };
  attributes?: ProductAttribute[];
  images?: { id: string }[];
}

export interface ProductImage {
  id: string;
  url: string;
  url_large: string;
  url_small: string;
  alt: string;
}

export enum VariantAttributeType {
  COLOR = "color",
  SIZE = "size",
  FORMAT = "format",
  OTHER = "other",
  VALUE = "value",
}

export interface ProductVars {
  id: string;
  thumbnailSize?: number;
  imageSize?: number;
  countryCode?: string;
}

export const PRODUCT_QUERY = gql`
  query GetProductById($id: ID!, $thumbnailSize: Int = 120, $imageSize: Int = 600, $countryCode: CountryCode = US) {
    product(id: $id) {
      id
      name
      description
      descriptionJson
      isAvailable
      slug
      category {
        id
        name
        slug
      }
      productType {
        id
        isShippingRequired
        isDigital
        name
        hasVariants
        variantAttributes {
          name
          slug
          values {
            name
          }
        }
      }
      images {
        id
        url_small: url(size: $thumbnailSize)
        url_large: url(size: $imageSize)
        url
        alt
      }
      variants {
        id
        name
        sku
        quantityAvailable(countryCode: $countryCode)
        pricing {
          price {
            currency
            net {
              amount
            }
            gross {
              amount
            }
          }
        }
        attributes {
          attribute {
            name
          }
          values {
            name
          }
        }
        images {
          id
        }
      }
      pricing {
        priceRange {
          start {
            currency
            gross {
              amount
            }
            net {
              amount
            }
          }
        }
      }
      metadata {
        key
        value
      }
    }
  }
`;

export interface IVendorProductImage {
  url: string;
  path: string;
  checksum: string;
}

export interface IVendorProductMetadata {
  costcoRawData?: string;
  costcoUrl?: string;
  rating?: number;
  reviewCount?: number;
  rawDescription?: string;
  images?: string;
  variationName?: string | null;
  features?: string[];
  details?: string[];
  specifications?: Record<string, string>;
}
