import { createContext, useContext, useEffect, useState } from "react";
import { StoreCheckout } from "../components/checkout/Checkout.types";
import { useBitsUserContext } from "./SignedInContext";
import { v4 as uuidv4 } from "uuid";
import useSessionStorage from "../hooks/useSessionStorage";
import { UserInfo } from "../components/signup/SignUpApi";
import { Pricing } from "../components/checkout/Checkout";
export const SHOPPING_CART_STICKY_STATE = uuidv4();

export interface ShoppingCartContextInterface {
  shoppingCartCheckout: StoreCheckout | undefined;
  setShoppingCartCheckout: (shoppingCartCheckout: StoreCheckout | undefined) => void;
  cartExpirationDate: number | undefined;
  setCartExpirationDate: (cartExpirationDate: number | undefined) => void;
  completedCartCheckout: StoreCheckout | undefined;
  setCompletedCartCheckout: (shoppingCartCheckout: StoreCheckout | undefined) => void;
  creditPricing: Pricing | undefined;
  debitPricing: Pricing | undefined;
  setDebitPricing: (debitPricing: Pricing | undefined) => void;
  setCreditPricing: (creditPricing: Pricing | undefined) => void;
  tranchesCount: number;
  setTranchesCount: (tranchesCount: number) => void;
}

export const INITIAL_CHECKOUT: StoreCheckout | undefined = undefined;

export const INITIAL_CONTEXT: ShoppingCartContextInterface = {
  shoppingCartCheckout: INITIAL_CHECKOUT,
  setShoppingCartCheckout: () => {},
  cartExpirationDate: undefined,
  setCartExpirationDate: () => {},
  completedCartCheckout: INITIAL_CHECKOUT,
  setCompletedCartCheckout: () => {},
  debitPricing: undefined,
  creditPricing: undefined,
  setDebitPricing: () => {},
  setCreditPricing: () => {},
  tranchesCount: 0,
  setTranchesCount: () => {},
};

export const ShoppingCartContext = createContext<ShoppingCartContextInterface>(INITIAL_CONTEXT);

export const useShoppingCartContext = () => useContext(ShoppingCartContext);
export const getShoppingCartCheckoutKey = (userInfo: UserInfo): string => {
  if (!userInfo?.membershipNumber) {
    return "";
  }
  return `bits-shopping-cart-${userInfo.membershipNumber}`;
};
const ShoppingCartContextProvider: React.FC = ({ children }) => {
  const { userInfo } = useBitsUserContext();
  const [shoppingCartCheckout, setShoppingCartCheckout] = useState<StoreCheckout | undefined>();
  const [completedCartCheckout, setCompletedCartCheckout] = useState<StoreCheckout | undefined>();
  const [cartExpirationDate, setCartExpirationDate] = useState<number | undefined>();
  const { getItem, setItem, removeItem } = useSessionStorage();
  const [debitPricing, setDebitPricing] = useState<Pricing | undefined>(undefined);
  const [creditPricing, setCreditPricing] = useState<Pricing | undefined>(undefined);
  const [tranchesCount, setTranchesCount] = useState<number>(0);

  const initializeDebitPricing = (membershipNumber: string) => {
    const debitPricingKey = `${membershipNumber}-debitPricing`;
    const debitPricing = getItem(debitPricingKey);
    if (debitPricing) {
      setDebitPricing(JSON.parse(debitPricing));
    }
  };

  const initializeCreditPricing = (membershipNumber: string) => {
    const creditPricingKey = `${membershipNumber}-creditPricing`;
    const creditPricing = getItem(creditPricingKey);
    if (creditPricing) {
      setCreditPricing(JSON.parse(creditPricing));
    }
  };
  useEffect(() => {
    setShoppingCartCheckout(undefined);
    setDebitPricing(undefined);
    setCreditPricing(undefined);
    if (userInfo?.membershipNumber) {
      const shoppingCartCheckoutKey: string = getShoppingCartCheckoutKey(userInfo);
      initializeDebitPricing(userInfo.membershipNumber);
      initializeCreditPricing(userInfo.membershipNumber);
      const shoppingCartCheckout = getItem(shoppingCartCheckoutKey) ?? undefined;
      if (shoppingCartCheckout) {
        setShoppingCartCheckout(JSON.parse(shoppingCartCheckout));
      }
    }
  }, [userInfo?.membershipNumber]);

  const handleSetShoppingCartCheckout = (shoppingCartCheckout: StoreCheckout | undefined): void => {
    setShoppingCartCheckout(shoppingCartCheckout);
    if (!shoppingCartCheckout) {
      handleSetDebitPricing(undefined);
      handleSetCreditPricing(undefined);
    }
    const shoppingCartCheckoutKey: string | undefined = userInfo ? getShoppingCartCheckoutKey(userInfo) : undefined;
    handleItemInSessionStorage(shoppingCartCheckoutKey, shoppingCartCheckout);
  };

  const handleSetDebitPricing = (debitPricing: Pricing | undefined) => {
    setDebitPricing(debitPricing);
    const debitPricingKey: string | undefined = userInfo ? `${userInfo.membershipNumber}-debitPricing` : undefined;
    handleItemInSessionStorage(debitPricingKey, debitPricing);
  };

  const handleSetCreditPricing = (creditPricing: Pricing | undefined) => {
    setCreditPricing(creditPricing);
    const creditPricingKey: string | undefined = userInfo ? `${userInfo.membershipNumber}-creditPricing` : undefined;
    handleItemInSessionStorage(creditPricingKey, creditPricing);
  };

  const handleItemInSessionStorage = (key: string | undefined, value: any) => {
    if (!key) {
      return;
    }
    try {
      if (value) {
        setItem(key, JSON.stringify(value));
      } else {
        removeItem(key);
      }
    } catch (err: unknown) {
      console.error(err);
    }
  };

  return (
    <ShoppingCartContext.Provider
      value={{
        shoppingCartCheckout,
        setShoppingCartCheckout: handleSetShoppingCartCheckout,
        cartExpirationDate,
        setCartExpirationDate,
        completedCartCheckout,
        setCompletedCartCheckout,
        creditPricing,
        debitPricing,
        setCreditPricing: handleSetCreditPricing,
        setDebitPricing: handleSetDebitPricing,
        tranchesCount,
        setTranchesCount,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
};

export default ShoppingCartContextProvider;
