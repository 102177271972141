import { getJson, postJson } from "../../utils/api";

export interface StartKycSessionResponse {
  isKycRequired: boolean;
  accessToken?: string;
}

export enum UserKycStatus {
  kycSubmitted = "kyc-submitted",
  kycApproved = "kyc-approved",
  kycError = "kyc-error",
  kycDeclined = "kyc-declined",
  kycMissingData = "kyc-missing-data",
}

export enum UserInitialKycStatus {
  initialized = "initialized",
  created = "created",
  pending = "pending",
  retryRequired = "retry-required",
  approved = "approved",
  denied = "denied",
}

export interface KycUserProps {
  initialKycStatus?: UserInitialKycStatus;
  kycStatus?: UserKycStatus;
  lastAMLCheck?: string;
  lastFullKYCCheck?: string;
  dateCreate?: string;
  dateUpdate?: string;
  error?: string;
}

export class KycApi {
  async startSession(isFullKycRequired: boolean): Promise<StartKycSessionResponse> {
    return await postJson("session", { isFullKycRequired }, process.env.REACT_APP_BASE_KYC_API_URL)
      .then((response) => response.json())
      .then((response) => response["data"]);
  }

  async getStatus(): Promise<KycUserProps> {
    return await getJson("status", process.env.REACT_APP_BASE_KYC_API_URL)
      .then((response) => response.json())
      .then((response) => response["data"]);
  }
}
