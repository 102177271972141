import { useTrackSnapchat } from "../../hooks/useTrackSnapchat";
import { useEffect } from "react";
import { useBitsUserContext } from "../../context/SignedInContext";
import { useLocation } from "react-router-dom";
import { useTrackTwitter } from "../../hooks/useTrackTwitter";

export const TrackAnalytic = () => {
  const { pathname } = useLocation();
  const { trackSnapchatPageView } = useTrackSnapchat();
  const { trackTwitterPageView } = useTrackTwitter();
  const { userInfo } = useBitsUserContext();

  useEffect(() => {
    trackSnapchatPageView(userInfo);
    trackTwitterPageView();
  }, [pathname]);
  return <></>;
};
