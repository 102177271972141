import React, { useEffect } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { SubscriptionPlan } from "./SignUpApi";
import { getCurrencySymbol } from "../../utils/country";
import { useTranslation } from "react-i18next";
import SubscriptionCheckoutForm from "./payment/SubscriptionCheckoutForm";
import { ContentfulSignupPayment, replaceContentfulSubscriptionPrice } from "../../utils/contentful";
import { useSignUpContext } from "../../context/SignUpContext";
import { TripleSpinner } from "../ui/TripleSpinner";
import { useContentfulContext } from "../../context/ContentfulContext";
import { PAYMENT_STEP_NO } from "./SignUp";

const SignUpPayment = (props: {
  subscriptionPlan: SubscriptionPlan;
  setSignUpStep: any;
  checkoutSuccessUrl?: string;
  checkoutCancelUrl?: string;
}) => {
  const { subscriptionPlan, setSignUpStep, checkoutSuccessUrl, checkoutCancelUrl } = props;
  const { userInfo } = useSignUpContext();
  const { t } = useTranslation();
  const defaultContentfulConfiguration: ContentfulSignupPayment = {
    headerText: t("payment.forms.payment.header", {
      currency: getCurrencySymbol(subscriptionPlan?.currency),
      amount: subscriptionPlan?.amount,
      interval: subscriptionPlan?.interval,
    }),
    subcontentText: t("payment.forms.payment.subheader"),
  };

  const { content, isLoading } = useContentfulContext();

  const getPlanIntervalText = (plan: SubscriptionPlan): string => {
    if (plan?.interval === "month") {
      return t("payment.forms.payment.monthly");
    }
    if (plan?.interval === "year") {
      if (plan?.renewalPeriod === 1) {
        return t("payment.forms.payment.annually");
      }
      if (plan?.renewalPeriod === 2) {
        return t("payment.forms.payment.every2years");
      }
    }
    return "";
  };

  useEffect(() => {
    setSignUpStep(PAYMENT_STEP_NO);
  }, []);

  if (isLoading) {
    return <TripleSpinner />;
  }

  return (
    <React.Fragment>
      <Row className="mt-3 signup-title">
        <Col className="col-sm-12">
          {replaceContentfulSubscriptionPrice(
            (content?.signupPayment ?? defaultContentfulConfiguration).headerText,
            subscriptionPlan?.currency,
            subscriptionPlan?.amount,
            getPlanIntervalText(subscriptionPlan)
          )}
        </Col>
      </Row>
      <Row className="text-muted font-weight-light pe-2 signup-subtitle">
        <Col className="col-sm-12">
          {replaceContentfulSubscriptionPrice(
            (content?.signupPayment ?? defaultContentfulConfiguration).subcontentText,
            subscriptionPlan?.currency,
            subscriptionPlan?.amount,
            getPlanIntervalText(subscriptionPlan)
          )}
        </Col>
      </Row>
      <Row className="mt-4 mx-2 py-2">
        {subscriptionPlan === undefined ? (
          <Col className="text-center">
            <Alert key="alertResponse" variant="danger">
              {t("payment.errors.noPlan")}
            </Alert>
          </Col>
        ) : (
          <SubscriptionCheckoutForm
            subscriptionPlan={subscriptionPlan}
            userInfo={userInfo}
            checkoutSuccessUrl={checkoutSuccessUrl}
            checkoutCancelUrl={checkoutCancelUrl}
          />
        )}
      </Row>
    </React.Fragment>
  );
};
export default SignUpPayment;
