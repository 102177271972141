import { useTranslation } from "react-i18next";
import React from "react";
import { TripleSpinner } from "../../ui/TripleSpinner";
import { Button, Col, Image, Row } from "react-bootstrap";
import Icon, { IconType } from "../../common/Icon";
import { CreditAgencyList } from "../../../utils/country";

interface IAccountSetupStep1 {
  onNext: () => void;
}

const AccountSetupStep3 = ({ onNext }: IAccountSetupStep1) => {
  const { t, ready } = useTranslation();

  if (!ready) {
    return <TripleSpinner />;
  }

  return (
    <>
      <Row className="step3">
        <Col>
          <Icon size={130} type={IconType.clockAlt} />
        </Col>
      </Row>
      <Row>
        <Col className="header step3">{t("accountSetup.step3.header")}</Col>
      </Row>
      <Row>
        <Col className="subheader">{t("accountSetup.step3.subheader1")}</Col>
      </Row>
      <Row>
        <Col className="subheader last">{t("accountSetup.step3.subheader2", { creditAgencies: CreditAgencyList })}</Col>
      </Row>
      <Row>
        <Col className="image">
          <Image src="/assets/newsletter.png" />
        </Col>
      </Row>
      <Row className="step3">
        <Col lg={2} />
        <Col className="buttons">
          <Button id="step3-submit" onClick={onNext}>
            {t("accountSetup.ok")}
          </Button>
        </Col>
        <Col lg={2} />
      </Row>
    </>
  );
};

export default AccountSetupStep3;
