import React from "react";
import "./ErrorMessage.scss";
import { Alert, Col, Row } from "react-bootstrap";

export const ErrorMessage = ({ errorMessage, className }: { errorMessage: string | null; className?: string }) => {
  if (!errorMessage) {
    return null;
  }

  return (
    <Row className={className ?? "error-message"} data-cy="error-message">
      <Col className="text-center">
        <Alert key="alertResponse" variant="danger">
          <i className="bi bi-exclamation-triangle me-2" />
          {errorMessage}
        </Alert>
      </Col>
    </Row>
  );
};
