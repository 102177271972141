import { useEffect } from "react";
import { trackSubscribe } from "../../utils/analytics";
import { TripleSpinner } from "../ui/TripleSpinner";
import { SUBSCRIPTION_PLAN_SELECTED_KEY } from "./plans/SignUpSubscription";
import useSessionStorage from "../../hooks/useSessionStorage";
import { useNavigate } from "react-router";
import { UserTags } from "../auth/TagsApi";
import useApi from "../../hooks/useApi";
import logger, { LogTagKey } from "../../utils/logger";
import { JSONParse } from "../../utils";
import { isCountryUk } from "../../utils/country";
import { useBitsUserContext } from "../../context/SignedInContext";
import { mixPanelInstance } from "../../utils/analytics/mixpanel";
import { useUTMQuery } from "../../hooks/useUTMQuery";
import { SIGNUP_CONFIRMATION_URL, SIGN_UP_CHANGE_PAYMENT_DATE_URL } from "./SignUp";
import { useTrackTwitter } from "hooks/useTrackTwitter";
import { useTrackSnapchat } from "../../hooks/useTrackSnapchat";

export const SignUpConfirmationV2 = ({ setSignUpStep }: { setSignUpStep: (stepNumber: any) => any }) => {
  const { loadPocket } = useBitsUserContext();
  const { getItem, removeItem } = useSessionStorage();
  const { userInfo, subscriptionPlanSelected } = JSONParse(getItem(SUBSCRIPTION_PLAN_SELECTED_KEY));
  const { url } = JSONParse(getItem("previousPage"));
  const { registerUTMSubscriptionPurchased } = useUTMQuery();
  const { trackTwitterPurchase } = useTrackTwitter();
  const { trackSnapchatPurchase } = useTrackSnapchat();

  const navigate = useNavigate();
  const tagsApi = useApi().tags;
  const signupApi = useApi().signUp;

  const registerMailchimpActiveSubscription = async (email: string) => {
    await signupApi.tagListMember(email, true);
  };

  useEffect(() => {
    if (subscriptionPlanSelected && userInfo) {
      trackTwitterPurchase(subscriptionPlanSelected.amount.toFixed(2));
      trackSubscribe(subscriptionPlanSelected, userInfo);
      mixPanelInstance.trackSubscriptionPurchased(subscriptionPlanSelected);
      registerUTMSubscriptionPurchased();
      registerMailchimpActiveSubscription(userInfo.email);
      trackSnapchatPurchase(subscriptionPlanSelected.amount.toFixed(2), userInfo);
    }
    setSignUpStep(SIGNUP_CONFIRMATION_URL);
    removeItem(SUBSCRIPTION_PLAN_SELECTED_KEY);
    tagsApi
      .setUserTag(UserTags.NotApplied, true)
      .then(() => {
        if (isCountryUk()) {
          loadPocket();
          return navigate(SIGN_UP_CHANGE_PAYMENT_DATE_URL);
        } else {
          navigate(url ?? "/store");
        }
      })
      .catch((err) => {
        logger.error(err, LogTagKey.TkTags, "Error adding tag to user", {
          membershipNumber: userInfo?.membershipNumber,
          tag: UserTags.NotApplied,
        });
      });
  }, []);

  return <TripleSpinner />;
};
