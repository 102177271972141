import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import "./SignUp.scss";
import SignUpContextProvider from "../../context/SignUpContext";
import { SubscriptionPlan } from "./SignUpApi";
import loadable from "@loadable/component";
import { useStickyState } from "../../hooks/useStickyState";
import { TrustPilotBox } from "../common/TrustPilotBox";
import { SignUpChangePaymentDate } from "./SignUpChangePaymentDate";

const StepProgressBar = loadable(() => import(/* webpackChunkName: "StepProgressBar" */ "../ui/StepProgressBar"));
const SignUpLegalName = loadable(() => import(/* webpackChunkName: "SignUpLegalName" */ "./SignUpLegalName"));
const SignUpAddress = loadable(() => import(/* webpackChunkName: "SignUpAddress" */ "./address/SignUpAddress"));
const LoginSignupSwitch = loadable(
  () => import(/* webpackChunkName: "LoginSignupSwitch" */ "../common/LoginSignupSwitch"),
  {
    resolveComponent: (components) => components.LoginSignupSwitch,
  }
);
const SignUpPayment = loadable(() => import(/* webpackChunkName: "SignUpPayment" */ "./SignUpPayment"));
const SignUpConfirmation = loadable(() => import(/* webpackChunkName: "SignUpConfirmation" */ "./SignUpConfirmation"));
const SignUpContactInfo = loadable(
  () => import(/* webpackChunkName: "SignUpContactInfo" */ "./contact/SignUpContactInfo")
);
const SignUpCreatePassword = loadable(
  () => import(/* webpackChunkName: "SignUpCreatePassword" */ "./password/SignUpCreatePassword")
);
const SignUpReferrals = loadable(() => import(/* webpackChunkName: "SignUpReferrals" */ "./referrals/SignUpReferrals"));
const SignUpAboutYourData = loadable(
  () => import(/* webpackChunkName: "SignUpAboutYourData" */ "./aboutyourdata/SignUpAboutYourData")
);
const SignUpSchedulePayment = loadable(
  () => import(/* webpackChunkName: "SignUpSchedulePayment" */ "./schedulepayment/SignUpSchedulePayment")
);
const SignUpSubscription = loadable(
  () => import(/* webpackChunkName: "SignUpSubscription" */ "./plans/SignUpSubscription")
);

export const SIGNUP_LEGALNAME_URL = "/signup";
export const SIGNUP_ADDRESS_URL = "/signup/address";
export const SIGNUP_CONTACTINFO_URL = "/signup/contactinfo";
export const CREATE_PASSWORD_URL = "/signup/password";
export const SUBSCRIPTION_PLANS_URL = "/signup/subscription";

export const LEGAL_NAME_STEP_NO = 0;
export const ADDRESS_STEP_NO = 1;
export const CONTACT_INFO_STEP_NO = 2;
export const CREATE_PASSWORD_STEP_NO = 3;
export const REFERRALS_STEP_NO = 4;
export const ABOUT_YOUR_DATA_STEP_NO = 5;
export const LOADING_CREDIT_FILE_STEP_NO = 999;
export const SUBSCRIPTIONS_STEP_NO = 6;
export const PAYMENT_STEP_NO = 8;
export const CHANGE_PAYMENT_DATE_STEP_NO = 9;
export const CONFIRMATION_STEP_NO = 10;
export const SCHEDULE_CONFIRMATION_STEP_NO = 11;
export const SIGN_UP_REFERRALS_URL = "/signup/referrals";
export const SIGN_UP_ABOUT_YOUR_DATA_URL = "/signup/aboutyourdata";
export const SIGN_UP_PAYMENT_URL = "/signup/payment";
export const SIGN_UP_CHANGE_PAYMENT_DATE_URL = "/signup/paymentdate";
export const SIGNUP_CONFIRMATION_URL = "/signup/confirmation";
export const SIGNUP_SCHEDULE_URL = "/signup/schedule";

interface ISignUp {
  setHeaderClass: (headerClass: string | null) => void;
  setWrapperClass: (wrapperClass: string | null) => void;
  setBypassAuthCheck: (bypassAuthCheck: boolean) => void;
  setDisplayBackButton: (displayBackButton: boolean) => void;
}

interface IUseSignUp {
  layout: string;
  subscriptionPlan: SubscriptionPlan | undefined;
  signUpStep: number;
  setSignUpStep: (signUpStep: number) => void;
  setSubscriptionPlan: (subscriptionPlan: SubscriptionPlan | undefined) => void;
}

const useSignUp = ({ setHeaderClass, setWrapperClass, setDisplayBackButton }: any): IUseSignUp => {
  const [signUpStep, setSignUpStep] = useStickyState<number>(0, "signUpStep");
  const [styleClasses, setStyleClasses] = useState<any>({
    headerClass: "",
    wrapperClass: "",
    layout: "welcome-layout",
  });
  const [subscriptionPlan, setSubscriptionPlan] = useStickyState<SubscriptionPlan | undefined>(
    undefined,
    "subscriptionPlan"
  );

  useEffect(() => {
    const styleClasses: any = getClassNames();
    setStyleClasses(styleClasses);
    const { headerClass, wrapperClass } = styleClasses;
    setHeaderClass(headerClass);
    setWrapperClass(wrapperClass);
    const displayBackButton: boolean =
      signUpStep > 0 &&
      signUpStep !== SUBSCRIPTIONS_STEP_NO &&
      signUpStep !== SCHEDULE_CONFIRMATION_STEP_NO &&
      signUpStep !== CHANGE_PAYMENT_DATE_STEP_NO;
    setDisplayBackButton(displayBackButton);
  }, [signUpStep]);

  const getClassNames = (): any => {
    switch (signUpStep) {
      case SUBSCRIPTIONS_STEP_NO:
        return { headerClass: "", wrapperClass: "unauthorized-white-layout", layout: "subscriptions-layout" };
      case LOADING_CREDIT_FILE_STEP_NO:
        return { headerClass: "d-none", wrapperClass: "", layout: "welcome-layout welcome-loading-layout" };
      default:
        return { headerClass: "", wrapperClass: "", layout: "welcome-layout" };
    }
  };

  const layout: string = styleClasses?.layout || "welcome-layout";

  return {
    layout,
    subscriptionPlan,
    signUpStep,
    setSignUpStep,
    setSubscriptionPlan,
  };
};

export const SignUp = ({ setHeaderClass, setWrapperClass, setBypassAuthCheck, setDisplayBackButton }: ISignUp) => {
  const TOTAL_SIGNUP_STEPS = 6;
  const START_SIGNUP_STEP_NO = 0;
  const { layout, signUpStep, subscriptionPlan, setSignUpStep, setSubscriptionPlan } = useSignUp({
    setHeaderClass,
    setWrapperClass,
    setDisplayBackButton,
  });

  const renderLoginSignUpSwitch = (): JSX.Element | null => {
    if (signUpStep > START_SIGNUP_STEP_NO) {
      return null;
    }
    return (
      <Row>
        <Col className="text-center">
          <LoginSignupSwitch isSignup={true} />
        </Col>
      </Row>
    );
  };

  return (
    <SignUpContextProvider>
      <div className={layout}>
        {signUpStep < SUBSCRIPTIONS_STEP_NO && (
          <StepProgressBar steps={[...new Array(TOTAL_SIGNUP_STEPS)]} signUpStep={signUpStep} />
        )}
        <Container className="pb-3">
          {renderLoginSignUpSwitch()}
          <Row className="justify-content-center pt-2">
            <Col>
              <Routes>
                <Route index element={<SignUpLegalName setSignUpStep={setSignUpStep} />} />
                <Route path="address" element={<SignUpAddress setSignUpStep={setSignUpStep} />} />
                <Route path="contactinfo" element={<SignUpContactInfo setSignUpStep={setSignUpStep} />} />
                <Route path="password" element={<SignUpCreatePassword setSignUpStep={setSignUpStep} />} />
                <Route path="referrals" element={<SignUpReferrals setSignUpStep={setSignUpStep} />} />
                <Route
                  path="schedule"
                  element={<SignUpSchedulePayment setSignUpStep={setSignUpStep} subscriptionPlan={subscriptionPlan} />}
                />
                <Route
                  path="aboutyourdata"
                  element={
                    <SignUpAboutYourData setSignUpStep={setSignUpStep} setBypassAuthCheck={setBypassAuthCheck} />
                  }
                />
                <Route
                  path="subscription"
                  element={
                    <SignUpSubscription
                      setSignUpStep={setSignUpStep}
                      setBypassAuthCheck={setBypassAuthCheck}
                      setSubscriptionPlan={setSubscriptionPlan}
                    />
                  }
                />
                <Route
                  path="payment"
                  element={<SignUpPayment subscriptionPlan={subscriptionPlan!} setSignUpStep={setSignUpStep} />}
                />
                <Route
                  path="paymentdate"
                  element={<SignUpChangePaymentDate setSignUpStep={setSignUpStep} signUpStep={signUpStep} />}
                />
                {/* TODO: subscriptionPlan must be in signup context */}
                <Route path="confirmation" element={<SignUpConfirmation setSignUpStep={setSignUpStep} />} />
              </Routes>
            </Col>
          </Row>
          <TrustPilotBox />
        </Container>
      </div>
    </SignUpContextProvider>
  );
};
