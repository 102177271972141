import React, { createRef, Fragment, useEffect, useRef, useState } from "react";
import { ProductImage } from "./Product.types";
import { Col } from "react-bootstrap";
import { ProductThumbnail } from "./ProductThumbnail";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { TripleSpinner } from "../../ui/TripleSpinner";

interface ProductImagesProps {
  images?: ProductImage[];
  selectedImageId?: string;
}

export const ProductImages = ({ images, selectedImageId }: ProductImagesProps) => {
  if (!images || images.length === 0) {
    return null;
  }

  const [selectedImage, setSelectedImage] = useState<ProductImage | undefined>(images?.[0]);
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [showImagePlaceholder, setShowImagePlaceholder] = useState<boolean>(true);
  const divRefs: any = useRef(images?.map(() => createRef()));

  useEffect(() => {
    setShowImagePlaceholder(true);
    handleLoadImage(divRefs?.current?.[selectedItem], images?.[selectedItem].url_large);
  }, [selectedItem]);

  useEffect(() => {
    if (!selectedImageId) {
      return;
    }

    const matchingImage = images.find((image: ProductImage) => image.id === selectedImageId);
    if (matchingImage) {
      const matchingImageIdx = images.indexOf(matchingImage);
      if (matchingImageIdx > -1) {
        setSelectedItem(matchingImageIdx);
        setSelectedImage(matchingImage);
      }
    }
  }, [images, selectedImageId]);

  const handleArrowNext = (): void => {
    if (selectedItem + 1 >= images?.length) {
      setSelectedImage(images?.[0]);
      return setSelectedItem(0);
    }
    setSelectedItem((selectedItem: number) => {
      const newIndex: number = selectedItem + 1;
      setSelectedImage(images?.[newIndex]);
      return newIndex;
    });
  };

  const handleArrowPrev = (): void => {
    if (selectedItem === 0) {
      const newIndex: number = images?.length - 1;
      setSelectedImage(images?.[newIndex]);
      return setSelectedItem(newIndex);
    }
    setSelectedItem((selectedItem: number) => {
      const newIndex: number = selectedItem - 1;
      setSelectedImage(images?.[newIndex]);
      return newIndex;
    });
  };

  const renderArrowNext = (): JSX.Element => {
    return (
      <div className="control-arrow control-arrow-next" onClick={handleArrowNext} id="product-image-arrow-right">
        <i className="bi bi-chevron-right bits-arrow" />
      </div>
    );
  };

  const renderArrowPrev = (): JSX.Element => {
    return (
      <div className="control-arrow" onClick={handleArrowPrev} id="product-image-arrow-left">
        <i className="bi bi-chevron-left bits-arrow" />
      </div>
    );
  };

  const handleSelectedImage = (image: ProductImage, index: number): void => {
    setSelectedImage(image);
    setSelectedItem(index);
  };

  const handleLoadImage = (divRef: any, imageLocation: string): void => {
    const bgImg = new Image();
    bgImg.onload = function () {
      if (divRef?.current?.style) {
        divRef.current.style.backgroundImage = "url(" + imageLocation + ")";
        setShowImagePlaceholder(false);
      }
    };
    bgImg.src = imageLocation;
  };

  const renderCarouselItem = (image: ProductImage, idx: number): JSX.Element | null => {
    return (
      <Fragment>
        {renderSpinner(idx)}
        <div
          ref={divRefs?.current?.[idx]}
          style={{ backgroundSize: "cover" }}
          className="product-carousel-image"
          title={image.alt}
          id={`carousel-image-${idx}`}
        />
      </Fragment>
    );
  };

  const renderSpinner = (idx: number): JSX.Element | null => {
    if (!showImagePlaceholder || selectedItem !== idx) {
      return null;
    }
    return <TripleSpinner />;
  };

  const renderCarouselItems = (): JSX.Element[] => {
    return images.map((image, idx) => {
      return <div key={`carousel-image-${idx}`}>{renderCarouselItem(image, idx)}</div>;
    });
  };

  const renderProductThumnails = (id: string): JSX.Element | null => {
    return (
      <div className="product-thumbnails" id={id}>
        {images.map((image, idx) => {
          return (
            <ProductThumbnail
              selected={image === selectedImage}
              onSelect={() => handleSelectedImage(image, idx)}
              image={image}
              key={idx}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className="d-flex product-images-wrapper">
      <Col sm={12} lg={3} className="d-none d-lg-block">
        {renderProductThumnails("product-thumbnails-lg-up")}
      </Col>
      <Col md={12} lg={9}>
        <div className="product-main-image px-3">
          <Carousel
            showStatus={false}
            showIndicators={false}
            renderArrowPrev={renderArrowPrev}
            renderArrowNext={renderArrowNext}
            emulateTouch={true}
            swipeable={true}
            selectedItem={selectedItem}
            showThumbs={false}
            onChange={setSelectedItem}
          >
            {renderCarouselItems()}
          </Carousel>
        </div>
      </Col>
      <Col sm={12} className="d-lg-none">
        {renderProductThumnails("product-thumnails-lg-down")}
      </Col>
    </div>
  );
};
