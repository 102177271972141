import { getCurrencySymbol } from "../../../utils/country";
import { roundFullAmount } from "../../../utils/money";

interface ProductPriceProps {
  currency?: string;
  amount?: number;
}

export const ProductPrice = ({ currency, amount }: ProductPriceProps) => {
  const currencySymbol = getCurrencySymbol(currency);

  return <>{currencySymbol + roundFullAmount(amount || 0)}</>;
};
