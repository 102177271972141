import { ProductImage } from "./Product.types";
import React from "react";

interface ProductThumbnailProps {
  image: ProductImage;
  selected: boolean;
  onSelect?: () => void;
}

export const ProductThumbnail = ({ image, selected, onSelect }: ProductThumbnailProps) => {
  return (
    <div
      style={{ backgroundImage: `url(${image.url_small})`, backgroundSize: "cover" }}
      title={image.alt}
      className={`product-thumbnail-container mb-1 ${selected ? "active" : ""}`}
      onClick={onSelect}
    />
  );
};
