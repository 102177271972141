import { useTranslation } from "react-i18next";
import { IVendorProductMetadata } from "./Product.types";
import * as JSON5 from "json5";
import { Col, Row } from "react-bootstrap";

interface ProductMetadataProps {
  metadata?: IVendorProductMetadata;
  className?: string;
  children?: JSX.Element;
}

function capitalizeFirstLetter(input: string): string {
  if (!input) {
    return "";
  }
  return input.charAt(0).toUpperCase() + input.slice(1);
}

function tryParseJson<T>(str: any, defaultValue: T): T {
  if (str == null) {
    return defaultValue;
  }
  try {
    return JSON5.parse(str) as T;
  } catch (e) {
    return defaultValue;
  }
}

function renderList(
  header: string,
  className: string | undefined,
  rowClass: string,
  values: string[]
): JSX.Element | null {
  if (values.length === 0) {
    return null;
  }

  return (
    <div className="product-description mt-3">
      {header}:
      <ul className={className}>
        {values.map((value, index) => (
          <li key={index} className={rowClass}>
            <strong>{value}</strong>
          </li>
        ))}
      </ul>
    </div>
  );
}

export const ProductMetadata = ({ metadata, className, children }: ProductMetadataProps) => {
  const { t, ready } = useTranslation();

  if (!ready || !metadata) {
    return null;
  }

  const features = tryParseJson<string[]>(metadata.features, []);
  const details = tryParseJson<string[]>(metadata.details, []);
  const specifications = tryParseJson<Record<string, string>>(metadata.specifications, {});

  const elements: (JSX.Element | null)[] = [];

  if (metadata?.variationName) {
    elements.push(
      <p className={className}>
        {t("store.product.variation")}: <strong>{capitalizeFirstLetter(metadata.variationName)}</strong>
      </p>
    );
  }

  elements.push(renderList(t("store.product.features"), className, "feature", features));

  if (children != null) {
    elements.push(children);
  }

  if (Object.keys(specifications).length > 0) {
    elements.push(
      <>
        {t("store.product.specification")}:
        {Object.keys(specifications)
          .sort((a: string, b: string) => a.localeCompare(b))
          .map((key) => (
            <Row key={key} className="specification">
              <Col className="specification-name">{key}</Col>
              <Col className="specification-value">
                <strong>{specifications[key]}</strong>
              </Col>
            </Row>
          ))}
      </>
    );
  }

  elements.push(renderList(t("store.product.details"), className, "details", details));

  return (
    <>
      {elements
        .filter((el) => el != null)
        .map((el, index) => (
          <div key={index}>{el}</div>
        ))}
    </>
  );
};
