import { createContext, useContext, useState } from "react";

export interface UTMSInterface {
  emailUTM: boolean;
  tiktokUTM: boolean;
  facebookUTM: boolean;
}

export interface UTMSContextInterface {
  utms: UTMSInterface;
  setUtms: (utms: UTMSInterface) => void;
  buildReferral: () => string;
}

export const INITIAL_UTMS: UTMSInterface = { emailUTM: false, tiktokUTM: false, facebookUTM: false };

export const INITIAL_CONTEXT: UTMSContextInterface = {
  utms: INITIAL_UTMS,
  setUtms: () => {},
  buildReferral: () => {
    return "";
  },
};

export const UtmsContext = createContext<UTMSContextInterface>(INITIAL_CONTEXT);

export const useUtmsContext = () => useContext(UtmsContext);

const handleBuildReferral = (utms: UTMSInterface) => {
  const { emailUTM, tiktokUTM, facebookUTM } = utms;
  if (emailUTM) {
    return "email";
  } else if (facebookUTM) {
    return "facebook";
  } else if (tiktokUTM) {
    return "tiktok";
  } else {
    return "";
  }
};
const UtmsContextProvider: React.FC = ({ children }) => {
  const [utms, setUtms] = useState<UTMSInterface>(INITIAL_UTMS);

  const buildReferral = (): string => {
    return handleBuildReferral(utms);
  };

  return (
    <UtmsContext.Provider
      value={{
        utms,
        setUtms,
        buildReferral,
      }}
    >
      {children}
    </UtmsContext.Provider>
  );
};

export default UtmsContextProvider;
