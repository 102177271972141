import { Dropdown, DropdownButton } from "react-bootstrap";

export const CartQuantity = ({
  quantity = 1,
  setQuantity,
  id,
}: {
  quantity: number | undefined;
  setQuantity: (quantity: number | undefined) => Promise<void>;
  id: string;
}) => {
  const renderDropDownItem = (quantityIndex: number): JSX.Element => {
    return (
      <Dropdown.Item
        onClick={() => setQuantity(quantityIndex)}
        key={`quantity-item-${id}-${quantityIndex}`}
        active={quantityIndex === quantity}
      >
        {quantityIndex}
      </Dropdown.Item>
    );
  };

  const renderDropDownItems = (): JSX.Element[] => {
    const length: number = process.env.REACT_APP_MAX_CART_ITEM_QUANTITY
      ? parseInt(process.env.REACT_APP_MAX_CART_ITEM_QUANTITY)
      : 15;
    return Array.from({ length }).map((_, index: number) => renderDropDownItem(index + 1));
  };
  return (
    <DropdownButton className="quantity-dropdown" title={`${quantity ?? ""}`} variant="white">
      {renderDropDownItems()}
    </DropdownButton>
  );
};
