import { Col, Modal, Button, Image, CloseButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Variant } from "../checkout/Checkout.types";
import { getLineVariantImage } from "../checkout/CheckoutUtils";
import { AutoClosableModal } from "../common/AutoClosableModal";
import { isCountryUk } from "../../utils/country";

export interface ItemAddedInterface {
  thumbnail: string;
  alt: string;
  quantity: string;
  name: string;
  amount: string;
  variant: Variant;
}

const DEFAULT_AUTOCLOSE_IN_SECONDS = 5;
const getAutoCloseInSeconds = () => {
  try {
    return parseInt(process.env.REACT_APP_MODAL_AUTOCLOSE_IN_SECONDS ?? DEFAULT_AUTOCLOSE_IN_SECONDS.toString(), 10);
  } catch (err: unknown) {
    return DEFAULT_AUTOCLOSE_IN_SECONDS;
  }
};

const autoCloseInSeconds: number = getAutoCloseInSeconds();
export const CartConfirmation = ({
  show,
  setHide,
  hideModal,
  itemAdded,
}: {
  show: boolean;
  setHide: () => void;
  hideModal: () => void;
  itemAdded: ItemAddedInterface | undefined;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isUK = isCountryUk();

  const navigateToCheckout = () => navigate("/checkout");
  const navigateToYourCart = () => navigate("/store/cart");
  if (!itemAdded) {
    return null;
  }
  return (
    <AutoClosableModal
      show={show}
      setHide={setHide}
      dialogClassName={isUK ? "cart-modal-centered" : "cart-modal"}
      secondsToDisplay={autoCloseInSeconds}
    >
      <Modal.Header className="your-cart-header">
        <CloseButton onClick={hideModal} />
      </Modal.Header>
      <Modal.Body className="cart-modal-body">
        <div className="d-flex">
          <Col sm={12} md={6}>
            <div className="thumbnail">
              <Image
                id="cart-thumbnail"
                src={getLineVariantImage(itemAdded?.variant)}
                alt={itemAdded.alt}
                className="img-fluid"
              />
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div className="product-title">{itemAdded.name}</div>
            <div className="product-amount">{itemAdded.amount}</div>
            <div className="product-quantity">
              {t("cart.quantity")}: {itemAdded.quantity}
            </div>
          </Col>
        </div>
      </Modal.Body>

      <Modal.Footer className="cart-modal-footer">
        <div className="buttons-wrapper">
          <div>
            <Button id="cartCheckoutBtn" variant="primary" onClick={navigateToCheckout}>
              {t("checkout.checkout")}
            </Button>
          </div>

          <div>
            <Button id="cartYourCartBtn" variant="white" className="your-cart" onClick={navigateToYourCart}>
              {t("cart.yourCart")}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </AutoClosableModal>
  );
};
