import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSignUpContext } from "../context/SignUpContext";
import { useUtmsContext, UTMSInterface } from "../context/UTMSContext";
import { mixPanelInstance } from "../utils/analytics/mixpanel";
import { useQuery } from "./useQuery";

const TIKTOK_UTM = "utm_tiktok";
const FACEBOOK_UTM = "utm_facebook";
const EMAIL_UTM = "utm_email";
const TIKTOK_UTM_CODE = process.env.REACT_APP_TIKTOK_UTM_CODE;
const FACEBOOK_UTM_CODE = process.env.REACT_APP_FACEBOOK_UTM_CODE;
const EMAIL_UTM_CODE = process.env.REACT_APP_EMAIL_UTM_CODE;

export const useUTMQuery = (): any => {
  const query = useQuery();
  const { pathname } = useLocation();
  const { setUtms, utms } = useUtmsContext();
  const { signUpUUID } = useSignUpContext();

  const appendUTMCode = (confirmationUrl: string) => {
    const { tiktokUTM, facebookUTM, emailUTM } = utms;
    if (tiktokUTM) {
      return `${confirmationUrl}?utm_tiktok=${process.env.REACT_APP_TIKTOK_UTM_CODE}`;
    }

    if (facebookUTM) {
      return `${confirmationUrl}?utm_facebook=${process.env.REACT_APP_FACEBOOK_UTM_CODE}`;
    }

    if (emailUTM) {
      return `${confirmationUrl}?utm_email=${process.env.REACT_APP_EMAIL_UTM_CODE}`;
    }
    return confirmationUrl;
  };

  const sendTiktokMixpanelEvent = (tiktokUTM: boolean) => {
    if (tiktokUTM) {
      mixPanelInstance.trackTiktokSubscriptionPurchase(signUpUUID);
    }
  };

  const sendFacebookMixpanelEvent = (facebookUTM: boolean) => {
    if (facebookUTM) {
      mixPanelInstance.trackFacebookSubscriptionPurchase(signUpUUID);
    }
  };

  const sendEmailMixpanelEvent = (emailUTM: boolean) => {
    if (emailUTM) {
      mixPanelInstance.trackEmailSubscriptionPurchase(signUpUUID);
    }
  };

  const registerUTMSubscriptionPurchased = () => {
    const { emailUTM, tiktokUTM, facebookUTM } = getUtms() ?? {};
    sendTiktokMixpanelEvent(tiktokUTM);
    sendFacebookMixpanelEvent(facebookUTM);
    sendEmailMixpanelEvent(emailUTM);
  };

  const getUTM = (queryString: string, value: string | undefined): boolean => {
    return query.get(queryString) === value;
  };

  const getUtms = (): UTMSInterface => {
    return {
      tiktokUTM: getUTM(TIKTOK_UTM, TIKTOK_UTM_CODE),
      facebookUTM: getUTM(FACEBOOK_UTM, FACEBOOK_UTM_CODE),
      emailUTM: getUTM(EMAIL_UTM, EMAIL_UTM_CODE),
    };
  };

  const buildUTMCode = (utms: UTMSInterface): string => {
    const { tiktokUTM, emailUTM, facebookUTM } = utms;
    if (tiktokUTM) {
      return "?utm_source=tiktok";
    }
    if (emailUTM) {
      return "?utm_source=email";
    }
    if (facebookUTM) {
      return "?utm_source=facebook";
    }
    return "";
  };

  useEffect(() => {
    const strippedPath = pathname.toLowerCase().replace(/\//g, "");
    if (strippedPath === "signup") {
      const utms: UTMSInterface = getUtms();
      setUtms(utms);
    }
  }, [pathname]);

  return { registerUTMSubscriptionPurchased, appendUTMCode, buildUTMCode };
};
