import React from "react";
import { Row } from "react-bootstrap";
import { getExternalLinks } from "../../utils/country";

export const TrustPilotBox = () => {
  const ref1 = React.useRef(null);
  const ref2 = React.useRef(null);
  React.useEffect(() => {
    if ((window as any).Trustpilot) {
      (window as any).Trustpilot.loadFromElement(ref1.current, true);
      (window as any).Trustpilot.loadFromElement(ref2.current, true);
    }
  }, []);

  const externalLinks = getExternalLinks();

  if (!externalLinks?.trustpilotId || !externalLinks?.trustpilotDomain) {
    return null;
  }

  return (
    <Row className="mt-8 trust-pilot">
      <div
        className="trustpilot-widget"
        data-locale="en-GB"
        data-template-id="53aa8807dec7e10d38f59f32"
        data-businessunit-id={externalLinks.trustpilotId}
        data-style-height="150px"
        data-style-width="100%"
        data-theme="light"
        data-cy="trustpilot-rating"
        ref={ref1}
      >
        <a href={externalLinks.trustpilotDomain} target="_blank" rel="noreferrer">
          Trustpilot
        </a>
      </div>
      <div
        className="trustpilot-widget"
        data-locale="en-GB"
        data-template-id="54d0e1d8764ea9078c79e6ee"
        data-businessunit-id={externalLinks.trustpilotId}
        data-style-height="300px"
        data-style-width="100%"
        data-theme="light"
        data-stars="4,5"
        data-review-languages="en"
        data-cy="trustpilot-reviews"
        ref={ref2}
      >
        <a href={externalLinks.trustpilotDomain} target="_blank" rel="noreferrer">
          Trustpilot
        </a>
      </div>
    </Row>
  );
};
