import { getJson, postJson } from "../../utils/api";

export enum PlanInterval {
  day = "day",
  week = "week",
  month = "month",
  year = "year",
}
export enum AnnualRenewalPeriod {
  annually = 1,
  biannually = 2,
}
export enum MonthlyRenewalPeriod {
  monthly = 1,
  q2month = 2,
  q3month = 3,
}

export interface SubscriptionPlan {
  subscriptionPlanId: number;
  stripePlanId: string;
  name: string;
  title: string;
  subtitle: string;
  availability?: string;
  amount: number;
  currency: string;
  renewalPeriod: MonthlyRenewalPeriod | AnnualRenewalPeriod;
  interval: PlanInterval;
  creditAmount: number;
  order: number;
  isDefault: boolean;
}

export interface Pocket {
  id: number;
  name: string;
  url: string;
  balance: number;
  status: string;
  type: string;
  accountNumber: string;
  sortCode: string;
}

export interface CreditHistoryItem {
  amount: number;
  createdAt: string;
  creditAmount: number;
  prevAmount: number;
  prevCreditAmount: number;
  reportedAt: string;
}

export interface CreditHistoryResponse {
  items: CreditHistoryItem[];
  limit: number;
  offset: number;
  total: number;
}

export class SubscriptionApi {
  async getSubscriptionPlans(): Promise<SubscriptionPlan[]> {
    return await getJson("plans", process.env.REACT_APP_BASE_PAYMENT_API_URL)
      .then((response) => response.json())
      .then((response) => response["data"])
      .then((response) => response["plans"]);
  }

  async updateSubscriptionPlans(planId: number): Promise<Pocket> {
    return postJson(`wallet/subscriptions/plan`, { planId }, process.env.REACT_APP_BASE_PAYMENT_API_URL).then(
      (response) => response.json()
    );
  }

  async createPocket(): Promise<Pocket> {
    return (await postJson(`pockets?wait=true`, { type: "CreditReport" })).json();
  }

  async getPockets(): Promise<Pocket[]> {
    return await getJson(`pockets?limit=100`)
      .then((response) => response.json())
      .then((response) => response["items"]);
  }

  async getCreditHistory(pocketId: number, limit: number, offset: number): Promise<CreditHistoryResponse> {
    return await getJson(`pockets/${pocketId}/credit_history?limit=${limit}&offset=${offset}`).then((response) =>
      response.json()
    );
  }

  async getOrCreatePocket(): Promise<Pocket> {
    const pockets = await this.getPockets();
    if (pockets.length === 0) {
      return await this.createPocket();
    }
    return pockets[0];
  }
}
