import { Trans, useTranslation } from "react-i18next";
import React from "react";
import { TripleSpinner } from "../../ui/TripleSpinner";
import { Button, Col, Row } from "react-bootstrap";
import Icon, { IconType } from "../../common/Icon";

interface IAccountSetupStep1 {
  onNext: () => void;
}

const AccountSetupStep1 = ({ onNext }: IAccountSetupStep1) => {
  const { t, ready } = useTranslation();

  if (!ready) {
    return <TripleSpinner />;
  }

  return (
    <>
      <Row className="step1">
        <Col>
          <Icon size={130} type={IconType.clock} />
        </Col>
      </Row>
      <Row>
        <Col className="header">{t("accountSetup.step1.header")}</Col>
      </Row>
      <Row>
        <Col className="subheader">
          <Trans i18nKey="accountSetup.step1.subheader1" />
        </Col>
      </Row>
      <Row>
        <Col className="subheader last mt-4">{t("accountSetup.step1.subheader2")}</Col>
      </Row>
      <Row>
        <Col lg={2} />
        <Col className="buttons">
          <Button id="step1-submit" onClick={onNext}>
            {t("accountSetup.next")}
          </Button>
        </Col>
        <Col lg={2} />
      </Row>
    </>
  );
};

export default AccountSetupStep1;
