import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { isCountryUk, isCountryUs } from "../../utils/country";
import AuthService from "../auth/AuthService";

export enum DeploymentEnv {
  Dev = "dev",
  Staging = "staging",
  Production = "production",
}

// This component is used for inserting all common integration scripts/tags into <head>
export const CustomHead = () => {
  const isUK = isCountryUk();
  const deploymentEnv = process.env.REACT_APP_DEPLOYMENT_ENV || "";
  const gaTrackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || "";
  const snapTrackingId = process.env.REACT_APP_SNAPCHAT_PIXEL_ID || "";

  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (!AuthService.isAuthenticated()) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  });

  let googleCode;
  if (gaTrackingId) {
    googleCode = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gaTrackingId}');`;
  }

  let snapchatCode;
  if (snapTrackingId) {
    snapchatCode = `(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function(){a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};a.queue=[];var s='script';r=t.createElement(s);r.async=!0;r.src=n;var u=t.getElementsByTagName(s)[0];u.parentNode.insertBefore(r,u);})(window,document,'https://sc-static.net/scevent.min.js');`;
  }

  return (
    <Helmet>
      {isUK ? <body className={(loggedIn ? "loggedIn" : "loggedOut") + " " + "cassette"} /> : <body />}
      {/* Prevent un-prod sites from showing in google search result */}
      {deploymentEnv !== DeploymentEnv.Production && <meta name="robots" content="noindex, nofollow" />}
      {/*Global site tag (gtag.js) - Google Analytics Helmet doesn't allow wrap elements inside fragment*/}
      {googleCode && <script>{googleCode}</script>}
      {snapchatCode && <script type="text/javascript">{snapchatCode}</script>}
      {isCountryUk() && (
        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async />
      )}
      {isCountryUk() && <html lang="en-GB" />}
      {isCountryUs() && <html lang="en-US" />}
    </Helmet>
  );
};
