import { useLocation, useParams } from "react-router-dom";
import { TFunction, useTranslation } from "react-i18next";
import { ContentWrapper } from "../../common/ContentWrapper";
import { Button, Col, Row } from "react-bootstrap";
import Loading from "../../common/Loading";
import { ErrorMessage } from "../../error/ErrorMessage";
import { useEffect, useState } from "react";
import { ProductDescription } from "./ProductDescription";
import { ApolloError, useQuery } from "@apollo/client";
import logger, { LogTagKey } from "../../../utils/logger";
import {
  IVendorProductImage,
  IVendorProductMetadata,
  MetadataItem,
  PRODUCT_QUERY,
  ProductAttribute,
  ProductData,
  ProductImage,
  ProductVariant,
  ProductVariantAttribute,
  ProductVars,
  VariantAttributeType,
} from "./Product.types";
import { ProductPrice } from "./ProductPrice";
import { ProductImages } from "./ProductImages";
import { useBitsUserContext } from "../../../context/SignedInContext";
import { useNavigate } from "react-router";
import "./ProductDetails.scss";
import { CheckoutLine, Money, StoreCheckout } from "../../checkout/Checkout.types";
import { TripleSpinner } from "../../ui/TripleSpinner";
import { TooltipOnHover } from "../../ui/TooltipOnHover";
import { StoreBreadcrumbItem, StoreBreadcrumbs } from "../../ui/StoreBreadcrumbs";
import * as JSON5 from "json5";
import { useCheckout } from "../../../hooks/useCheckout";

import { getCountrySaleorCode, getCurrencySymbolFromCountry } from "../../../utils/country";
import { CartQuantity } from "../../cart/CartQuantity";
import { CartConfirmation, ItemAddedInterface } from "../../cart/CartConfirmation";
import AuthService from "../../auth/AuthService";
import { useStickyState } from "../../../hooks/useStickyState";

interface IUseProductDetails {
  error: string;
  loading: boolean;
  data: ProductData | undefined;
  variants: ProductVariantType[];
  selectedVariant: string;
  selectedAttributeVariants?: Record<VariantAttributeType, string>;
  handleVariantClick: (attribute: VariantAttributeType, value: string) => void;
  selectedPrice?: Money;
  getMatchingVariant: (attribute: VariantAttributeType, value: string) => ProductVariant | undefined;
  selectedImageVariantId?: string;
  hotlinkedImages: ProductImage[];
  productMetadata?: IVendorProductMetadata;
  handleShowCartConfirmation: () => void;
  handleHideCartConfirmation: () => void;
  showCartConfirmation: boolean;
  selectedQuantity: number | undefined;
  setSelectedQuantity: (selectedQuantity: number | undefined) => void;
  addToCart: (navigateToCheckout?: boolean) => Promise<void>;
  goToCheckout: () => void;
  shoppingCartCheckout: StoreCheckout | undefined;
}

interface IUseProductDetailsParams {
  id: string;
  thumbnailSize: number;
  imageSize: number;
  t: TFunction;
}

interface ProductVariantType {
  type: VariantAttributeType;
  label: string;
  values: string[];
}

interface SelectedProductVariant {
  variant: string;
  qty: number;
  navigateToCheckout: boolean;
}

const extractVendorProductMetadata = (metadata?: MetadataItem[]): IVendorProductMetadata => {
  if (!Array.isArray(metadata) || metadata.length === 0) {
    return {};
  }

  return metadata.reduce((acc: IVendorProductMetadata, row: MetadataItem) => {
    // @ts-ignore
    acc[row.key] = row.value;
    return acc;
  }, {} as IVendorProductMetadata);
};

export const useGetVariantAttributeType =
  () =>
  (name: string): VariantAttributeType => {
    if (name.match(/color|colour/i)) {
      return VariantAttributeType.COLOR;
    } else if (name.match(/size/i)) {
      return VariantAttributeType.SIZE;
    } else if (name.match(/format/i)) {
      return VariantAttributeType.FORMAT;
    } else if (name.match(/value/i)) {
      return VariantAttributeType.VALUE;
    } else {
      logger.warn(
        `Unrecognized Saleor variant attribute: ${name}`,
        LogTagKey.TkStoreProduct,
        "Unrecognized Saleor variant attribute"
      );
      return VariantAttributeType.OTHER;
    }
  };

const useProductDetails = ({ id, thumbnailSize, imageSize, t }: IUseProductDetailsParams): IUseProductDetails => {
  const [currentId, setCurrentId] = useState(id);
  const [selectedQuantity, setSelectedQuantity] = useState<number | undefined>(1);
  const [error, setError] = useState("");
  const { setPreviousPage } = useBitsUserContext();
  const location = useLocation();
  const [variants, setVariants] = useState<ProductVariantType[]>([]);
  const [selectedVariant, setSelectedVariant] = useState<string>("");
  const [selectedAttributeVariants, setSelectedAttributeVariants] = useState<
    Record<VariantAttributeType, string> | undefined
  >();
  const [selectedPrice, setSelectedPrice] = useState<Money>();
  const [selectedImageVariantId, setSelectedImageVariantId] = useState<string>();
  const [showCartConfirmation, setShowCartConfirmation] = useState<boolean>(false);
  const [isCartLoading, setIsCartLoading] = useState<boolean>(false);
  const getVariantAttributeType = useGetVariantAttributeType();

  // Costco-specific hacks
  const [hotlinkedImages, setHotlinkedImages] = useState<ProductImage[]>([]);
  const [productMetadata, setProductMetadata] = useState<IVendorProductMetadata>({});

  const [selectedProductVariant, setSelectedProductVariant] = useStickyState<SelectedProductVariant | undefined>(
    undefined,
    "selectedVariant"
  );

  const [addedProductVariant, setAddedProductVariant] = useState<SelectedProductVariant | undefined>(undefined);

  const flushState = (newId: string) => {
    setSelectedQuantity(1);
    setError("");
    setVariants([]);
    setSelectedVariant("");
    setSelectedAttributeVariants(undefined);
    setSelectedImageVariantId(undefined);
    setShowCartConfirmation(false);
    setHotlinkedImages([]);
    setProductMetadata({});
    setSelectedProductVariant(undefined);
    setAddedProductVariant(undefined);
    setCurrentId(newId);
  };

  const handleHideCartConfirmation = () => setShowCartConfirmation(false);
  const handleShowCartConfirmation = () => setShowCartConfirmation(true);
  const { addItemToCart, shoppingCartCheckout } = useCheckout();
  const navigate = useNavigate();
  const handleApolloError = (err: ApolloError) => {
    logger.error(err, LogTagKey.TkStoreProducts, "Error fetching product details from Saleor", { productId: id });
    setError(t("store.error.product"));
  };

  const hasVariantsWithAttribute = (name: string, value: string, data: ProductData): boolean => {
    return !!data?.product?.variants?.some(({ attributes }: ProductVariant) => {
      return !!attributes?.some(({ attribute, values }: ProductAttribute) => {
        return attribute.name === name && values?.some(({ name: attributeValue }) => attributeValue === value);
      });
    });
  };

  const restorePreviousAction = () => {
    if (selectedProductVariant != null) {
      setAddedProductVariant(selectedProductVariant);
      setSelectedProductVariant(undefined);
    }
  };

  const handleVariantlessProduct = (variants: ProductVariant[] | undefined) => {
    const variant = variants?.[0];

    if (!variant) {
      logger.warn(
        "Could not find a matching variant",
        LogTagKey.TkStoreProduct,
        `Variantless product has no default variant`,
        {
          productId: id,
        }
      );
      setSelectedVariant("");
      setError(t("store.error.unknownVariant"));
    } else if (variant.quantityAvailable === 0) {
      setError(t("store.error.outOfStock"));
      setSelectedAttributeVariants(undefined);
      setSelectedVariant("");
    } else {
      setError("");
      setSelectedVariant(variant.id);
      setSelectedPrice({
        currency: variant?.pricing?.price?.currency ?? "",
        amount: variant?.pricing?.price?.gross?.amount ?? 0,
      });
    }
    restorePreviousAction();
  };

  const extractVariantsAndMetadata = (data: ProductData) => {
    if (!data?.product) {
      return;
    }
    const {
      product: { productType, metadata, variants },
    } = data;

    const extractedMetadata: IVendorProductMetadata = extractVendorProductMetadata(metadata);
    setProductMetadata(extractedMetadata);

    if (extractedMetadata?.images) {
      try {
        const images: IVendorProductImage[] = JSON5.parse(extractedMetadata.images);
        setHotlinkedImages(
          images.map((img: IVendorProductImage) => ({
            id: img?.checksum,
            url: img?.url,
            url_large: img?.url,
            url_small: img?.url,
            alt: "",
          }))
        );
      } catch (e) {
        console.log("Failed to parse images metadata");
      }
    }

    setSelectedPrice({
      currency: data?.product?.pricing?.priceRange?.start?.currency ?? "",
      amount: data?.product?.pricing?.priceRange?.start?.gross?.amount ?? 0,
    });

    if (!data?.product?.variants?.length) {
      setError(t("store.error.outOfStock"));
      logger.error(new Error("Product has no variants"), LogTagKey.TkStoreProduct, "Product has no variants", {
        productId: id,
        hasVariants: productType?.hasVariants,
        variantAttributesLength: productType?.variantAttributes?.length,
      });
    }

    if (!productType?.hasVariants || !productType?.variantAttributes?.length) {
      handleVariantlessProduct(variants);
      return;
    }

    const availableVariants: ProductVariantType[] = productType.variantAttributes.map(
      (attribute: ProductVariantAttribute) => {
        const type = getVariantAttributeType(attribute.name);

        const variant: ProductVariantType = {
          type,
          label: attribute.name,
          values: attribute.values.map((v) => v.name).filter((v) => hasVariantsWithAttribute(attribute.name, v, data)),
        };

        return variant;
      }
    );

    const hasAnyVariants = availableVariants.some((variant: ProductVariantType) => variant.values.length > 0);
    if (!hasAnyVariants) {
      setError(t("store.error.outOfStock"));
      logger.error(
        new Error("Product has no variants matching attributes"),
        LogTagKey.TkStoreProduct,
        "Product has no variants matching attributes",
        { productId: id }
      );
      return;
    }

    setVariants(availableVariants);
    restorePreviousAction();
  };

  const { data, loading } = useQuery<ProductData, ProductVars>(PRODUCT_QUERY, {
    variables: {
      id: currentId,
      thumbnailSize,
      imageSize,
      countryCode: getCountrySaleorCode(),
    },
    onError: handleApolloError,
    onCompleted: (data: ProductData) => extractVariantsAndMetadata(data),
    fetchPolicy: "network-only",
  });

  // Flush state when switching between products, e.g. click on search
  useEffect(() => {
    if (currentId !== id) {
      flushState(id);
    }
  }, [id]);

  const findMatchingVariant = (
    newSelectedAttributeVariants: Record<VariantAttributeType, string>
  ): ProductVariant | undefined => {
    return data?.product?.variants?.find((row) => {
      return Object.keys(newSelectedAttributeVariants).every((variantKey) => {
        const variant = variants.find((v) => v.type === variantKey);
        if (!variant) {
          return false;
        }

        const attributeValue = row?.attributes?.find((att) => att.attribute.name === variant.label)?.values?.[0]?.name;

        return newSelectedAttributeVariants[variantKey as VariantAttributeType] === attributeValue;
      });
    });
  };

  const updateVariant = (newSelectedAttributeVariants: Record<VariantAttributeType, string>) => {
    const variant = findMatchingVariant(newSelectedAttributeVariants);

    if (!variant) {
      logger.warn("Could not find a matching variant", LogTagKey.TkStoreProduct, `No matching attribute found`, {
        productId: data?.product?.id,
      });
      setSelectedVariant("");
      setError(t("store.error.unknownVariant"));
    } else if (variant.quantityAvailable === 0) {
      setError(t("store.error.outOfStock"));
      setSelectedAttributeVariants(undefined);
      setSelectedVariant("");
    } else {
      setError("");
      setSelectedVariant(variant.id);
      setSelectedPrice({
        currency: variant?.pricing?.price?.currency ?? "",
        amount: variant?.pricing?.price?.gross?.amount ?? 0,
      });
    }
  };

  const findAndSelectVariantPhoto = (newSelectedAttributeVariants: Record<VariantAttributeType, string>) => {
    const partialMatchVariant = findMatchingVariant(newSelectedAttributeVariants);

    if (partialMatchVariant?.images?.length) {
      setSelectedImageVariantId(partialMatchVariant.images[0].id);
    }
  };

  const handleVariantClick = (attribute: VariantAttributeType, value: string) => {
    const newSelectedAttributeVariants = { ...selectedAttributeVariants, [attribute]: value };
    setSelectedAttributeVariants(newSelectedAttributeVariants as Record<VariantAttributeType, string>);

    findAndSelectVariantPhoto(newSelectedAttributeVariants as Record<VariantAttributeType, string>);

    if (Object.keys(newSelectedAttributeVariants).length === variants.length) {
      updateVariant(newSelectedAttributeVariants as Record<VariantAttributeType, string>);
    }
  };

  const getMatchingVariant = (attribute: VariantAttributeType, value: string): ProductVariant | undefined => {
    const matchingAttributeVariants = { ...selectedAttributeVariants, [attribute]: value };
    if (Object.keys(matchingAttributeVariants).length < variants.length) {
      return undefined;
    }

    return findMatchingVariant(matchingAttributeVariants as Record<VariantAttributeType, string>);
  };

  const selectVariant = (variant: ProductVariant) => {
    const newSelectedAttributeVariants: any = {};
    variant.attributes?.forEach((attribute) => {
      newSelectedAttributeVariants[getVariantAttributeType(attribute?.attribute?.name)] = attribute.values?.[0]?.name;
    });
    setSelectedAttributeVariants(newSelectedAttributeVariants as Record<VariantAttributeType, string>);

    updateVariant(newSelectedAttributeVariants as Record<VariantAttributeType, string>);
  };

  useEffect(() => setPreviousPage({ title: data?.product?.name ?? "", url: location.pathname }), [data]);

  useEffect(() => {
    if (addedProductVariant != null) {
      setSelectedQuantity(addedProductVariant.qty);
      setSelectedVariant(addedProductVariant.variant);
      setTimeout(() =>
        addToCart(addedProductVariant.navigateToCheckout, addedProductVariant.qty, addedProductVariant.variant)
      );
    }
  }, [addedProductVariant]);

  useEffect(() => {
    if (variants.length === 0 || !data?.product?.variants) {
      return;
    }

    const variantsInStock = data?.product?.variants?.filter((variant) => variant.quantityAvailable > 0) ?? [];
    if (variantsInStock.length === 1) {
      selectVariant(variantsInStock[0]);
    }
  }, [variants]);

  const addToCart = async (navigateToCheckout = false, qty?: number, variant?: string) => {
    try {
      if (!AuthService.isAuthenticated()) {
        setSelectedProductVariant({ variant: selectedVariant, qty: selectedQuantity ?? 1, navigateToCheckout });
        setTimeout(() => navigate("/login", { state: { from: location } }), 0);
        return;
      }
      setIsCartLoading(true);
      await addItemToCart(variant ?? selectedVariant, qty ?? selectedQuantity ?? 1);
      setIsCartLoading(false);
      if (navigateToCheckout) {
        navigate("/checkout");
        return;
      }
      setTimeout(() => handleShowCartConfirmation(), 10);
    } catch (err: unknown) {
      setIsCartLoading(false);
      console.error(err);
    }
  };

  const goToCheckout = async () => {
    await addToCart(true);
  };

  return {
    data,
    loading: loading || isCartLoading,
    error,
    variants,
    handleVariantClick,
    selectedVariant,
    selectedAttributeVariants,
    selectedPrice,
    getMatchingVariant,
    selectedImageVariantId,
    hotlinkedImages,
    productMetadata,
    handleShowCartConfirmation,
    handleHideCartConfirmation,
    showCartConfirmation,
    selectedQuantity,
    setSelectedQuantity,
    addToCart,
    goToCheckout,
    shoppingCartCheckout,
  };
};

export const ProductDetails = () => {
  const { id } = useParams();

  const { t, ready } = useTranslation();

  const {
    data,
    error,
    loading,
    variants,
    handleVariantClick,
    selectedVariant,
    selectedAttributeVariants,
    selectedPrice,
    getMatchingVariant,
    selectedImageVariantId,
    hotlinkedImages,
    handleHideCartConfirmation,
    showCartConfirmation,
    selectedQuantity,
    setSelectedQuantity,
    addToCart,
    goToCheckout,
    shoppingCartCheckout,
    productMetadata,
  } = useProductDetails({ id: String(id), t, thumbnailSize: 120, imageSize: 600 });
  const [itemAdded, setItemAdded] = useState<ItemAddedInterface | undefined>(undefined);

  useEffect(() => {
    const { lines } = shoppingCartCheckout ?? { lines: [] };
    const item: CheckoutLine | undefined = lines?.find((item: CheckoutLine) => item.variant.id === selectedVariant);
    if (!item) {
      return;
    }
    const variant = item.variant;
    setItemAdded({
      thumbnail: variant?.product?.thumbnail?.url,
      alt: variant?.product?.thumbnail?.alt,
      quantity: selectedQuantity ? selectedQuantity.toString() : item.quantity.toString(),
      name: variant?.product?.name,
      amount: `${getCurrencySymbolFromCountry()}${variant?.pricing?.price?.gross?.amount}`,
      variant,
    });
  }, [shoppingCartCheckout?.lines]);

  const updateSelectedQuantity = async (quantity: number | undefined): Promise<void> => {
    setSelectedQuantity(quantity);
  };

  const renderImages = () => {
    if (hotlinkedImages.length) {
      return <ProductImages images={hotlinkedImages} selectedImageId={undefined} />;
    } else {
      return <ProductImages images={data?.product?.images} selectedImageId={selectedImageVariantId} />;
    }
  };
  const hasProductData = !!data?.product;

  const renderProductDetails = (): JSX.Element | null => {
    if (!loading && data && !hasProductData) {
      logger.error(
        "Product details error",
        LogTagKey.TkStoreProducts,
        "Product data not returned from Saleor (missing variants)",
        { productId: id }
      );
      return <ErrorMessage errorMessage={t("store.error.product")} />;
    }

    if (!ready) {
      return <TripleSpinner />;
    }

    if (loading || !hasProductData) {
      return null;
    }

    return (
      <>
        <Row className="mt-4">
          <Col sm={12} md={6} className="product-images pr-0 pr-md-5">
            {renderImages()}
          </Col>
          <Col sm={12} md={6} className="product-details">
            <h4 className="product-name">{data?.product.name}</h4>
            <h1 className="product-price">
              <ProductPrice currency={selectedPrice?.currency} amount={selectedPrice?.amount} />
            </h1>
            {renderShippingCost()}
            {renderProductVariants()}
            <h5 className="mt-3">{t("cart.quantity")}</h5>
            <CartQuantity
              id="product-details-quantity"
              quantity={selectedQuantity}
              setQuantity={updateSelectedQuantity}
            />
            <Row>
              <Col lg={12}>
                <Button
                  variant="primary"
                  size="lg"
                  className="mt-1 mt-md-4 w-100"
                  disabled={!selectedVariant || !selectedQuantity}
                  data-cy="btn-add-to-cart"
                  id="btn-add-to-cart"
                  onClick={() => addToCart()}
                >
                  {t(
                    selectedVariant?.length > 0 && selectedQuantity
                      ? "store.product.addToCart"
                      : "store.product.selectVariant"
                  )}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Button
                  variant="primary"
                  size="lg"
                  className="mt-1 mt-md-4 w-100 go-to-checkout"
                  disabled={!selectedVariant || !selectedQuantity}
                  onClick={goToCheckout}
                  data-cy="btn-go-to-checkout"
                >
                  {t("store.product.goToCheckout")}
                </Button>
              </Col>
            </Row>
            <ProductDescription
              description={data?.product.description}
              descriptionJson={data?.product.descriptionJson}
              metadata={productMetadata}
              className="product-description mt-3"
            />
          </Col>
        </Row>
      </>
    );
  };

  const renderProductVariants = (): JSX.Element | null => {
    if (loading || !variants.length) {
      return null;
    }

    return (
      <>
        {variants.map((variant: ProductVariantType) => {
          return (
            <div className="product-variants mt-3" key={id + variant.type}>
              <h5>{t(`store.product.variants.${variant.type}`)}</h5>
              {variant.values.map((value) => {
                const isSelected = selectedAttributeVariants?.[variant.type] === value;

                const matchingVariant = getMatchingVariant(variant.type, value);

                const isOutOfStock = matchingVariant?.quantityAvailable === 0;

                return (
                  <TooltipOnHover title={isOutOfStock ? t("store.product.outOfStock") : undefined} key={value}>
                    <Button
                      variant={isSelected ? "primary" : "outline-primary"}
                      className="rectangle"
                      onClick={() => handleVariantClick(variant.type, value)}
                      disabled={isOutOfStock}
                      data-cy="variant-btn"
                    >
                      {value}
                    </Button>
                  </TooltipOnHover>
                );
              })}
            </div>
          );
        })}
      </>
    );
  };

  const renderShippingCost = () => {
    if (loading || data?.product?.productType?.isShippingRequired) {
      return null;
    }

    return <h5 className="product-shipping mb-2">{t("store.product.freeShipping")}</h5>;
  };

  const renderBreadcrumbs = (): JSX.Element | null => {
    if (loading) {
      return null;
    }

    const breadcrumbItems: StoreBreadcrumbItem[] = [
      {
        label: t("store.store"),
        link: "/store",
      },
    ];

    if (data?.product?.category?.slug) {
      breadcrumbItems.push({
        label: data.product.category.name,
        link: "/store/category/" + data.product.category.slug,
      });
    }

    if (data?.product?.name) {
      breadcrumbItems.push({
        label: data?.product?.name,
      });
    }

    return <StoreBreadcrumbs items={breadcrumbItems} className="d-none d-md-block breadcrumbs-desktop" />;
  };

  const renderMobileBreadcrumbs = (): JSX.Element | null => {
    if (loading || !data?.product?.category?.slug) {
      return null;
    }

    const breadcrumbItems: StoreBreadcrumbItem[] = [
      {
        label: data.product.category.name,
        link: "/store/category/" + data.product.category.slug,
        chevronLeft: true,
      },
    ];

    return <StoreBreadcrumbs items={breadcrumbItems} className="d-block d-md-none breadcrumbs-mobile" />;
  };

  return (
    <ContentWrapper shadow className="store-product-details-wrapper">
      {renderBreadcrumbs()}
      {renderMobileBreadcrumbs()}
      <div className="bits-loading-wrapper">
        <Loading loading={loading} message={null} />
      </div>
      <ErrorMessage errorMessage={error} />
      {renderProductDetails()}
      <CartConfirmation
        show={showCartConfirmation}
        hideModal={handleHideCartConfirmation}
        itemAdded={itemAdded}
        setHide={handleHideCartConfirmation}
      />
    </ContentWrapper>
  );
};
