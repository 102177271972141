const useSessionStorage = () => {
  const getItem = (key: string): string | null => {
    return window.sessionStorage.getItem(key);
  };

  const setItem = (key: string, value: string): void => {
    window.sessionStorage.setItem(key, value);
  };

  const removeItem = (key: string): void => {
    window.sessionStorage.removeItem(key);
  };
  return {
    getItem,
    setItem,
    removeItem,
  };
};
export default useSessionStorage;
