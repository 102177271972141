import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";

export const TripleSpinner = ({ message }: { message?: string }) => {
  return (
    <div className="mt-4">
      <Row className="fw-bold font-title text-center">
        <Col>
          <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" className="me-2" />
          <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" className="me-2" />
          <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" className="me-2" />
        </Col>
      </Row>
      {message && (
        <Row className="mt-4 fw-bold font-title text-center">
          <Col>{message}</Col>
        </Row>
      )}
    </div>
  );
};
