import { useEffect, useState } from "react";
import { PlanInterval, Pocket, SubscriptionPlan } from "../../payment/SubscriptionApi";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import logger, { LogTagKey } from "../../../utils/logger";
import { UserInfo } from "../../signup/SignUpApi";
import useApi from "../../../hooks/useApi";
import "./SubcriptionPlans.scss";
import { TripleSpinner } from "../../ui/TripleSpinner";
import { useTranslation } from "react-i18next";
import { SubscriptionPlanBenefit } from "./SubcriptionPlansBenefits";
import { SubscriptionPlanInfo } from "./SubcriptionPlanInfo";
import { DetailSubscriptionPlanItem } from "./DetailSubscriptionPlanItem";
import { Link } from "react-router-dom";
import { getPlanWithDefaultCreditLimit } from "../../../utils/subscription";
import { ErrorModal } from "../../error/ErrorModal";
import { isCountryUk } from "../../../utils/country";
import { useContentfulContext } from "../../../context/ContentfulContext";

export interface ISelected {
  selected?: boolean;
}

const SubscriptionPlans = (props: {
  onSubscriptionPlanSelected: (plan: SubscriptionPlan | undefined) => void;
  setErrorMessage: (s: string | undefined) => void;
  pocket: Pocket | undefined;
  userInfo: UserInfo | undefined;
  currentPlanId?: string | undefined;
  isSignup: boolean;
  isSubmiting?: boolean;
  onSubmitBtnClick: (data: any) => void;
  showStoreLink?: boolean;
}) => {
  const { t, ready } = useTranslation();
  const {
    onSubscriptionPlanSelected,
    pocket,
    setErrorMessage,
    userInfo,
    currentPlanId,
    isSignup,
    isSubmiting,
    onSubmitBtnClick,
    showStoreLink,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState<(SubscriptionPlan & ISelected)[]>();
  const [preselectedPlan, setPreselectedPlan] = useState<SubscriptionPlan & ISelected>();
  const [defaultPlan, setDefaultPlan] = useState<SubscriptionPlan>();
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan & ISelected>();
  const [currentActivePlan, setCurrentActivePlan] = useState<SubscriptionPlan>();
  const [error, setError] = useState<string>();
  const [showError, setShowError] = useState<boolean>(false);
  const { content, isLoading: isContentfulLoading } = useContentfulContext();

  const subscriptionApi = useApi().subscription;

  useEffect(() => {
    const fetchSubscriptions = async () => {
      if (pocket?.id) {
        setErrorMessage(undefined);
        setIsLoading(true);

        subscriptionApi
          .getSubscriptionPlans()
          .then((response) => {
            const showingIds =
              content?.subscriptionPlans === undefined ? [] : content.subscriptionPlans.allowShowingPlanIds;
            let preselectedPlan;
            const subscriptionPlans = response
              .filter((p) => {
                if (showingIds?.length > 0) {
                  return showingIds.includes(p.subscriptionPlanId);
                }

                return true;
              })
              .sort((p1: SubscriptionPlan, p2: SubscriptionPlan) => p1.order - p2.order)
              .map((p: SubscriptionPlan & ISelected) => {
                currentPlanId && p.stripePlanId === currentPlanId && setCurrentActivePlan(p);
                if (p.stripePlanId === currentPlanId || (!currentPlanId && p.isDefault)) {
                  p.selected = true;
                  preselectedPlan = p;
                }
                return p;
              });

            if (subscriptionPlans.length === 0) {
              throw new Error("No active subscription plans");
            }

            setSubscriptionPlans(subscriptionPlans);

            if (preselectedPlan) {
              setPreselectedPlan(preselectedPlan);
            }

            const planWithLowestCreditLimit = getPlanWithDefaultCreditLimit(response);
            if (planWithLowestCreditLimit) {
              setDefaultPlan(planWithLowestCreditLimit);
            }
          })
          .catch((e: any) => {
            showErrorMessage(t("common.error.unknown"));
            setIsLoading(false);
            logger.error(e, LogTagKey.TkSubscription, "Payment System error fetching subscription plans", {
              membershipNumber: userInfo?.membershipNumber,
              pocketId: pocket?.id,
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    };
    fetchSubscriptions().then(() => {});
  }, [pocket]);

  useEffect(() => {
    preselectedPlan && onPlanSelected(preselectedPlan);
  }, [preselectedPlan]);

  const onPlanSelected = (selectedPlan: SubscriptionPlan | undefined) => {
    setSubscriptionPlans(
      (subscriptionPlans ?? []).map((plan) => ({
        ...plan,
        selected: plan.subscriptionPlanId === selectedPlan?.subscriptionPlanId,
      }))
    );
    onSubscriptionPlanSelected(selectedPlan);
    setSelectedPlan(selectedPlan);
    validateSelectedPlan(selectedPlan);
  };
  const validateSelectedPlan = (selectedPlan: SubscriptionPlan | undefined) => {
    if (!selectedPlan || !currentActivePlan) return;
    switch (currentActivePlan.interval) {
      case PlanInterval.year:
        if (selectedPlan.interval === PlanInterval.month) {
          showErrorMessage(t("subscription.errors.downgradeAnnualToMonthly"));
          return;
        }
        if (
          selectedPlan.interval === PlanInterval.year &&
          selectedPlan.renewalPeriod < currentActivePlan.renewalPeriod
        ) {
          showErrorMessage(t("subscription.errors.downgradePlan"));
          return;
        }
        setError(undefined);
        return;
      case PlanInterval.month:
        if (
          selectedPlan.interval === PlanInterval.month &&
          selectedPlan.renewalPeriod < currentActivePlan.renewalPeriod
        ) {
          showErrorMessage(t("subscription.errors.downgradePlan"));
          return;
        }
        setError(undefined);
        return;
      default:
        showErrorMessage(t("subscription.errors.unexpectedError"));
    }
  };
  const showErrorMessage = (message: string) => {
    setError(message);
    setShowError(true);
  };
  if (!ready) {
    return null;
  }

  if (isLoading || isContentfulLoading) {
    return <TripleSpinner />;
  }

  return (
    <>
      <Col className="my-4 me-2 text-center">
        <h2 id="subscription-plans-title">{content?.subscriptionPlans?.title ?? t("settings.forms.plans.title")}</h2>
        {showStoreLink && (
          <Link to="/store" target="_blank">
            {t("settings.forms.plans.seeStore")}
          </Link>
        )}
      </Col>
      <Row className="mt-4 justify-content-center">
        {subscriptionPlans &&
          subscriptionPlans.map((subscriptionPlan: SubscriptionPlan & ISelected) => {
            const id = `plan${subscriptionPlan.subscriptionPlanId}`;
            const cssClass =
              subscriptionPlan.title != null
                ? `
            #${id}::before {
              content: '${subscriptionPlan.title}'
            }
        `
                : "";
            return (
              <Col
                className={`col-6 col-lg-4 col-xl-3 my-4 subscription-card-holder ${
                  subscriptionPlan.selected || subscriptionPlan.stripePlanId === currentPlanId ? "active" : ""
                }`}
                id={id}
                key={`col-${subscriptionPlan.subscriptionPlanId}`}
                xs={12}
                md={4}
              >
                <style>{cssClass}</style>
                <DetailSubscriptionPlanItem
                  key={subscriptionPlan.subscriptionPlanId}
                  isSignup={isSignup}
                  subscriptionPlan={subscriptionPlan}
                  currentPlanId={currentPlanId}
                  onSelect={() => onPlanSelected(subscriptionPlan)}
                  onSubmitBtnClick={onSubmitBtnClick}
                />
              </Col>
            );
          })}
      </Row>
      <SubscriptionPlanBenefit planBenefit={content?.subscriptionPlans?.benefits ?? []} />
      <Row className="d-none d-md-block text-center my-5">
        <Col>
          {isSignup ? (
            <Button
              type="button"
              id="sign-up-subscription-plans-submit"
              disabled={!preselectedPlan || isSubmiting}
              size="lg"
              className="submit-button fs-4"
              variant="primary"
              onClick={onSubmitBtnClick}
            >
              {isSubmiting && (
                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" className="me-2" />
              )}
              {t("settings.forms.plans.choosePlan")}
            </Button>
          ) : (
            <Button
              id="changePlan"
              className="submit-button fs-4"
              disabled={selectedPlan?.stripePlanId === currentPlanId || !!error || isSubmiting}
              onClick={onSubmitBtnClick}
            >
              {isSubmiting && (
                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" className="me-2" />
              )}
              {selectedPlan?.stripePlanId === currentPlanId
                ? t("subscription.forms.planChange.currentPlan")
                : t("subscription.forms.planChange.changeButton")}
            </Button>
          )}
        </Col>
      </Row>
      <Row className="my-5 mx-2 justify-content-center">
        <SubscriptionPlanInfo
          description={content?.subscriptionPlans?.description || []}
          highlightedPlan={isCountryUk() ? selectedPlan : defaultPlan}
        />
      </Row>

      <ErrorModal
        id="modal-error-select-plan"
        show={showError}
        errorMessage={error}
        onClose={() => setShowError(false)}
      />
    </>
  );
};

export default SubscriptionPlans;
