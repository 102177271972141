import "./ShoppingCart.scss";
import { CheckoutLine, Money, StoreCheckout } from "../Checkout.types";
import { useTranslation } from "react-i18next";
import { Col, Image, Row } from "react-bootstrap";
import VariantFeature from "./VariantFeature";
import { formatPrice } from "../../../utils/money";
import { useGetVariantAttributeType } from "../../store/product-details/ProductDetails";
import { PaymentMethod, Pricing } from "../Checkout";
import { getLineVariantImage } from "../CheckoutUtils";

const ShoppingCart = ({
  checkout,
  showTotals = true,
}: {
  paymentMethod?: PaymentMethod;
  creditPricing?: Pricing;
  checkout: StoreCheckout | undefined;
  showTotals?: boolean;
}) => {
  const { t } = useTranslation();
  const getVariantAttributeType = useGetVariantAttributeType();

  const freeText = t("checkout.free");

  const TotalsLine = ({ labelKey, price }: { labelKey: string; price?: Money }) =>
    price != null ? (
      <>
        <td className="totals-name">{t(labelKey)}</td>
        <td className="totals-price">{formatPrice(price, freeText)}</td>
      </>
    ) : (
      <></>
    );

  const lines = checkout?.lines ?? [];
  const brand = "Bits";

  const renderVariantFeature = (label: string, value: string | undefined): JSX.Element | null => {
    if (value == null) {
      return null;
    }
    return <VariantFeature label={label} value={value} />;
  };

  const renderVariantInformation = (line: CheckoutLine): JSX.Element | null => {
    if (!line) {
      return null;
    }

    const selectedVariant: any = {};
    line?.variant?.attributes?.forEach((attribute) => {
      selectedVariant[getVariantAttributeType(attribute?.attribute?.name)] = attribute?.values?.[0]?.name;
    });

    const { color, size } = selectedVariant;
    if (color == null && size === null) {
      return null;
    }
    return (
      <>
        {renderVariantFeature("Color", color)}
        {renderVariantFeature("Size", size)}
      </>
    );
  };

  const renderLine = (line: CheckoutLine, index: number): JSX.Element => {
    return line ? (
      <div key={`checkout-line-${index}`}>
        <Row className="hidden-mobile">
          <Col>
            <h4 className="product-header">{line?.variant?.product?.name}</h4>
          </Col>
        </Row>
        <div>
          <div key={line.id} className="order-line">
            <Col sm={12} className="flex-row">
              <div className="product-image">
                <Image src={getLineVariantImage(line.variant)} />
              </div>
              <div className="product-description">
                <Col sm={12} className="flex-row">
                  <div className="product-name">
                    <span className="hidden-mobile">{brand}</span>
                    <strong className="visible-mobile">{line?.variant?.product?.name}</strong>
                    {checkout?.isShippingRequired ? (
                      <div>
                        {formatPrice(line.variant.pricing.price.gross, freeText)}{" "}
                        {line.requiresShipping ? (
                          <>
                            + {formatPrice(checkout?.shippingPrice?.gross, freeText)} {t("checkout.shipping1")}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="product-price">{formatPrice(line.totalPrice.gross, freeText)}</div>
                </Col>
                <Col sm={12} className="flex-row variant-information">
                  {renderVariantInformation(line)}
                </Col>
              </div>
            </Col>
          </div>
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const renderTotals = (): JSX.Element => {
    return (
      <div>
        <TotalsLine labelKey="checkout.subtotal" price={checkout?.subtotalPrice?.gross} />
        {checkout?.isShippingRequired && (
          <TotalsLine labelKey="checkout.shipping" price={checkout?.shippingPrice?.gross} />
        )}
        <TotalsLine labelKey="checkout.total" price={checkout?.totalPrice?.gross} />
      </div>
    );
  };
  const renderLines = (): JSX.Element => {
    return (
      <>
        {lines?.map((line: CheckoutLine, index: number) => renderLine(line, index))}
        {showTotals && renderTotals()}
      </>
    );
  };
  return renderLines();
};

export default ShoppingCart;
