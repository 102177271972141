import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/types";

interface TooltipOnHoverProps {
  title: string | undefined;
  children: JSX.Element;
  placement?: Placement;
}

export const TooltipOnHover = ({ title, children, placement }: TooltipOnHoverProps): JSX.Element => {
  if (!title) {
    return children;
  }

  return (
    <OverlayTrigger overlay={<Tooltip>{title}</Tooltip>} placement={placement}>
      <span className="d-inline-block">{children}</span>
    </OverlayTrigger>
  );
};
