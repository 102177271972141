import React, { useState } from "react";
import "./SubscriptionModal.scss";
import { useBitsUserContext } from "../../../context/SignedInContext";
import { Modal } from "react-bootstrap";
import SubscriptionPlans from "../../settings/subscription/SubcriptionPlans";
import SubscriptionPlansCasette from "../../cassettePages/settings/subscription/SubscriptionPlansCasette";
import { SubscriptionPlan } from "../../signup/SignUpApi";
import SignUpPayment from "../../signup/SignUpPayment";
import { isCountryUk } from "../../../utils/country";

const SubscriptionModal = ({ isShown, onClose }: { isShown: boolean; onClose: () => void }) => {
  const isUK = isCountryUk();
  const [subscriptionPlan, setSubscriptionPlan] = useState<SubscriptionPlan>();
  const [isPlanSelected, setIsPlanSelected] = useState<boolean>(false);
  const { userInfo, pocket, setErrorMessage } = useBitsUserContext();

  const handleClose = () => {
    onClose();
  };

  const thisUrl: string = window.location.href;

  return (
    <Modal show={isShown} onHide={handleClose} className={`subscription-modal`} size="xl" id="subscription-modal">
      <Modal.Header closeButton={true} />
      <Modal.Body>
        {!isPlanSelected && (
          <div id="subscriptions-list">
            {isUK ? (
              <SubscriptionPlansCasette
                userInfo={userInfo}
                pocket={pocket}
                setErrorMessage={setErrorMessage}
                onSubscriptionPlanSelected={(plan: SubscriptionPlan | undefined) => setSubscriptionPlan(plan)}
                isSignup={true}
                onSubmitBtnClick={() => setIsPlanSelected(true)}
                showStoreLink={false}
              />
            ) : (
              <SubscriptionPlans
                userInfo={userInfo}
                pocket={pocket}
                setErrorMessage={setErrorMessage}
                onSubscriptionPlanSelected={(plan: SubscriptionPlan | undefined) => setSubscriptionPlan(plan)}
                isSignup={true}
                onSubmitBtnClick={() => setIsPlanSelected(true)}
                showStoreLink={false}
              />
            )}
          </div>
        )}
        {isPlanSelected && (
          <div className="text-center mb-2" id="payment-form">
            <SignUpPayment
              subscriptionPlan={subscriptionPlan!}
              setSignUpStep={() => {}}
              checkoutSuccessUrl={thisUrl}
              checkoutCancelUrl={thisUrl}
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SubscriptionModal;
