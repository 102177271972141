import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CalendarImage from "../../../assets/settings/Date.webp";
import { TripleSpinner } from "../../ui/TripleSpinner";
import { InputIcon } from "../../ui/InputIcon";
import "./PaymentDateChange.scss";
import { PaymentDateCalendar } from "./PaymentDateCalendar";
import Loading from "../../common/Loading";
import { OnlyIf } from "../../common/OnlyIf";
import { ErrorMessage } from "../../error/ErrorMessage";
import { usePaymentDateChange } from "../../../hooks/signup/usePaymentDateChange";
import SubscriptionModal from "../../checkout/subscription-modal/SubscriptionModal";

export type PaymentDate = number | "last";

const PaymentDateChange = ({ isSignUp = false }: { isSignUp?: boolean }) => {
  const { t, ready } = useTranslation();
  const {
    isLoading,
    selectedPaymentDate,
    showCalendar,
    handleInputClick,
    handleSubmit,
    handleSkip,
    onDaySelected,
    errorMessage,
    isSubscriptionModalShown,
    setIsSubscriptionModalShown,
  } = usePaymentDateChange(isSignUp);

  if (!ready) {
    return <TripleSpinner />;
  }

  const formatSelectedDate = (day?: PaymentDate) => {
    if (!day) {
      return "";
    }

    if (day === "last") {
      return t("payment.forms.paymentDate.lastDayOfMonth");
    }

    return day + t("common.ordinal", { count: day, ordinal: true }) + " " + t("payment.forms.paymentDate.day");
  };

  if (isLoading) {
    return <Loading loading={true} message={t("payment.forms.paymentDate.sending")} />;
  }

  const lgRows = isSignUp ? 12 : 2;
  const title = isSignUp ? (
    <h2>{t("payment.forms.paymentDate.setPaymentDate")}</h2>
  ) : (
    <h1 className="payment-date-header">{t("payment.forms.paymentDate.header")}</h1>
  );
  const handleSubscriptionModalClose = () => {
    setIsSubscriptionModalShown(false);
  };
  return (
    <>
      {title}
      <Row>
        <Col lg={lgRows}>
          <p className="payment-date-subheader">{t("payment.forms.paymentDate.subheader")}</p>
          <Form.Group className="payment-date-input" onClick={handleInputClick}>
            <InputIcon>
              <i className="bi bi-calendar4" />
            </InputIcon>
            <Form.Control
              type="text"
              name="choose-day"
              placeholder={t("payment.forms.paymentDate.chooseDay")}
              readOnly
              value={formatSelectedDate(selectedPaymentDate)}
            />
          </Form.Group>
          <PaymentDateCalendar
            className="payment-date-calendar p-3"
            onDaySelected={onDaySelected}
            visible={showCalendar}
          />
        </Col>
        <OnlyIf condition={!isSignUp}>
          <Col className="d-none d-lg-flex hide-imgages">
            <Image
              data-cy="payment-date-change-img"
              className="w-100"
              src={CalendarImage}
              alt={t("settings.forms.main.changeMyPaymentDate")}
            />
          </Col>
        </OnlyIf>
      </Row>
      <OnlyIf condition={isSignUp}>
        <Row className="mb-2">
          <ErrorMessage errorMessage={errorMessage} />
        </Row>
      </OnlyIf>
      <Row>
        <Button
          id="confirm-change-payment-date"
          variant="primary"
          size="lg"
          disabled={isLoading || !selectedPaymentDate}
          onClick={handleSubmit}
        >
          {t("payment.forms.paymentDate.confirmButton")}
        </Button>
        <OnlyIf condition={isSignUp}>
          <Button
            id="skip-change-payment-date"
            variant="white"
            className="mt-1"
            size="lg"
            disabled={isLoading}
            onClick={handleSkip}
          >
            {t("payment.forms.paymentDate.skipButton")}
          </Button>
        </OnlyIf>
      </Row>
      <SubscriptionModal isShown={isSubscriptionModalShown} onClose={handleSubscriptionModalClose} />
    </>
  );
};

export default PaymentDateChange;
