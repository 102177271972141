import { useTranslation } from "react-i18next";
import { getExternalLinks, isCountryUk, isCountryUs } from "../../utils/country";

export const ESIGNMessage = (): JSX.Element | null => {
  const { t } = useTranslation();
  const isUS = isCountryUs();
  const externalLinks = getExternalLinks();

  const agreement = isCountryUk() ? t("accountSetup.esign.ukAgreement") : t("accountSetup.esign.agreement");
  const termsConditionsUrl = isCountryUk() ? externalLinks.termsUrl : externalLinks.termsConditionsUrl;

  const renderUSESignMessage = () => {
    if (!isCountryUs()) {
      return <></>;
    }
    return (
      <>
        {", "}
        <a
          className="enabled esign-link"
          id="esign-privacy-policy"
          href={externalLinks.policyPrivacyUrl}
          target="_blank"
        >
          {t("accountSetup.step2.privacyPolicy")}
        </a>
        <span className="enabled">{t("accountSetup.step2.and")}</span>{" "}
        <a className="enabled esign-link" href={externalLinks.esignUrl} target="_blank" id="esign-document-signatures">
          {t("accountSetup.step2.esign")}
        </a>
      </>
    );
  };

  const renderUKESignMessage = () => {
    if (!isCountryUk()) {
      return <></>;
    }
    return (
      <div className="termsConditions">
        <span className="enabled" id="esign-agreement">
          {agreement}{" "}
        </span>
        <a className="enabled esign-link" id="esign-tc" href={termsConditionsUrl} target="_blank">
          {t("accountSetup.esign.t&c")}
        </a>
        <span className="enabled">{t("accountSetup.step2.and")}</span>{" "}
        <a
          className="enabled esign-link"
          id="esign-privacy-policy"
          href={externalLinks.policyPrivacyUrl}
          target="_blank"
        >
          {t("accountSetup.step2.privacyPolicy")}
        </a>
      </div>
    );
  };

  return (
    <>
      {isUS && (
        <>
          <span className="enabled" id="esign-agreement">
            {agreement}{" "}
          </span>
          <a className="enabled esign-link" id="esign-tc" href={termsConditionsUrl} target="_blank">
            {t("accountSetup.esign.t&c")}
          </a>
          {renderUSESignMessage()}
        </>
      )}
      {renderUKESignMessage()}
    </>
  );
};
