import "./VariantFeature.scss";

interface IVariantFeature {
  label: string;
  value: string;
}

const VariantFeature = ({ label, value }: IVariantFeature): JSX.Element => {
  return (
    <fieldset className="variant-feature">
      <legend className="variant-feature-label">{label}</legend>
      <span className="variant-feature-value">{value}</span>
    </fieldset>
  );
};
export default VariantFeature;
