import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import "./SignUpSubscription.scss";
import logger, { LogTagKey } from "../../../utils/logger";
import { useSignUpContext } from "../../../context/SignUpContext";
import useApi from "../../../hooks/useApi";
import { Pocket } from "../../payment/SubscriptionApi";
import SubscriptionPlans from "../../settings/subscription/SubcriptionPlans";
import { useTranslation } from "react-i18next";
import { SubscriptionPlan, UserInfo } from "../SignUpApi";
import { ErrorMessage } from "../../error/ErrorMessage";
import { useNavigate } from "react-router";
import AuthService from "../../auth/AuthService";
import BitsLoading from "../../common/Loading";
import useLocalStorage from "../../../hooks/useSessionStorage";
import { mixPanelInstance } from "../../../utils/analytics/mixpanel";
import { v4 as uuidv4 } from "uuid";
import {
  LOADING_CREDIT_FILE_STEP_NO,
  SIGN_UP_ABOUT_YOUR_DATA_URL,
  SIGN_UP_PAYMENT_URL,
  SUBSCRIPTIONS_STEP_NO,
} from "../SignUp";

interface ISignUpSubscription {
  setSignUpStep: (stepNumber: number) => void;
  setBypassAuthCheck: (bypassAuthCheck: boolean) => void;
  setSubscriptionPlan: (subscriptionPlan: SubscriptionPlan | undefined) => void;
}

export const SUBSCRIPTION_PLAN_SELECTED_KEY = "subscription-plan-selected";

interface IUseSignUpSubscription {
  isLoading: number;
  pocket: Pocket | undefined;
  ready: boolean;
  t: any;
  errorMessage: string | undefined;
  userInfo: UserInfo;
  setErrorMessage: (errorMessage: string | undefined) => void;
  handleSubscriptionPlanSelected: (subscriptionPlan: SubscriptionPlan | undefined) => void;
  subscriptionPlanSelected: SubscriptionPlan | undefined;
  handleSubmit: (event: React.FormEvent) => void;
}

enum ELoadingState {
  NOT_LOADING = 0,
  INITAL_LOADING = 1,
  SUBMIT_LOADING = 2,
}

const useSignUpSubscription = ({
  setSignUpStep,
  setBypassAuthCheck,
  setSubscriptionPlan,
}: ISignUpSubscription): IUseSignUpSubscription => {
  const navigate = useNavigate();
  const [stickyState, setStickyState] = useState<any>(null);
  const [pocket, setPocket] = useState<Pocket | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<number>(ELoadingState.INITAL_LOADING);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { userInfo, signUpUUID, setSignUpUUID } = useSignUpContext();
  const { t, ready } = useTranslation();
  const { subscription } = useApi();
  const [subscriptionPlanSelected, setSubscriptionPlanSelected] = useState<SubscriptionPlan | undefined>(undefined);
  const { setItem } = useLocalStorage();

  useEffect(() => {
    if (stickyState) {
      setItem(SUBSCRIPTION_PLAN_SELECTED_KEY, JSON.stringify(stickyState));
      navigate(SIGN_UP_PAYMENT_URL);
    }
  }, [stickyState]);

  useEffect(() => {
    if (!AuthService.getAuthToken()) {
      setBypassAuthCheck(false);
      return navigate(SIGN_UP_ABOUT_YOUR_DATA_URL);
    }
    mixPanelInstance.trackSignUpSelectSubscription(signUpUUID);
    setSignUpStep(LOADING_CREDIT_FILE_STEP_NO);
    const fetchOrCreatePocket = async (): Promise<void> => {
      setSignUpStep(SUBSCRIPTIONS_STEP_NO);
      try {
        const pocket: Pocket = await subscription.createPocket();
        setPocket(pocket);
        setIsLoading(ELoadingState.NOT_LOADING);
      } catch (err: unknown) {
        setIsLoading(ELoadingState.NOT_LOADING);
        setErrorMessage(t("auth.errors.unknownError"));
        logger.error(err, LogTagKey.TkSignup, "Error creating pocket");
      }
    };
    fetchOrCreatePocket();
    return () => {
      setSignUpStep(0);
      setSignUpUUID(uuidv4());
    };
  }, []);

  const handleSubscriptionPlanSelected = (subscriptionPlan: SubscriptionPlan | undefined): void => {
    setSubscriptionPlanSelected(subscriptionPlan);
  };

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    if (subscriptionPlanSelected) {
      mixPanelInstance.trackSubscriptionPlanSelected(subscriptionPlanSelected);
      setIsLoading(ELoadingState.SUBMIT_LOADING);
      setSubscriptionPlan(subscriptionPlanSelected);
      setStickyState({ userInfo, pocket, subscriptionPlanSelected });
      window.scrollTo(0, 0);
    }
    setErrorMessage(t("settings.forms.plans.required"));
  };

  return {
    isLoading,
    pocket,
    ready,
    t,
    errorMessage,
    userInfo,
    setErrorMessage,
    handleSubscriptionPlanSelected,
    subscriptionPlanSelected,
    handleSubmit,
  };
};

const SignUpSubscription = ({ setSignUpStep, setBypassAuthCheck, setSubscriptionPlan }: ISignUpSubscription) => {
  const {
    isLoading,
    pocket,
    ready,
    t,
    errorMessage,
    userInfo,
    setErrorMessage,
    handleSubscriptionPlanSelected,
    handleSubmit,
  } = useSignUpSubscription({ setSignUpStep, setBypassAuthCheck, setSubscriptionPlan });

  if (!ready) {
    return null;
  }

  const renderErrorMessage = (): JSX.Element | null => {
    if (!errorMessage) {
      return null;
    }
    return (
      <Col className="mt-3">
        <ErrorMessage errorMessage={errorMessage} />
      </Col>
    );
  };

  if (isLoading === ELoadingState.INITAL_LOADING) {
    return <BitsLoading loading={true} message={t("settings.forms.plans.loadingCreditFile")} />;
  }

  return (
    <Form
      id="sign-up-subscription-plans-form"
      noValidate
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
        return false;
      }}
    >
      <SubscriptionPlans
        userInfo={userInfo}
        pocket={pocket}
        setErrorMessage={setErrorMessage}
        onSubscriptionPlanSelected={handleSubscriptionPlanSelected}
        isSignup={true}
        onSubmitBtnClick={handleSubmit}
        showStoreLink={true}
      />
      {renderErrorMessage()}
    </Form>
  );
};
export default SignUpSubscription;
