import "./Icon.scss";
import { Image } from "react-bootstrap";

export enum IconType {
  checkmark = "checkmark",
  exclamationWarning = "exclamationWarning",
  exclamationError = "exclamationError",
  clock = "clock",
  clockAlt = "clockAlt",
}

const Icon = ({ size, type, className }: { size: number; type: IconType; className?: string }) => {
  return (
    <div className={`text-center d-flex icon-row ${className}`}>
      <div
        className={`icon d-flex ${type}`}
        style={{ width: `${size}px`, height: `${size}px`, fontSize: `${Math.round(size * 0.8)}px` }}
      >
        {type === IconType.clock && <Image src="/assets/clock.svg" />}
        {type === IconType.clockAlt && <Image src="/assets/clock-1.svg" />}
        {type === IconType.checkmark && <i className="bi bi-check" />}
        {(type === IconType.exclamationWarning || type === IconType.exclamationError) && (
          <i className="bi bi-exclamation" />
        )}
      </div>
    </div>
  );
};
export default Icon;
