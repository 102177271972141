import BitsIcon from "../cassetteUI/common/BitsIcon";
import { useTranslation } from "react-i18next";
import { TripleSpinner } from "../ui/TripleSpinner";

import "./ScoreError.scss";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";

export const ScoreError = () => {
  const { t, ready } = useTranslation();
  const navigate = useNavigate();
  if (!ready) {
    return <TripleSpinner />;
  }

  return (
    <>
      <div className="unAuth-card" id="apply-error">
        <BitsIcon />
        <div className="signup-header">
          <h5 className="title"> {t("score.error.header")}</h5>
        </div>
        <div className="body form-wrapper">
          {t("score.error.top1")}
          {/*<Link to="">{t("score.error.top2")}</Link>*/}
          <Button
            type="button"
            className="form-input enabled w-100 button"
            id="sign-up-important-information-submit"
            onClick={() => navigate("../")}
          >
            {t("score.error.button")}
          </Button>
        </div>
      </div>
    </>
  );
};
