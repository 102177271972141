import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getCurrencySymbol } from "../../../utils/country";
import { AnnualRenewalPeriod, PlanInterval, SubscriptionPlan } from "../../payment/SubscriptionApi";
import { ISelected } from "./SubcriptionPlans";
import { useContentfulContext } from "../../../context/ContentfulContext";
import { OnlyIf } from "../../common/OnlyIf";

export const DetailSubscriptionPlanItem = React.memo(
  (props: {
    isSignup: boolean;
    currentPlanId?: string;
    subscriptionPlan: SubscriptionPlan & ISelected;
    onSelect: () => void;
    onSubmitBtnClick: (data: any) => void;
  }) => {
    const { t, ready } = useTranslation();
    const { content } = useContentfulContext();
    const { isSignup, subscriptionPlan, currentPlanId, onSelect, onSubmitBtnClick } = props;

    const isDefault = subscriptionPlan.isDefault ?? false;

    const getSavingLabelClass = () => {
      if (!subscriptionPlan.subtitle || subscriptionPlan.subtitle.length === 0) {
        return "invisible";
      }
      switch (subscriptionPlan.interval) {
        case PlanInterval.year:
          return subscriptionPlan.renewalPeriod === AnnualRenewalPeriod.annually
            ? "saving-label-annually"
            : "saving-label-biannually";
        case PlanInterval.month:
        default:
          return "invisible";
      }
    };
    const currency = getCurrencySymbol(subscriptionPlan.currency);
    const monthlyAnnualPlanAmount =
      subscriptionPlan.interval === PlanInterval.year && subscriptionPlan.renewalPeriod
        ? Math.floor(subscriptionPlan.amount / (subscriptionPlan.renewalPeriod * 12))
        : 0;

    const planDetails: string[] = content?.subscriptionPlans?.planDetails || [];

    if (!ready) {
      return null;
    }
    return (
      <Card
        className={`subscription-card ${subscriptionPlan.stripePlanId === currentPlanId && "current-plan"}`}
        onClick={onSelect}
      >
        <Card.Body>
          <Col className="text-center">
            <Row>
              <Col className=" mt-1 fw-bold fs-4" data-cy="plan-renewal">
                {subscriptionPlan.interval === PlanInterval.year
                  ? `${subscriptionPlan.renewalPeriod * 12 ?? 12} ${t("subscription.subscriptionInfo.intervalMonths")}`
                  : `${subscriptionPlan.renewalPeriod ?? 1} ${t("subscription.subscriptionInfo.intervalMonth")}`}
              </Col>
            </Row>
            <Row>
              <Col className="mx-3">
                <hr className="my-2" />
              </Col>
            </Row>
            <Row>
              <i className={`bi bi-lightning-fill selected ${!isDefault && "invisible"}`} />
            </Row>
            <Row className="m-1 fw-bold" data-cy="plan-price">
              {subscriptionPlan.interval === PlanInterval.month ? (
                <Col>
                  <span>{currency}</span>
                  <span className="fs-2">{subscriptionPlan.amount}</span>
                  <span className="ms-2 fs-6">/{subscriptionPlan.interval}</span>
                </Col>
              ) : (
                <Col>
                  <span>{currency}</span>
                  <span className="fs-2">{monthlyAnnualPlanAmount}</span>
                  <span className="ms-2 fs-6">/{t("subscription.subscriptionInfo.intervalMonth")}</span>
                </Col>
              )}
            </Row>
            <Row>
              <Col className="mx-1 fs-6" data-cy="plan-billed-as">
                {subscriptionPlan.interval === PlanInterval.month && (
                  <>
                    <small>{t("subscription.subscriptionInfo.billed")}</small>{" "}
                    <small className="fw-bold"> {t("subscription.subscriptionInfo.intervalMonthly")}</small>
                  </>
                )}
                {subscriptionPlan.interval === PlanInterval.year && (
                  <>
                    <small>
                      {t("subscription.subscriptionInfo.billedAs")} {currency}
                      {subscriptionPlan.amount}
                    </small>
                    <small className="fw-bold">
                      {subscriptionPlan.renewalPeriod === AnnualRenewalPeriod.biannually
                        ? t("subscription.subscriptionInfo.intervalBiannual")
                        : t("subscription.subscriptionInfo.intervalAnnual")}
                    </small>
                  </>
                )}
              </Col>
            </Row>
            <OnlyIf condition={planDetails?.length > 0}>
              <Row className="plan-details mt-4">
                <Col className="mx-1 fs-6" data-cy="plan-details">
                  <ul>
                    {planDetails.map((planDetailsText, idx) => (
                      <li key={`planDetails${idx}`}>
                        {planDetailsText.replace(
                          "%CREDIT_LIMIT%",
                          `${getCurrencySymbol(subscriptionPlan?.currency)}${subscriptionPlan?.creditAmount ?? "0"}`
                        )}
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </OnlyIf>

            <Row className={`mt-4 ${getSavingLabelClass()}`}>
              <Col>{subscriptionPlan.subtitle}</Col>
            </Row>
            <Row className="d-md-none m-3">
              {isSignup ? (
                <Button
                  type="button"
                  id={`sign-up-subscription-plans-submit${subscriptionPlan.subscriptionPlanId}`}
                  disabled={!subscriptionPlan.selected}
                  size="lg"
                  className="submit-button fs-4"
                  variant="primary"
                  onClick={onSubmitBtnClick}
                >
                  {t("settings.forms.plans.choosePlan")}
                </Button>
              ) : (
                <Button
                  id={`changePlan${subscriptionPlan.subscriptionPlanId}`}
                  className="submit-button fs-4"
                  disabled={subscriptionPlan?.stripePlanId === currentPlanId || !subscriptionPlan.selected}
                  onClick={onSubmitBtnClick}
                >
                  {subscriptionPlan?.stripePlanId === currentPlanId
                    ? t("subscription.forms.planChange.currentPlan")
                    : t("subscription.forms.planChange.changeButton")}
                </Button>
              )}
            </Row>
          </Col>
        </Card.Body>
      </Card>
    );
  }
);
