import BitsLogo from "../../../assets/cassetteUI/images/bits-logo-icon.png";
import "./BitsIcon.scss";

const BitsIcon = () => {
  return (
    <div className="bits-icon">
      <img src={BitsLogo} alt="bits logo" />
    </div>
  );
};
export default BitsIcon;
