import { createContext, useContext, useState } from "react";
import { UserInfo, Address, SubscriptionPlan } from "../components/signup/SignUpApi";
import { v4 as uuidv4 } from "uuid";

interface AppContextInterface {
  userInfo: UserInfo;
  setUserInfo: (userInfo: UserInfo) => void;
  selectedAddress: Address | undefined;
  setSelectedAddress: (address: Address | undefined) => void;
  subscriptionPlan: SubscriptionPlan | undefined;
  setSubscriptionPlan: (subscriptionPlan: SubscriptionPlan | undefined) => void;
  signUpUUID?: string;
  setSignUpUUID: (signUpUUID: string) => void;
  signUpSessionId: string | undefined;
  setSignUpSessionId: (signUpSessionId: string | undefined) => void;
}

export const INITIAL_SIGN_UP_USER_INFO: UserInfo = {
  address1: "",
  address2: "",
  address3: "",
  addressId: "",
  city: "",
  dateBirth: "",
  email: "",
  emergencyContact: "",
  firstName: "",
  gender: "",
  lastName: "",
  password: "",
  phoneNumber: "",
  phoneNumberToken: "",
  postCode: "",
  referredBy: "",
  sessionId: "",
  identifier: "",
  membershipNumber: "",
  country: "",
  state: "",
  placeId: "",
  userId: "",
  termsAccepted: false,
};

export const INITIAL_CONTEXT: AppContextInterface = {
  userInfo: INITIAL_SIGN_UP_USER_INFO,
  setUserInfo: () => {},
  selectedAddress: undefined,
  setSelectedAddress: () => {},
  setSubscriptionPlan: () => {},
  subscriptionPlan: {
    subscriptionPlanId: 17,
    name: "80_grand_reserver_2880",
    title: "80",
    subtitle: "",
    availability: "",
    amount: 80.0,
    currency: "GBP",
    interval: "month",
    creditAmount: 2880.0,
    order: 999,
    isDefault: false,
    stripePlanId: "price_1GqdN6BAuCt4uEK6QOXceMuk",
  },
  signUpUUID: "",
  setSignUpUUID: () => {},
  signUpSessionId: undefined,
  setSignUpSessionId: () => {},
};

export const SignUpContext = createContext<AppContextInterface>(INITIAL_CONTEXT);

export const useSignUpContext = () => useContext(SignUpContext);

const SignUpContextProvider: React.FC = ({ children }) => {
  const [userInfo, setUserInfo] = useState<UserInfo>(INITIAL_SIGN_UP_USER_INFO);
  const [selectedAddress, setSelectedAddress] = useState<Address | undefined>();
  const [subscriptionPlan, setSubscriptionPlan] = useState<SubscriptionPlan | undefined>(undefined);
  const [signUpUUID, setSignUpUUID] = useState<string>(uuidv4());
  const [signUpSessionId, setSignUpSessionId] = useState<string | undefined>();

  return (
    <SignUpContext.Provider
      value={{
        userInfo,
        setUserInfo,
        selectedAddress,
        setSelectedAddress,
        subscriptionPlan,
        setSubscriptionPlan,
        signUpUUID,
        setSignUpUUID,
        signUpSessionId,
        setSignUpSessionId,
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
};

export default SignUpContextProvider;
