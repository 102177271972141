import ReactPixel from "react-facebook-pixel";
import { SubscriptionPlan, UserInfo } from "../../components/signup/SignUpApi";
import { getCookieConsentValue } from "react-cookie-consent";
import { CheckoutLine, StoreCheckout } from "../../components/checkout/Checkout.types";

const pixelIdFromConfig = process.env.REACT_APP_FACEBOOK_PIXEL_ID || undefined;

export const getStoreCheckoutContents = (checkout: StoreCheckout): any => {
  const contents: any = [];
  const content_ids: string[] = [];
  checkout?.lines.forEach((line: CheckoutLine) => {
    const { sku, product, pricing } = line?.variant ?? {};
    const { id, name } = product ?? {};
    const { price } = pricing ?? {};
    if (id) {
      content_ids.push(id);
      contents.push({ id, name, sku, price });
    }
  });
  return { contents, content_ids };
};

export const buildPaymentDataFromStoreCheckout = (checkout: StoreCheckout): any => {
  const { content_ids, contents } = getStoreCheckoutContents(checkout);
  const { amount, currency } = checkout?.totalPrice?.gross ?? {};
  return {
    value: amount?.toFixed(2),
    currency: currency,
    content_type: "product",
    content_ids,
    contents,
  };
};

class FacebookPixelAPI {
  private _initialized = false;
  init(pixelId?: string, userInfo?: UserInfo) {
    const advancedMatching: ReactPixel.AdvancedMatching | undefined =
      userInfo && userInfo.email
        ? ({
            em: userInfo.email,
            ct: userInfo.city ?? "",
            country: "US",
            db: userInfo.dateBirth ?? "",
            fn: userInfo.firstName ?? "",
            ge: "",
            ln: userInfo.lastName ?? "",
            ph: userInfo.phoneNumber ?? "",
            st: "",
            zp: userInfo.postCode ?? "",
            extern_id: userInfo.membershipNumber ?? undefined,
            external_id: userInfo.membershipNumber ?? undefined,
          } as any)
        : undefined;
    const options: ReactPixel.Options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(pixelId || pixelIdFromConfig || "", advancedMatching, options);
    if (!this._initialized && getCookieConsentValue() !== "true") {
      ReactPixel.revokeConsent();
    }
    this._initialized = true;
  }

  allowTracking() {
    ReactPixel.grantConsent();
  }

  trackPurchase(checkout: StoreCheckout, userInfo?: UserInfo) {
    if (userInfo && pixelIdFromConfig) {
      this.init(pixelIdFromConfig, userInfo);
    }

    const data = buildPaymentDataFromStoreCheckout(checkout);
    ReactPixel.track("Purchase", data);
    ReactPixel.trackCustom("Product Purchase", data);
  }

  trackSubscribe(plan: SubscriptionPlan, userInfo?: UserInfo) {
    if (userInfo && pixelIdFromConfig) {
      this.init(pixelIdFromConfig, userInfo);
    }

    const { currency, amount, subscriptionPlanId: id, title } = plan ?? {};
    const amountWithDecimals = amount?.toFixed(2);
    const data = {
      currency,
      value: amountWithDecimals,
      content_type: "product",
      content_ids: [id],
      contents: [
        {
          id: id,
          quantity: 1,
        },
      ],
      plan: `${title}: ${amountWithDecimals}/month`,
    };
    ReactPixel.track("Subscribe", data);
    ReactPixel.track("Purchase", data);
    ReactPixel.trackCustom("Subscription Purchased", data);
  }

  trackLogin(userInfo?: UserInfo) {
    if (userInfo && pixelIdFromConfig) {
      this.init(pixelIdFromConfig, userInfo);
    }

    ReactPixel.trackCustom("Login", {});
  }
}

export default new FacebookPixelAPI();
