import { useEffect, useState } from "react";
import useApi from "../../../hooks/useApi";
import { SubscriptionPlan, UserInfo } from "../SignUpApi";
import logger, { LogTagKey } from "../../../utils/logger";
import { WalletModel, WalletStatus } from "../../payment/PaymentApi";
import CheckoutForm from "../../payment/CheckoutForm";
import { useUTMQuery } from "../../../hooks/useUTMQuery";

const SubscriptionCheckoutForm = (props: {
  userInfo?: UserInfo;
  subscriptionPlan: SubscriptionPlan;
  checkoutSuccessUrl?: string;
  checkoutCancelUrl?: string;
}) => {
  const { userInfo, subscriptionPlan, checkoutSuccessUrl, checkoutCancelUrl } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const paymentApi = useApi().payment;
  const { appendUTMCode } = useUTMQuery();

  useEffect(() => {
    const fetchWallet = async () => {
      setErrorMessage(undefined);
      setIsLoading(true);
      paymentApi
        .getWallet()
        .then((response: WalletModel) => {
          if (
            response.gateway?.checkoutUrl != null &&
            response.plan?.id === subscriptionPlan.stripePlanId &&
            response.status === WalletStatus.pending
          ) {
            window.location.href = response.gateway.checkoutUrl;
          }
          return response;
        })
        .then(() => {
          const baseHrefTokens = window.location.href.split("/");
          baseHrefTokens.pop();
          const successUrl = checkoutSuccessUrl ?? [...baseHrefTokens, appendUTMCode("confirmation")].join("/");
          const cancelUrl = checkoutCancelUrl ?? [...baseHrefTokens, "schedule"].join("/");
          paymentApi.createSubscription(subscriptionPlan.stripePlanId, successUrl, cancelUrl).then((response) => {
            if (response.gateway?.checkoutUrl != null) {
              window.location.href = response.gateway.checkoutUrl;
            }
          });
        })
        .catch((e: any) => {
          setErrorMessage("Something went wrong! Please contact Bits for support.");
          setIsLoading(false);
          logger.error(e, LogTagKey.TkPaymentSystem, "Payment System error creating subscription", {
            membershipNumber: userInfo?.membershipNumber,
            stripePlanId: subscriptionPlan.stripePlanId,
          });
        });
    };

    fetchWallet();
  }, [subscriptionPlan, paymentApi, userInfo]);

  return <CheckoutForm errorMessage={errorMessage} isLoading={isLoading} />;
};
export default SubscriptionCheckoutForm;
