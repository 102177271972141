import BitsIcon from "../cassetteUI/common/BitsIcon";
import { useTranslation } from "react-i18next";
import { TripleSpinner } from "../ui/TripleSpinner";
import { useBitsUserContext } from "../../context/SignedInContext";

import "./ScoreDataConfirm.scss";
import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { useNavigate } from "react-router";
import logger, { LogTagKey } from "../../utils/logger";
import { Spinner } from "react-bootstrap";
import { EligibilityResponse } from "./ScoreApi";

export const ScoreDataConfirm = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { userInfo, setErrorMessage } = useBitsUserContext();
  const [eligibility, setEligibility] = useState<EligibilityResponse>();

  const scoreApi = useApi().score;
  const navigate = useNavigate();

  const { t, ready } = useTranslation();
  if (!ready) {
    return <TripleSpinner />;
  }

  useEffect(() => {
    scoreApi
      .getEligibility()
      .then((res) => {
        setEligibility(res);
      })
      .catch((err) => {
        setErrorMessage(t("common.error.unknown"));
        logger.error(err, LogTagKey.TkScore, "Failed to get eligibility status", {
          membershipNumber: userInfo?.membershipNumber,
        });
      });
    return () => setErrorMessage("");
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    setErrorMessage("");
    const apiCall = eligibility?.kycRequired ? scoreApi.activateStart() : scoreApi.activate();

    apiCall
      .then(() => {
        navigate(eligibility?.kycRequired ? "../kyc" : "../complete");
      })
      .catch((err: any) => {
        if (err.error?.errorType === "Equifax") {
          navigate("../error");
        } else {
          setErrorMessage(t("common.error.unknown"));
        }
        logger.error(err, LogTagKey.TkScore, "Failed to get activate score service", {
          membershipNumber: userInfo?.membershipNumber,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="unAuth-card" id="data-confirm">
        <BitsIcon />
        <div className="signup-header">
          <h5 className="title"> {t("score.data.header")}</h5>
        </div>
        <div className="body form-wrapper">
          {t("score.data.top")}
          <ul>
            <li>{t("score.data.ul1")}</li>
            <li>{t("score.data.ul2")}</li>
            <li>{t("score.data.ul3")}</li>
            <li>{t("score.data.ul4")}</li>
          </ul>
          <button
            type="button"
            className="form-input enabled w-100"
            id="sign-up-important-information-submit"
            onClick={handleSubmit}
            disabled={loading || eligibility == null}
          >
            {loading && (
              <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" className="me-2" />
            )}{" "}
            {t("auth.forms.continue")}
          </button>
        </div>
      </div>
    </>
  );
};
