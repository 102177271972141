import { ReactNode } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "./ErrorMessage";

interface IErrorModal {
  id: string;
  show: boolean;
  errorMessage?: string;
  title?: string;
  className?: string;
  onClose: () => void;
  children?: ReactNode;
}
export const ErrorModal = ({ id, show, errorMessage, title, className, children, onClose }: IErrorModal) => {
  const { t, ready } = useTranslation();
  if (!ready) return null;
  return (
    <Modal id={id} show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title ?? t("common.errorView.subheader")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ErrorMessage errorMessage={errorMessage ?? t("common.error.unknown")} className={className ?? "mt-2"} />
        {children}
      </Modal.Body>
    </Modal>
  );
};
