import { ReactNode, useEffect, useState } from "react";
import { OnlyIf } from "./OnlyIf";
import { useContentfulContext } from "../../context/ContentfulContext";

interface FeatureFlagProps {
  name: string;
  children: ReactNode;
  initial?: boolean;
  inverse?: boolean;
}

export const useFeatureFlag = (name: string, inverse = false): boolean => {
  const [show, setShow] = useState(inverse);
  const { content, contentReady } = useContentfulContext();
  useEffect(() => {
    if (content?.featureFlags?.[name] === true) {
      setShow(!inverse);
    }
  }, [name, inverse, contentReady]);

  return show;
};

export const FeatureFlag = ({ name, children, inverse = false }: FeatureFlagProps) => {
  const show = useFeatureFlag(name, inverse);

  return <OnlyIf condition={show}>{children}</OnlyIf>;
};
