import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import "./SignUp.scss";
import SignUpContextProvider from "../../context/SignUpContext";
import { SubscriptionPlan } from "./SignUpApi";
import loadable from "@loadable/component";
import { useStickyState } from "../../hooks/useStickyState";
import { TrustPilotBox } from "../common/TrustPilotBox";
import { SignUpConfirmationV2 } from "./SignUpConfirmationV2";

const SignUpCreatePasswordV2 = loadable(
  () => import(/* webpackChunkName: "SignUpCreatePasswordV2" */ "./password/SignUpCreatePasswordV2"),
  {
    resolveComponent: (components) => components.SignUpCreatePasswordV2,
  }
);

const SignUpChangePaymentDateV2 = loadable(
  () => import(/* webpackChunkName: "SignUpChangePaymentDateV2" */ "./SignUpChangePaymentDateV2"),
  {
    resolveComponent: (components) => components.SignUpChangePaymentDateV2,
  }
);

const SignUpPaymentV2 = loadable(() => import(/* webpackChunkName: "SignUpPaymentV2" */ "./SignUpPaymentV2"), {
  resolveComponent: (components) => components.SignUpPaymentV2,
});

const SignUpSubscriptionV2 = loadable(
  () => import(/* webpackChunkName: "SignUpSubscriptionV2" */ "./plans/SignUpSubscriptionV2"),
  {
    resolveComponent: (components) => components.SignUpSubscriptionV2,
  }
);

const SignUpAboutYourDataV2 = loadable(
  () => import(/* webpackChunkName: "SignUpAboutYourDataV2" */ "./aboutyourdata/SignUpAboutYourDataV2"),
  {
    resolveComponent: (components) => components.SignUpAboutYourDataV2,
  }
);

const SignUpPhoneNumber = loadable(
  () => import(/* webpackChunkName: "SignUpPhoneNumber" */ "./contact/SignUpPhoneNumber"),
  {
    resolveComponent: (components) => components.SignUpPhoneNumber,
  }
);

const SignUpEmail = loadable(() => import(/* webpackChunkName: "SignUpEmail" */ "./contact/SignUpEmail"), {
  resolveComponent: (components) => components.SignUpEmail,
});

const SignUpLegalNameV2 = loadable(() => import(/* webpackChunkName: "SignUpLegalNameV2" */ "./SignUpLegalNameV2"), {
  resolveComponent: (components) => components.SignUpLegalNameV2,
});

const SignUpVerifyPhone = loadable(
  () => import(/* webpackChunkName: "SignUpVerifyPhone" */ "./contact/SignUpVerifyPhone"),
  {
    resolveComponent: (components) => components.SignUpVerifyPhone,
  }
);

const LoginSignupSwitch = loadable(
  () => import(/* webpackChunkName: "LoginSignupSwitch" */ "../common/LoginSignupSwitch"),
  {
    resolveComponent: (components) => components.LoginSignupSwitch,
  }
);

const SignUpReferralsV2 = loadable(
  () => import(/* webpackChunkName: "SignUpReferralsV2" */ "./referrals/SignUpReferralsV2"),
  {
    resolveComponent: (components) => components.SignUpReferralsV2,
  }
);

const SignUpSchedulePayment = loadable(
  () => import(/* webpackChunkName: "SignUpSchedulePayment" */ "./schedulepayment/SignUpSchedulePayment")
);

interface ISignUp {
  setHeaderClass: (headerClass: string | null) => void;
  setWrapperClass: (wrapperClass: string | null) => void;
  setBypassAuthCheck: (bypassAuthCheck: boolean) => void;
  setDisplayBackButton: (displayBackButton: boolean) => void;
}

interface IUseSignUp {
  layout: string;
  subscriptionPlan: SubscriptionPlan | undefined;
  signUpStep: number;
  setSignUpStep: (signUpStep: number) => void;
  setSubscriptionPlan: (subscriptionPlan: SubscriptionPlan | undefined) => void;
  isBeforeSubscriptionPlansPage: boolean;
}

export const SIGN_UP_V2_INDEX_ROUTE_STEP_NO = 0;
export const SIGN_UP_V2_PHONE_NUMBER_STEP_NO = 1;
export const SIGN_UP_V2_VERIFY_PHONE_NUMBER_STEP_NO = 2;
export const SIGN_UP_V2_CREATE_PASSWORD_STEP_NO = 3;
export const SIGN_UP_V2_SUBSCRIPTION_PLANS_STEP_NO = 4;
export const SIGN_UP_V2_LEGAL_NAME_STEP_NO = 5;
export const SIGN_UP_V2_REFERRALS_STEP_NO = 6;
export const SIGN_UP_V2_ABOUT_YOUR_DATA_STEP_NO = 7;
export const LOADING_CREDIT_FILE_STEP_NO = 999;
export const SIGN_UP_V2_PAYMENT_STEP_NO = 8;
export const SIGN_UP_V2_CHANGE_PAYMENT_DATE_STEP_NO = 9;
export const SIGN_UP_V2_CONFIRMATION_STEP_NO = 10;
export const SIGN_UP_V2_SCHEDULE_CONFIRMATION_STEP_NO = 11;

export const SIGN_UP_V2_INDEX_ROUTE_URL = "/signup";
export const SIGN_UP_V2_PHONE_NUMBER_URL = "/signup/phone";
export const SIGN_UP_V2_VERIFY_PHONE_NUMBER_URL = "/signup/smsverification";
export const SIGN_UP_V2_LEGALNAME_URL = "/signup/legal";
export const SIGN_UP_V2_SUBSCRIPTION_PLANS_URL = "/signup/subscription";
export const SIGN_UP_V2_CREATE_PASSWORD_URL = "/signup/password";
export const SIGN_UP_V2_REFERRALS_URL = "/signup/referrals";
export const SIGN_UP_V2_ABOUT_YOUR_DATA_URL = "/signup/aboutyourdata";
export const SIGN_UP_V2_PAYMENT_URL = "/signup/payment";
export const SIGN_UP_V2_CHANGE_PAYMENT_DATE_URL = "/signup/paymentdate";
export const SIGN_UP_V2_CONFIRMATION_URL = "/signup/confirmation";
export const SIGN_UP_V2_SCHEDULE_URL = "/signup/schedule";

enum SignupPaths {
  EMAIL = "email",
  PHONE_NUMBER = "phone",
  ADDRESS = "address",
  CONTACT_INFO = "contactinfo",
  PASSWORD = "password",
  REFERRALS = "referrals",
  SCHEDULE = "schedule",
  ABOUT_YOUR_DATA = "aboutyourdata",
  SUBSCRIPTION = "subscription",
  PAYMENT = "payment",
  PAYMENT_DATE = "paymentdate",
  CONFIRMATION = "confirmation",
  LEGAL = "legal",
  PHONE_VERIFICATION = "smsverification",
}

export const freeSubscriptionPlanId = -1;
export const freePlan: Readonly<SubscriptionPlan> = Object.freeze({
  subscriptionPlanId: freeSubscriptionPlanId,
  amount: 0,
  title: "Free",
  interval: "month",
  stripePlanId: "n/a",
} as SubscriptionPlan);

const useSignUp = ({ setHeaderClass, setWrapperClass, setDisplayBackButton }: any): IUseSignUp => {
  const [isBeforeSubscriptionPlansPage, setIsBeforeSubscriptionPlansPage] = useState(false);
  const [signUpStep, setSignUpStep] = useStickyState<number>(0, "signUpStep");
  const [styleClasses, setStyleClasses] = useState<any>({
    headerClass: "",
    wrapperClass: "",
    layout: "welcome-layout",
  });
  const [subscriptionPlan, setSubscriptionPlan] = useStickyState<SubscriptionPlan | undefined>(
    undefined,
    "subscriptionPlan"
  );

  useEffect(() => {
    const styleClasses: any = getClassNames();
    setStyleClasses(styleClasses);
    const { headerClass, wrapperClass } = styleClasses;
    setHeaderClass(headerClass);
    setWrapperClass(wrapperClass);
    const displayBackButton: boolean =
      signUpStep > 0 &&
      signUpStep !== SIGN_UP_V2_SUBSCRIPTION_PLANS_STEP_NO &&
      signUpStep !== SIGN_UP_V2_SCHEDULE_URL &&
      signUpStep !== SIGN_UP_V2_CHANGE_PAYMENT_DATE_STEP_NO;
    setDisplayBackButton(displayBackButton);
    setIsBeforeSubscriptionPlansPage(signUpStep < SIGN_UP_V2_SUBSCRIPTION_PLANS_STEP_NO);
  }, [signUpStep]);

  const getClassNames = (): any => {
    switch (signUpStep) {
      case SIGN_UP_V2_SUBSCRIPTION_PLANS_STEP_NO:
        return { headerClass: "", wrapperClass: "unauthorized-white-layout", layout: "subscriptions-layout" };
      case LOADING_CREDIT_FILE_STEP_NO:
        return { headerClass: "d-none", wrapperClass: "", layout: "welcome-layout welcome-loading-layout" };
      default:
        return { headerClass: "", wrapperClass: "", layout: "welcome-layout" };
    }
  };

  const layout: string = styleClasses?.layout || "welcome-layout";

  return {
    layout,
    subscriptionPlan,
    signUpStep,
    setSignUpStep,
    setSubscriptionPlan,
    isBeforeSubscriptionPlansPage,
  };
};

export const SignUpV2 = ({ setHeaderClass, setWrapperClass, setBypassAuthCheck, setDisplayBackButton }: ISignUp) => {
  const START_SIGNUP_STEP_NO = 0;
  const { layout, signUpStep, subscriptionPlan, setSignUpStep, setSubscriptionPlan } = useSignUp({
    setHeaderClass,
    setWrapperClass,
    setDisplayBackButton,
  });

  const renderLoginSignUpSwitch = (): JSX.Element | null => {
    if (signUpStep > START_SIGNUP_STEP_NO) {
      return null;
    }
    return (
      <Row>
        <Col className="text-center px-0" xs={{ span: 10, offset: 1 }} sm={{ span: 12, offset: 0 }}>
          <LoginSignupSwitch isSignup={true} />
        </Col>
      </Row>
    );
  };

  return (
    <SignUpContextProvider>
      <div className={layout}>
        <Container className="pb-3">
          {renderLoginSignUpSwitch()}
          <Row className="justify-content-center pt-2">
            <Col>
              <Routes>
                <Route index element={<SignUpEmail setSignUpStep={setSignUpStep} />} />
                <Route path={SignupPaths.LEGAL} element={<SignUpLegalNameV2 setSignUpStep={setSignUpStep} />} />
                <Route path={SignupPaths.PHONE_NUMBER} element={<SignUpPhoneNumber setSignUpStep={setSignUpStep} />} />
                <Route
                  path={SignupPaths.PHONE_VERIFICATION}
                  element={<SignUpVerifyPhone setSignUpStep={setSignUpStep} />}
                />
                <Route path={SignupPaths.PASSWORD} element={<SignUpCreatePasswordV2 setSignUpStep={setSignUpStep} />} />
                <Route path={SignupPaths.REFERRALS} element={<SignUpReferralsV2 setSignUpStep={setSignUpStep} />} />
                <Route
                  path={SignupPaths.SCHEDULE}
                  element={<SignUpSchedulePayment setSignUpStep={setSignUpStep} subscriptionPlan={subscriptionPlan} />}
                />
                <Route
                  path={SignupPaths.ABOUT_YOUR_DATA}
                  element={
                    <SignUpAboutYourDataV2 setSignUpStep={setSignUpStep} setBypassAuthCheck={setBypassAuthCheck} />
                  }
                />
                <Route
                  path={SignupPaths.SUBSCRIPTION}
                  element={
                    <SignUpSubscriptionV2
                      setSignUpStep={setSignUpStep}
                      setBypassAuthCheck={setBypassAuthCheck}
                      setSubscriptionPlan={setSubscriptionPlan}
                    />
                  }
                />
                <Route
                  path={SignupPaths.PAYMENT}
                  element={<SignUpPaymentV2 subscriptionPlan={subscriptionPlan!} setSignUpStep={setSignUpStep} />}
                />
                <Route
                  path={SignupPaths.PAYMENT_DATE}
                  element={<SignUpChangePaymentDateV2 setSignUpStep={setSignUpStep} signUpStep={signUpStep} />}
                />
                {/* TODO: subscriptionPlan must be in signup context */}
                <Route
                  path={SignupPaths.CONFIRMATION}
                  element={<SignUpConfirmationV2 setSignUpStep={setSignUpStep} />}
                />
              </Routes>
            </Col>
          </Row>
          <TrustPilotBox />
        </Container>
      </div>
    </SignUpContextProvider>
  );
};
