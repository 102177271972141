import { getValidAge } from "./country";
import dayjs from "dayjs";

const customParseFormat = require("dayjs/plugin/customParseFormat");
const AdvancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(AdvancedFormat);
const advancedDayjs: any = dayjs;
const dayJsCustomParser: any = dayjs;
dayJsCustomParser.extend(customParseFormat);

const MONTHS = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

const DATE_FORMAT = "YYYY-MM-DD";
export const isDateValid = (dateStr: string) => {
  return dayJsCustomParser(dateStr, DATE_FORMAT, true).isValid();
};
export const dateToString = (date: Date) => {
  return advancedDayjs(date).format(DATE_FORMAT);
};
export const stringToDate = (dateStr: string) => {
  try {
    return new Date(dateStr);
  } catch (e) {
    return undefined;
  }
};
export const isAgeValid = (date: Date | null): boolean => {
  try {
    const dob = advancedDayjs(date);
    const yearsOld = advancedDayjs(new Date()).diff(dob, "years");
    if (yearsOld < getValidAge()) {
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
};

const dateFormat = "MMMM Do YYYY";
const monthFormat = "MMM YYYY";
const dateTimeFormat = "MMM D [at] h:mmA";
const dateTimeFormatBy = "MMM D [by] h:mmA";

export const formatUtcAsLocalDate = (utcDate: string | null | undefined): string =>
  utcDate != null ? advancedDayjs(utcDate, { utc: true }).format(dateFormat) : "";

export const formatUtcAsLocalDateTime = (utcDate: string | null | undefined): string =>
  utcDate != null ? advancedDayjs(utcDate, { utc: true }).format(dateTimeFormat) : "";

export const formatUtcAsLocalDateTimeByEOD = (utcDate: string | null | undefined): string =>
  utcDate != null ? advancedDayjs(utcDate, { utc: true }).endOf("day").format(dateTimeFormatBy) : "";

export const formatUtcAsLocalMonth = (utcDate: string | null | undefined): string =>
  utcDate != null ? advancedDayjs(utcDate, { utc: true }).format(monthFormat) : "";

export const getMonthName = (isoDate: string): string => {
  return MONTHS[advancedDayjs(isoDate).get("month")];
};
export default advancedDayjs;
