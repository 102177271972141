import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const SubscriptionPlanBenefit = (props: { planBenefit: string[] }) => {
  const { planBenefit } = props;
  const { t, ready } = useTranslation();
  if (!ready) return null;
  return (
    <Row className="my-3 ms-2 justify-content-center">
      <Col xs={12} md={8} xl={4} className="position-relative">
        <Row className="my-3 fw-bold fs-4">{t("subscription.subscriptionInfo.whatincluded")}</Row>

        {planBenefit.map((benefit: string, index: number) => {
          return (
            <Row key={index} className="fs-4">
              <small>
                <i className="bi bi-check-circle-fill check-icon fs-3" />
                {benefit}
              </small>
            </Row>
          );
        })}
      </Col>
    </Row>
  );
};
