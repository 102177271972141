import { Col } from "react-bootstrap";

interface IOrderSummaryLine {
  label: string;
  value: string;
  className?: string;
  valueClassName?: string;
  dataCy?: string;
}
export const OrderSummaryLine = ({ label, value, className, valueClassName, dataCy }: IOrderSummaryLine) => {
  return (
    <div className={`order-summary-line-wrapper d-flex ${className ?? ""}`}>
      <Col md={6} lg={9} sm={6} className="order-line-label-wrapper">
        <span className="order-summary-line-label">{label}</span>
      </Col>
      <Col md={6} lg={3} sm={6} className="order-line-value-wrapper">
        <span className={`order-summary-line-value ${valueClassName ?? ""}`} data-cy={dataCy ?? ""}>
          {value}
        </span>
      </Col>
    </div>
  );
};
