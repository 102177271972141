/* eslint-disable prefer-spread */

import { getCurrencySymbolFromCountry } from "../../utils/country";

/* eslint-disable prefer-rest-params */
export class TwitterConvTrkr {
  private conversionId: string | undefined;
  private initialized = false;
  private twq: any;
  private static instance: TwitterConvTrkr | undefined;
  constructor(conversionId: string) {
    this.conversionId = conversionId;
  }
  async init(allowTracking: boolean) {
    return new Promise((resolve: any, reject: any) => {
      //@ts-ignore
      !((e: any, t: any, n: any, s: any, u: any, a: any) => {
        e.twq ||
          ((s = e.twq =
            function () {
              s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
            }),
          (s.version = "1.1"),
          (s.queue = []),
          (u = t.createElement(n)),
          (u.async = !0),
          (u.src = "//static.ads-twitter.com/uwt.js"),
          (a = t.getElementsByTagName(n)[0]),
          a.parentNode.insertBefore(u, a));
        this.twq = e.twq;
      })(window, document, "script");
      if (!this.conversionId) {
        const error = "TwitterConvTrkr.init(conversionId) missing conversion id.";
        console.error(error);
        return reject(error);
      } else if (!this.initialized && allowTracking) {
        this.twq("init", this.conversionId);
        this.initialized = true;
        return resolve();
      }
    });
  }

  trackPageView(eventId?: string) {
    if (!this.conversionId || !this.initialized) {
      console.error("TwitterConvTrkr init must be called first.");
      return;
    }

    if (eventId) {
      this.twq("event", eventId);
    } else {
      this.twq("track", "PageView");
    }
  }

  trackPurchase(value: string, eventId?: string) {
    if (!this.conversionId || !this.initialized) {
      console.error("TwitterConvTrkr init must be called first.");
      return;
    }
    const payload = {
      value,
      currency: getCurrencySymbolFromCountry() ?? "USD",
    };

    if (eventId) {
      this.twq("event", eventId, payload);
    } else {
      this.twq("track", "Purchase", payload);
    }
  }

  trackLead(email_address?: string, eventId?: string) {
    if (!this.conversionId || !this.initialized) {
      console.error("TwitterConvTrkr init must be called first.");
      return;
    }
    const payload = {
      email_address: email_address,
    };

    if (eventId) {
      this.twq("event", eventId, payload);
    }
  }

  trackGenericEvent(eventId: string) {
    if (!this.conversionId || !this.initialized) {
      console.error("TwitterConvTrkr init must be called first.");
      return;
    }

    this.twq("event", eventId);
  }

  public static getInstance(conversionId: string) {
    if (this.instance) {
      return this.instance;
    }
    return new TwitterConvTrkr(conversionId);
  }
}
