import { Fragment } from "react";
import "./StepProgressBar.scss";
import { useTranslation } from "react-i18next";

const StepProgressBar = (props: { steps: number[]; signUpStep: number; showDone?: boolean }) => {
  const { t, ready } = useTranslation();
  if (!ready) {
    return null;
  }
  const { steps, signUpStep, showDone } = props;
  const totalStep = steps.length;
  const stepNo = signUpStep > totalStep ? totalStep : signUpStep;
  const isInProgress = stepNo + 1 < totalStep;

  return (
    <div className="stepper-wrapper">
      {steps.map((_, index) => (
        <Fragment key={index}>
          <div className={`stepper-item ${index < stepNo ? "completed" : ""} ${index === stepNo ? "active" : ""}`}>
            {index === stepNo && (
              <div className="step-label">
                {isInProgress || !showDone
                  ? t("common.stepProgress", { stepNo: stepNo + 1, totalStep })
                  : t("common.stepProgressDone")}
              </div>
            )}
            <div className="step-counter" />
          </div>
        </Fragment>
      ))}
    </div>
  );
};
export default StepProgressBar;
