import { CURSOR_FORWARD } from "../../hooks/usePeriodicStatements";
import { getJson } from "../../utils/api";
import { isCountryUs } from "../../utils/country";
import { WalletModel } from "../payment/PaymentApi";

export interface PeriodicStatement {
  id: string;
  firstName: string;
  lastName: string;
  membershipNumber: string;
  email: string;
  status: string;
  statementInformation: string;
  errorMessage?: string | undefined;
  step?: string | undefined;
  dateCreated: string;
  dateModified?: string | undefined;
  periodStartDate: string;
  periodEndDate: string;
  formattedPeriodEndDate?: string;
}

export interface PeriodicStatementTransaction {
  referenceNumber: string;
  date: string;
  description: string;
  amount: string;
}

export class PeriodicStatementsApi {
  async getUserStatement(statementId: string): Promise<any> {
    return await getJson(`statements/${statementId}`, process.env.REACT_APP_BASE_PERIODIC_STATEMENTS_API_URL)
      .then((response: any) => response.json())
      .then((response) => response["data"]);
  }

  async getUserStatementList(
    pageSize: number,
    cursor: string | null = null,
    cursorDirection: string = CURSOR_FORWARD
  ): Promise<any> {
    const url = cursor
      ? `statements?pageSize=${pageSize}&dir=${cursorDirection}&cursor=${encodeURIComponent(JSON.stringify(cursor))}`
      : `statements?pageSize=${pageSize}`;
    return await getJson(url, process.env.REACT_APP_BASE_PERIODIC_STATEMENTS_API_URL)
      .then((response: any) => response.json())
      .then((response) => response["data"]);
  }
}

export const calculateCreditLimit = (creditLimit: number, wallet: WalletModel | undefined) => {
  const statementCreditLimit = creditLimit ?? wallet?.plan?.creditAmount;
  return statementCreditLimit ? statementCreditLimit : isCountryUs() ? 800 : 0;
};
