import AuthService from "../components/auth/AuthService";

export async function fetchApi(
  url: string,
  init?: RequestInit,
  apiBaseUrl = process.env.REACT_APP_BASE_API_URL ?? "api/v1/"
): Promise<Response> {
  const token = AuthService.getAuthToken();
  if (token && token !== "") {
    if (init && init.headers) init.headers = { ...init?.headers, Authorization: token };
    else if (init) init.headers = { Authorization: token, "Content-Type": "application/json" };
  }
  const response = await fetchWithTimeout(`${apiBaseUrl}${url}`, init);
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (token && response.status === 401) {
    // TODO for good UX we should inform user he has been signed out
    AuthService.clearAuthToken();
  }

  const responseBodyMethod = (response.headers.get("content-type") || "").includes("json") ? "json" : "text";

  return Promise.reject({
    statusCode: response.status,
    error: (await response[responseBodyMethod]()) || response.statusText,
    requestMethod: init?.method || "GET",
    message: response.statusText,
  });
}

export async function fetchWithTimeout(
  url: string,
  options: Parameters<typeof fetch>[1],
  timeout = 30000
): Promise<Response> {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) => setTimeout(() => reject(new Error("Request timed out")), timeout)) as any as Response,
  ]);
}

export async function postJson<T>(url: string, data: T, apiBaseUrl?: string): Promise<Response> {
  return fetchApi(
    url,
    {
      method: "POST",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
      body: JSON.stringify(data),
    },
    apiBaseUrl
  );
}

export async function putJson<T>(url: string, data: T, apiBaseUrl?: string): Promise<Response> {
  return fetchApi(
    url,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
      body: JSON.stringify(data),
    },
    apiBaseUrl
  );
}

export async function getJson(url: string, apiBaseUrl?: string): Promise<Response> {
  return fetchApi(
    url,
    {
      method: "GET",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
    },
    apiBaseUrl
  );
}
