import { Money } from "../components/checkout/Checkout.types";
import Dinero from "dinero.js";
import { getCurrencySymbol } from "./country";

export const convertFloatToDinero = (item: Money) => {
  const amountInMinimalUnit = Math.round(item.amount * Math.pow(10, Dinero.defaultPrecision));
  const currency = (item.currency?.toUpperCase() as Dinero.Currency) || Dinero.defaultCurrency;

  return Dinero({
    amount: amountInMinimalUnit,
    currency: currency,
  });
};

export const roundFullAmount = (amount: number): number | string => {
  if (amount % 1 !== 0) {
    return (Math.round(amount * 100) / 100).toFixed(2);
  }

  return amount;
};

export const formatPrice = (price: Money | undefined, freeText = "") =>
  price == null
    ? ""
    : price.amount === 0
    ? freeText
    : `${getCurrencySymbol(price.currency)}${roundFullAmount(price.amount)}`;

export const subtractMoney = (m1: Money | undefined, m2: Money | undefined): Money | undefined => {
  return m1 == null || m2 == null
    ? undefined
    : {
        currency: m1.currency,
        amount: convertFloatToDinero(m1).subtract(convertFloatToDinero(m2)).toUnit(),
      };
};

export const addMoney = (m1: Money | undefined, m2: Money | undefined): Money | undefined => {
  return m1 == null || m2 == null
    ? undefined
    : {
        currency: m1.currency,
        amount: convertFloatToDinero(m1).add(convertFloatToDinero(m2)).toUnit(),
      };
};

export const formatPriceWithCurrency = (amount: number, currency: string) =>
  convertFloatToDinero({ amount, currency }).toUnit();

export const showInCurrency = (value: number, currency: string) => `${currency}${roundFullAmount(value)}`;

const dineroToMoney = (input: Dinero.Dinero): Money => ({ amount: input.toUnit(), currency: input.getCurrency() });

export const getTranches = (amount: Dinero.Dinero, tranchesCount: number): Money[] => {
  const ret: Money[] = [];
  if (tranchesCount <= 1) {
    return [dineroToMoney(amount)];
  }
  const part = amount.divide(tranchesCount);
  for (let i = 1; i <= tranchesCount; i++) {
    if (i < tranchesCount) {
      ret.push(dineroToMoney(part));
    } else {
      ret.push(dineroToMoney(amount.subtract(part.multiply(tranchesCount - 1))));
    }
  }
  return ret;
};

export const getPayIn3Tranches = (amount: Dinero.Dinero): Money[] => getTranches(amount, 2);
export const getPayIn4Tranches = (amount: Dinero.Dinero): Money[] => getTranches(amount, 3);
