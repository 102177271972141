import logger, { LogTagKey } from "./logger";
import { SubscriptionPlan } from "../components/payment/SubscriptionApi";
import { Dinero } from "dinero.js";

export const getPlanWithDefaultCreditLimit = (plans: SubscriptionPlan[]): SubscriptionPlan | null => {
  const plansMarkedDefault = plans.filter((p) => p.isDefault);
  if (plansMarkedDefault.length === 1 && plansMarkedDefault[0].creditAmount > 0) {
    return plansMarkedDefault[0];
  } else {
    const nonZeroCreditAmountPlans = plans.filter((p) => p.creditAmount > 0);
    if (nonZeroCreditAmountPlans.length === 0) {
      logger.error(
        new Error("No subscription plan with credit amount set"),
        LogTagKey.TkSubscription,
        "No subscription plan with credit amount set"
      );
    } else {
      return nonZeroCreditAmountPlans.reduce((prev, curr) => (prev.creditAmount < curr.creditAmount ? prev : curr));
    }
  }

  return null;
};

export const calculateUtilization = (creditAmount: Dinero, balance: Dinero) => {
  return !creditAmount.isZero()
    ? Math.round(((balance.getAmount() / creditAmount.getAmount()) * 100 + Number.EPSILON) * 100) / 100
    : 0;
};
