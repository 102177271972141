import { BitsLogo } from "../common/BitsLogo";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Header.scss";

const Header = () => {
  const { t, ready } = useTranslation();

  if (!ready) {
    return null;
  }
  return (
    <header className="header-container">
      <BitsLogo to="/login" />
      <div className="menu-items">
        <a href="https://www.getbits.app/faqs" target="_blank">
          {t("header.faq")}
        </a>
        <Link to="/login">{t("header.signIn")}</Link>
        <Link to="/signup">{t("header.joinBits")}</Link>
      </div>
    </header>
  );
};
export default Header;
