import { getJson, postJson } from "../../utils/api";
export enum UserScoreStatus {
  enabled = "enabled",
  pending = "pending",
  failed = "failed",
  disabled = "disabled",
}

export interface ScoreUserModel {
  status: UserScoreStatus;
  lastScore: number | null;
  lastDataFetchTimestamp: string | null;
  lastError?: any | null;
  lastErrorTimestamp?: string | null;
}

export interface EligibilityResponse {
  isApplied: boolean;
  isEligible?: boolean;
  kycRequired?: boolean;
  user?: ScoreUserModel;
}

export interface ActivateResponse {
  status: boolean;
}

export interface ScoreHistoryResponse {
  userId: string;
  timestamp: string;
  score: number;
  difference?: number;
}

export class ScoreApi {
  async getEligibility(): Promise<EligibilityResponse> {
    return await getJson("eligibility", process.env.REACT_APP_BASE_SCORE_API_URL)
      .then((response) => response.json())
      .then((response) => response["data"]);
  }

  async activate(): Promise<ActivateResponse> {
    return await postJson("activate", {}, process.env.REACT_APP_BASE_SCORE_API_URL)
      .then((response) => response.json())
      .then((response) => response["data"]);
  }

  async activateStart(): Promise<ActivateResponse> {
    return await postJson("activate-start", {}, process.env.REACT_APP_BASE_SCORE_API_URL)
      .then((response) => response.json())
      .then((response) => response["data"]);
  }

  async getHistory(limit: number): Promise<any> {
    return await getJson(`score?limit=${limit}`, process.env.REACT_APP_BASE_SCORE_API_URL)
      .then((response) => response.json())
      .then((response) => response["data"]);
  }
}
